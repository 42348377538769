.free-spins-table {
  display: flex;
  flex-direction: column;
  gap: 34px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      display: flex;
      align-items: center;
      gap: 20px;

      .bonuses__title {
        margin: 0;
      }
    }

    .free-spins-table__head-filter {
      width: 300px;

      .free-spins-table__head-filter__search {
        > div {
          background-color: transparent !important;
        }

        input {
          color: white !important;
        }

        svg {
          z-index: 1;
        }

        fieldset {
          background-color: #25274a;
          border: 1px solid #ffffff26 !important;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;

    .free-spins__tile {
      max-width: calc(50% - 10px);
    }
  }
}

@media (max-width: 768px) {
  .free-spins-table {
    &__content {
      gap: 15px;
      flex-direction: column;

      .free-spins__tile {
        max-width: 100%;
      }
    }
  }
}
