.progress-bar {
  display: flex;
  flex-direction: column;
  gap: 5;
  font-family: Poppins;
  font-size: 16px;

  &__label {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-text {
      font-weight: 600;
    }

    &-percentage {
      font-weight: 400;
    }
  }

  &__bar {
    display: flex;
    padding: 2px;
    min-width: 200px;
    background: #ffffff1a;
    border-radius: 10px;
    overflow: hidden;

    &-progress {
      display: flex;
      height: 10px;
      background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
      border-radius: 10px;
    }
  }
}
