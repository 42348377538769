.access-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100vh;
  background-size: cover;
  background-position: center;
  background: url(../../../assets/forbidden/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  gap: 30px;
  width: 90%;
  max-width: 760px;
  height: 440px;
  padding: 50px;
  border-radius: 24px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.08);

  &__logo {
    height: 54px;
  }

  &__code {
    display: flex;
    align-items: center;
    height: 165px;
    gap: -10px;

    &__bubble {
      z-index: 1;
      height: 100%;
      margin-right: -15px;
    }

    &__num {
      color: #091344;
      font-size: 155px;
      margin-right: -10px;
      text-shadow: -1px -1px 1px #011024;
      font-weight: bolder;
    }
  }

  &__text {
    color: var(--white, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
}

@media screen and (max-width: 768px) {
  .content-container {
    padding: 50px 30px;

    &__logo {
      height: 36px;
    }

    &__code {
      height: 104px;

      &__num {
        font-size: 94px;
      }
    }

    &__text {
      font-size: 14px;
    }
  }
}
