.menu-item-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 14px 0px;
  gap: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  text-transform: capitalize;
  color: white;

  &--rotate-270 {
    transform: rotate(270deg);
  }
}
