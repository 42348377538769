.earn-more-banner-container {
  display: flex;
  width: 640px;
  min-height: 420px;
  height: auto;
  padding: 50px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid var(--Border-Blue-Hover, #2470F0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  gap: auto;
  box-sizing: border-box;
  overflow: hidden;
  will-change: transform, opacity;
  
  &__child {
      display: flex;
      width: 300px;
      flex-direction: column;
      align-items: flex-start;
      min-height: 100px;
      z-index: 1;
  }

  &__banner {
      display: flex;
      width: 300px;
      height: 200px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      z-index: 1;
      justify-content: flex-end;

      &__title {
          align-self: stretch;
          color: var(--white, #FFF);
          max-width: 300px;
          font-family: Poppins;
          font-size: 39px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 42.9px */
          text-transform: capitalize;
      }
  
      &__body {
          color: var(--white, #FFF);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 17.6px */
      }
  
      &__button {
          border-radius: 100px !important;
          width: 272px !important;
          min-height: 54px !important;
          padding: 16px 32px;
          font-family: Poppins !important;
          font-size: 19px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          line-height: 22px !important; /* 115.789% */
          text-transform: capitalize !important; 
          text-wrap: nowrap;
      }
  }
  &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 325px;
      min-height: 420px;
      height: 100%;
      border-radius: 32px;
      background-repeat: no-repeat;
      background-size: contain;
  }
  &--LuckyWheel::after {
      background-image: url('../../assets/homePage/earnmore/wheel/wheel.png');
  }

  &--Promotion::after {
      background-image: url('../../assets/homePage/earnmore/promotion/promotion.png');
  }

  &--Shop::after {
      background-image: url('../../assets/homePage/earnmore/shop/shop.png');
  }

  &--Challenges::after {
      background-image: url('../../assets/homePage/earnmore/challenge/challenge.png');
  }

  &--Tournaments::after {
      background-image: url('../../assets/homePage/earnmore/tournament/tournament.png');
  }

  &--LuckyWheel {
      background-image: url('../../assets/homePage/earnmore/wheel/wheel_background.png');
  }

  &--Promotion {
      background-image: url('../../assets/homePage/earnmore/promotion/promotion_background.png');
  }

  &--Shop {
      background-image: url('../../assets/homePage/earnmore/shop/shop_background.png');
  }

  &--Challenges {
      background-image: url('../../assets/homePage/earnmore/challenge/challenge_background.png');
  }

  &--Tournaments {
      background-image: url('../../assets/homePage/earnmore/tournament/tournament_background.png');
  }
}

@media screen and (max-width: 575px) {
  .earn-more-banner-container {
    width: 345px;
    min-height: 310px;
    padding: 30px;

    &__banner {
      width: 185px;
      gap: 10px;
      
      &__title {
         font-size: 24px; 
      }

      &__body {
         font-size: 14px;
      }
      &__button {
         min-height: 42px !important;
         font-size: 16px !important;
         width: auto !important;
         max-width: 216px !important;
         min-width: 139px !important;
         margin-top: 10px !important;
      }
    }
    &::after {
      width: 160px;
      min-height: 310px;
    }
    &--LuckyWheel::after {
      width: 100%;
    }

    &--Promotion::after {
      width: 100%;
    }

    &--Shop::after {
      width: 100%;
    }

    &--Challenges::after {
      width: 100%;
    }

    &--Tournaments::after {
      width: 100%;
    }
  }
} 

@media screen and (min-width: 576px) and (max-width: 767px) {
  .earn-more-banner-container {
    width: 546px;
    min-height: 340px;
    padding: 30px; 

    &__banner {        
      
      &__title {
        font-size: 31px;
      }

      &__button {
        font-size: 16px !important;
        height: 42px !important; 
      }
    }
    &::after {
      min-height: 340px;
    }
    &--LuckyWheel::after {
      width: 100%;
    }

    &--Promotion::after {
      width: 100%;
    }

    &--Shop::after {
      width: 100%;
    }

    &--Challenges::after {
      width: 100%;
    }

    &--Tournaments::after {
      width: 100%;
    }
  }
}                         

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .earn-more-banner-container {
    width: 560px;
    min-height: 340px;
    padding: 30px; 

    &__banner {         
      
      &__title {
        font-size: 31px;
      }

      &__button {
        font-size: 16px !important;
        height: 42px !important; 
      }
    }
    &::after {
      min-height: 340px;
    }
    &--LuckyWheel::after {
      width: 100%;
    }

    &--Promotion::after {
      width: 100%;
    }

    &--Shop::after {
      width: 100%;
    }

    &--Challenges::after {
      width: 100%;
    }

    &--Tournaments::after {
      width: 100%;
    }
  }
}                         

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .earn-more-banner-container {
    width: 540px;
    min-height: 340px;
    padding: 30px;

    &__banner {  

      &__title {
        font-size: 31px;
      }

      &__button {
        font-size: 16px !important;
        height: 42px !important; 
      }
    }
    &::after { 
      min-height: 340px;
    }
    &--LuckyWheel::after {
      width: 100%;
    }

    &--Promotion::after {
      width: 100%;
    }

    &--Shop::after {
      width: 100%;
    }

    &--Challenges::after {
      width: 100%;
    }

    &--Tournaments::after {
      width: 100%;
    }
  } 
}                      

@media screen and (min-width: 1400px) and (max-width: 1920px) {
  .earn-more-banner-container {
    width: 580px;

    &::after {
      width: 290px;
    }

    &--LuckyWheel::after {
      width: 100%;
    }

    &--Promotion::after {
      width: 100%;
    }

    &--Shop::after {
      width: 100%;
    }

    &--Challenges::after {
      width: 100%;
    }

    &--Tournaments::after {
      width: 100%;
    }
  }
}     