.account-transactions-container {
    display: flex;
    flex-direction: column;
    background: #16183D;
    width: 100%;
    height: auto;
    padding: 30px;
    border-radius: 32px;
    gap: 20px;
    margin-bottom: 20%;

    &__title {
        color: #F7C903;
        font-family: Poppins;
        font-size: 31px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 40.3px */
    }

    &__filters-button {

    }

    &__title-filter-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    &__filters-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        gap: 15px;

        &--type {
            grid-column: span 1;
        }

        &--period {
            grid-column: span 1;
        }
    }
}

@media (max-width: 1399px) {
    .account-transactions-container { 
        margin-bottom: 45%;
    }  
}

@media (max-width: 1199px) {
    .account-transactions-container { 
        margin-bottom: 20%;
    }  
}

@media (max-width: 1023px) {
    .account-transactions-container { 
        &__title{
            font-size: 24px;
        }
    }  
}

@media (max-width: 767px) {
    .account-transactions-container { 
        margin-bottom: 30%;
        &__filters-box {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            &--type {
                grid-column: span 2;
            }

            &--period {
                grid-column: span 1;
            }
        }
    }  
}

@media (max-width: 575px) {
    .account-transactions-container { 
        margin-bottom: 100%;
    }  
}

@media (min-width: 576px) {
    .account-transactions-container { 
        margin-bottom: 100%;
        &__filters-button {
            display: none;
        }
    }  
}