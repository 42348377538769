.element-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 16px 20px;
  border-radius: 14px;
  background-color: #25274a;
  font-family: Poppins;
  color: #fff;

  &:hover {
    background-color: #2b2e52;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  &--is-selected {
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }

    .element-row__details {
      &-logo {
        border: 1px solid #e1e1e8;
      }

      &-text {
        color: #031e44;
      }
    }

    .element-row__controls {
      color: #031e44;
    }
  }

  &--is-highlighted {
    border: 1px solid #f7c903;

    .element-row__details-text-main {
      color: #f7c903;
    }
  }

  &--is-disabled {
    opacity: 60%;
  }

  &__details {
    display: flex;
    align-items: center;
    gap: 15px;
    box-shadow: none !important;

    &-logo {
      display: flex;
      align-items: center;
      height: 48px;
      justify-content: center;
      width: 80px;
      border-radius: 8px;
      background-color: #fff;
      color: #000;

      &--visa {
        width: 36px;
        filter: drop-shadow(0px 1000px 0 #152682);
        transform: translateY(-1000px);
      }

      &--mastercard {
        height: 36px;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
      text-align: left;
      line-height: 22.4px;

      &-main {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700;
        text-transform: capitalize;
      }

      &-additional {
        font-weight: 400;
      }
    }
  }

  &__controls {
    @extend .element-row__details;
  }
}

@media (max-width: 768px) {
  .element-row {
    padding: 15px;
    justify-content: space-between;

    &--with-custom-controls {
      gap: 15px;
      align-items: start;
      flex-direction: column;
      height: auto;

      .element-row__details,
      .element-row__controls {
        width: 100%;

        .progress-bar,
        .element-row__details-text,
        .element-row__controls-custom,
        .element-row__details-text-main,
        .element-row__details-text-additional {
          max-width: 100%;
          width: 100%;
        }

        .element-row__controls-custom {
          .suggested-bonus-controls {
            button {
              width: 100%;
            }
          }
        }
      }
    }

    &__details {
      gap: 10px;

      &-logo {
        min-width: 64px;
        width: 64px;
      }

      &-text {
        font-size: 14px;

        &-main,
        &-additional {
          max-width: 360px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .element-row {
    &__details {
      &-text {
        &-main,
        &-additional {
          max-width: 250px;
        }
      }
    }
  }
}

// @media (max-width: 375px) {
//   .element-row {
//     &__details {
//       &-text {
//         &-main,
//         &-additional {
//           max-width: 200px;
//         }
//       }
//     }
//   }
// }
