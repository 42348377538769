$mobile-width: 345px;
$other-width: 560px;

.info-box {
  border-radius: 30px !important;
  background: rgba(37, 39, 74, 0.9) !important;
  backdrop-filter: blur(10px);

  @media (max-width: 767px) {
    width: $mobile-width;
  }

  @media (min-width: 768px) {
    width: $other-width;
  }

  &--extended-height {
    height: 800px;

    .info-box__content {
      max-height: 800px;

      &__children {
        max-height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    padding: 30px;
    max-height: 700px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    &__title {
      color: #f7c903;
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      width: 97%;

      @media (max-width: 767px) {
        font-size: 24px;
      }

      @media (min-width: 768px) {
        font-size: 31px;
      }
    }
    &__subtitle {
      color: var(--white, #fff);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      font-size: 16px;
    }

    &__children {
      width: 100%;
      max-height: 500px;
      overflow: auto;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &__button {
      width: 210px !important;

      &--claimed {
        color: var(--main-color) !important;
        mix-blend-mode: luminosity !important;
      }

      &--locked {
        color: var(--main-color) !important;
        background: var(--yellow-gradient) !important;
      }
    }
  }

  &__button-container {
    display: flex;
    width: 100%;

    &--centered {
      justify-content: center;
    }
  }

  &__close-button {
    position: absolute;
    right: 30px;
    top: 25px;
    z-index: 120;
  }
}

@media (max-width: 767px) {
  .info-box {
    &--extended-height {
      height: 670px;

      .info-box__content {
        max-height: 670px;

        &__children {
          max-height: 100%;
        }
      }
    }
  }
}
