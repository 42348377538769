.mobile-menu-container {
  width: 300px;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 20px 16px 30px 16px;

  &__logo-row {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 15px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.mobile-menu-logo {
  width: 144px;
  height: 30px;
  cursor: pointer;
}

.mobile-menu-burger {
  width: 18px;
  height: 12px;
  cursor: pointer;
}

.my-account-nav {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ffffff26;
}

.change-language-mobile-tooltip {
  width: 268px !important;
  padding-top: 14px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: space-between !important;

  &__img-display-name-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}

.arrow-drop-mobile-icon {
  margin: 0 !important;
  color: white !important;
}

.flag-img-mobile {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  justify-content: left !important;

  &--border-white {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 2px solid white;
  }
}

.arrow-drop-icon-mobile {
  margin: 0 !important;
  color: white !important;
}

.languages-menu-item-mobile {
  display: flex !important;
  gap: 8px !important;
  justify-content: flex-start !important;
  border-radius: 14px !important;

  &__display-name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: white;
  }

  &__tooltip-display-name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: white
  }
}

.language-menu-mobile {
  border-radius: 14px !important;
  width: 270px !important;
  background: #ffffff1a !important;
}