.tournament-card__games {
  display: flex;
  flex-flow: column;
  margin-top: 25px;
  width: 100%;

  &-title {
    display: flex;
    margin-bottom: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .test {
    position: relative;
  }

  &-list {
    position: relative;
    display: flex;
    gap: 8px;
    overflow: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none !important;
    }

    &__controls {
      position: absolute;
      display: flex;
      gap: 14px;
      justify-content: space-between;
      flex-grow: 0;
      width: 100%;
      height: 100%;

      &-left,
      &-right {
        position: absolute !important;
        background-color: #0d0f36 !important;
        color: white !important;
        border: 2px solid #e3ab00 !important;
        z-index: 100;
      }

      &-left {
        top: 45%;
        left: -20px;
        transform: translate(0, -50%);
      }

      &-right {
        top: 45%;
        right: -20px;
        transform: translate(0, -50%);
      }
    }
  }
}
