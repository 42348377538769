.level-meter {
  display: flex;

  &__level {
    position: relative;
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
    }

    &--is-selected {
      color: #f7c903;
      opacity: 1;
      transition: opacity 0.25s ease-in;

      &::after {
        content: ' ';
        bottom: -20px;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #333657;
        transform: rotate(45deg);
      }

      .level-meter__level-text {
        font-weight: 700;
      }
    }

    &-current {
      position: absolute;
      top: -7px;
      right: 5px;
      width: 26px;
      height: 26px;
      color: #f7c903;

      svg {
        font-size: 26px;
      }
    }

    &-image {
      width: 60px;
      height: 60px;
    }

    &-text {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
    }
  }
}

.level-meter__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 15px 0 30px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 8px;
  background: #333657;
  font-size: 14px;
  font-weight: 700;

  &-row {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-left: 5px;
      color: #f7c903;

      svg {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .level-meter {
    &__level {
      &--is-selected {
        .level-meter__level-text {
          font-weight: 500;
        }
      }

      &-current {
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;

        svg {
          font-size: 20px;
        }
      }

      &-image {
        width: 40px;
        height: 40px;
      }

      &-text {
        font-size: 9px;
      }
    }
  }
}
