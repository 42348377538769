.menu-tabs {
  display: flex;
  gap: 10px;

  // TODO
  // &-horizontal {
  // }

  &-vertical {
    flex-direction: column;
    width: 100%;
  }

  &__button {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 14px;
    background-color: #16183d;
    cursor: pointer;

    &:hover,
    &--is-selected {
      background-color: #222447;
      transition: background-color 0.3s ease !important;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 44px;
      min-height: 44px;
      background-color: #222447;
      border-radius: 14px;

      &--rotate-270 {
        transform: rotate(270deg);
      }
    }

    &-title {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
