.level-information {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Poppins;
  font-size: 14px;
  color: white;
  min-width: 155px;

  &--max-width {
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    .level-information__title {
      display: flex;
      font-weight: 600;
    }

    .level-information__link {
      display: flex;
      gap: 6px;
      font-size: 12px;
      font-weight: 400;
      color: white;
      text-decoration: underline;

      &-icon {
        font-size: 18px;
      }
    }

    .level-information__bar {
      width: 100%;
      height: 12px;
      padding: 2px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;

      &-progress {
        height: 100%;
        background: var(--yellow-gradient);
        border-radius: 10px;
        max-width: 100%;
      }
    }

    .level-information__progress-title {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 12px;
      font-weight: 400;

      &-icon {
        font-size: 20px;
        color: #ffd000;
      }
    }
  }
}
