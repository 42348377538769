.summary {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 32px;
  background: #16183d;

  &__title {
    color: #f7c903;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    padding-bottom: 20px;
  }

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    line-height: 130%;
  }

  &__type {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }

  &__amount {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 18.2px */
  }

  &__total {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 31.2px */
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */

    &-icon {
      width: 20px;
      height: 20px;
      color: #ffd000;
    }
  }

  &__action {
    margin-top: auto;
    width: 100%;
    &-button {
      &:disabled {
        background: white !important;
        opacity: 0.3 !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .summary {
    padding: 30px;

    &__title {
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .summary {
    &__title {
      font-size: 31px;
    }
  }
}


