.category-filter-row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 16px;
  margin-top: 24px;
  font: Poppins !important;
}

.additional-filter-row {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  margin-top: 24px;

  > div {
    max-width: 430px;
  }
}

@media screen and (max-width: 1199px) {
  .category-filter-row {
    flex-flow: row;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 0 16px;
    overflow-x: auto;
    justify-content: center;
    font-family: Poppins;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .additional-filter-row {
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    padding: 0 20px;

    .fullscreen-list__extra-actions {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 20px;
      padding: 0 15px;

      &-title {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1058px) {
  .category-filter-row {
    justify-content: start;
  }
}
