.promotions {
  .promotion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    width: 720px;
    height: 305px;
    background-color: #16183d;
    border-radius: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;

    &--claimed {
      mix-blend-mode: luminosity;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 67%;

      &__category {
        padding: 1px 5px;
        width: max-content;
        font-size: 14px;
        color: white;
        background-color: #08287c;
      }

      &__overline {
        font-size: 24px;
        font-weight: 700;
        color: #f7c903;
        text-align: start;
      }

      &__title {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        text-align: start;
        position: relative;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      width: 67%;

      &__button {
        min-width: 210px !important;
        max-height: 42px;
        width: 150px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .promotions {
    .promotion {
      width: 610px;
      height: 305px;

      &__content {
        &__overline {
          font-size: 20px;
        }

        &__title {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .promotions {
    .promotion {
      padding: 30px;
      width: 510px;
      height: 265px;

      &__content {
        width: 50%;

        &__category {
          margin-bottom: 5px;
          font-size: 12px;
        }

        &__overline {
          font-size: 16px;
        }

        &__title {
          font-size: 20px;
          line-height: 27px;
        }
      }

      &__actions {
        width: 50%;

        &__button {
          min-width: 140px !important;
          width: 140px;
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .promotions {
    .promotion {
      width: 422px;
    }
  }
}

@media screen and (max-width: 960px) {
  .promotions {
    .promotion {
      width: 640px;
    }
  }
}

@media screen and (max-width: 500px) {
  .promotions {
    .promotion {
      background-position-x: calc(100% + 90px);
    }
  }
}

@media screen and (max-width: 410px) {
  .promotions {
    .promotion {
      width: 345px;
    }
  }
}
