.indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-top: -55%;
    margin-left: -53%;
    border-radius: 45px;
    background-color: rgba(205, 10, 45, 1);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background-color: rgba(255, 0, 46, 1);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: -0.4s infinite;
  }

  &--bottom {
    top: 75%;
  }

  &--right {
    left: 75%;
  }
  
  &--top-right {
    top: 25%;
    left: 75%;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
