.title-container {
    color: var(--main-color);
    font-weight: 700;
    font-family: 'Poppins';
    font-size: 28px;
    font-style: normal;
    margin-left: 2rem;
    margin-right: 2rem;
    
    @media (min-width: 960px) {
      font-size: 40px;
      margin-left: 4rem;
      margin-right: 4rem;
    }
}

.subtitle-container {
    color: var(--main-color);
    font-weight: 400;
    font-family: 'Poppins';
    font-size: 16px;
    margin-left: 2rem;
    margin-right: 2rem;
    
    @media (min-width: 960px) {
      font-size: 18px;
      margin-left: 4rem;
      margin-right: 4rem;
    }
}

.group-container {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;

    @media (min-width: 960px) {
      margin-left: 4rem;
      margin-right: 4rem;
    }
    

    &__button-group {
        height: 40px;
        background: #2575FC !important;
        border-radius: 60px !important;
        color: white !important;
      
          &__option {
            color: white !important;
            height: 100% !important;
            min-width: 100px !important;
            font-size: 14px !important;
            font-weight: 600;
            border-radius: 100px !important;
      
            &--active {
              min-width: 100px !important;
              background: rgba(37, 39, 74, 0.90) !important;
              color: white !important;
              height: 100% !important;
              border-radius: 60px !important;
              border: none !important;
              font-size: 14px !important;
            }
      
            &:hover {
              background-color: rgba(37, 39, 74, 0.90) !important;
            }
          }
  
          @media (max-width: 576px) {
  
              &__option {
                font-size: 12px !important;
                padding: 8px !important;
                line-height: 32px !important;
              }
          }
      }
}