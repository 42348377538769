$button-background-dark: var(--button-background-dark);

.custom-select {
    height: 40px;
    background: $button-background-dark !important;
    box-shadow: none !important;
    border-radius: 25px !important;
    color: white !important;
    padding: 15px !important;

    &--open {
        border-radius: 14px 14px 0px 0px;   
        background: $button-background-dark !important;
        color: white !important;
        height: 40px;
        padding-left: 15px;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }

    .MuiSelect-icon {
        color: white;
    }

    &--open {
        .MuiSelect-icon {
            color: white;
        }
    }
}

.custom-menu-paper {
    background-color: $button-background-dark !important;
    color: white !important;
    border-radius: 0px 0px 14px 14px !important;
}