.free-spins {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &__tile {
    display: flex;
    flex: 45%;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 10px 28px 10px 10px;
    border-radius: 14px;
    background: #25274a;

    &:hover {
      background-color: #2b2e52;
      transition: background-color 0.3s ease;
      cursor: pointer;
    }

    &--disabled {
      mix-blend-mode: luminosity;
    }

    &-info {
      display: flex;
      flex-direction: row;
      gap: 15px;

      &__image {
        border-radius: 8px;
        width: 70px;
        height: 70px;
      }

      &__text-container {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        text-align: left;

        &__row {
          display: flex;
          gap: 5px;

          &--bold {
            font-weight: 700;
          }

          &--capitalized {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .free-spins {
    &__tile {
      gap: 0;
      padding: 10px;
    }
  }
}

@media (max-width: 768px) {
  .free-spins {
    gap: 15px;
    flex-direction: column;

    &__tile {
      &-info {
        &__text-container {
          font-size: 14px;
        }
      }
    }
  }
}
