.bonuses-table {
  display: flex;
  flex-direction: column;
  gap: 34px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bonuses-table__head-title {
      display: flex;
      align-items: center;
      gap: 20px;

      .bonuses__title {
        margin: 0;
      }
    }

    &-filter {
      width: 300px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .bonuses-table {
    gap: 29px;

    &__head {
      gap: 10px;
      flex-direction: column;
      align-items: start;

      &-filter {
        width: 100%;
      }
    }

    .element-row--with-custom-controls {
      .element-row__controls {
        &-custom {
          .past-bonus-controls {
            justify-content: center;

            &__text {
              display: none;
            }
          }
        }
      }
    }
  }
}

// .bonuses__filter {
//   width: 300px;

//   &__button {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     min-width: 260px;
//     padding: 10px 15px 10px 20px;
//     color: #ffffff99;
//     font-family: Poppins;
//     font-size: 16px;
//     font-weight: 400;
//     text-align: start;
//     background: #25274a;
//     border: 1px solid #ffffff26;
//     border-radius: 50px;

//     &:hover,
//     &--is-active {
//       background: #222447;
//       cursor: pointer;
//       transition: background ease 0.3s;
//     }
//   }

//   &-options {
//     position: absolute;
//     top: 55px;
//     width: 100%;
//     background: #16183d;
//     border: 1px solid #ffffff26;
//     border-radius: 25px;
//     overflow: hidden;

//     &__row {
//       padding: 15px 20px;

//       &:hover {
//         background: #25274a;
//         cursor: pointer;
//         transition: background ease 0.5s;
//       }
//     }
//   }
// }
