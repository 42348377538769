.withdraw,
.verification {
  display: flex;
  margin-bottom: 20px;
  padding: 30px 14px;
  background-color: #16183d;
  border-radius: 32px;
  overflow: hidden;
}

.verification {
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  padding: 25px;
  font-family: Poppins;

  &__message {
    display: flex;
    align-items: center;
    max-width: 680px;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  &__button {
    max-width: 185px;
  }
}

.withdraw {
  flex-direction: column;

  .withdraw__accordion {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &--is-disabled {
      background-color: transparent !important;
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      color: #f7c903;

      &--done {
        color: #686a8c;
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
        border-radius: 100%;
        color: #212121;

        svg {
          font-size: 16px;
        }
      }
    }

    &-expand-icon {
      color: #fff;
    }

    &-content {
      display: flex;

      &__cards {
        display: flex;
        gap: 10px;

        &__element {
          position: relative;
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 30px;
          height: 80px;
          background-color: #25274a;
          border-radius: 14px;

          &:hover {
            cursor: pointer;
            background-color: #2b2e52;
            transition: background-color 0.3s;
          }

          &-image {
            &--card {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &--is-selected {
            background-color: #fff;

            &:hover {
              background-color: #fff;
            }

            .withdraw__accordion-content__cards__element-image {
              filter: invert(1);
            }
          }

          &--is-disabled {
            &:hover {
              background-color: #25274a;
              cursor: default;
            }

            .withdraw__accordion-content__cards__element-image {
              opacity: 0.3;
            }

            .withdraw__accordion-content__cards__element-lock {
              position: absolute;
              top: 10px;
              left: 10px;
              color: #fff;
            }
          }
        }
      }
    }

    &__input {
      input {
        color: #fff;
        z-index: 1;

        &::placeholder {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }
      }

      &:hover {
        fieldset {
          background-color: #2b2e52 !important;
          transition: background-color 0.3s !important;
          border: none !important;
        }
      }

      fieldset {
        background-color: #25274a;
        z-index: 0;
      }

      > div {
        > p {
          margin: 0;
        }
      }

      &-helper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        &-text {
          margin-top: 5px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          opacity: 0.4;

          &--error {
            color: red;
            opacity: 1;
          }
        }

        &-button {
          background-color: #25274a;
          border: none;
          padding: 10px 20px;
          gap: 8px;
          border-radius: 14px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          color: #fff;

          &:hover {
            cursor: pointer;
            background-color: #2b2e52;
            transition: background-color 0.3s;
          }
        }
      }

      &-adornment {
        padding: 0 6px;
        z-index: 2;
        color: #fff;

        p {
          font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          text-align: left;
          color: #fff;
        }
      }

      &-end-adornment {
        z-index: 10;

        span {
          button {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }
  }

  .withdraw__button {
    margin: 40px 0 0 16px;
    max-width: 320px;
  }
}

@media (max-width: 1024px) {
  .withdraw {
    padding-left: 4px;
    padding-right: 4px;

    .withdraw__accordion {
      &-title {
        font-size: 21px;
      }

      &-content {
        &__cards {
          flex-direction: row;

          &__element {
            width: 33.3%;
            height: auto;
            padding: 18px;

            &-image {
              width: 110px;
            }

            &--is-disabled {
              .withdraw__accordion-content__cards__element-lock {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -35%);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .verification {
    flex-direction: column;

    &__message {
      text-align: center;
    }
  }
}
