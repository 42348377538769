.bonuses__past {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.past-bonus-controls {
  display: flex;
  align-items: center;
  gap: 20px;

  &__text {
    font-size: 16px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .bonuses__past {
    gap: 15px;
  }
}