.logo-container {
    font-family: 'Poppins';
    margin-left: 16px;
    margin-top: 16px;

    @media (min-width: 960px) {
        margin-top: 45px;
        margin-left: 45px;
      }

    & img {
      cursor: pointer;
      width: 184px;
    }
  }