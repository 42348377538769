.free-spins-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 14px;
  background-color: #25274a;

  &:hover {
    cursor: pointer;
  }

  &--is-selected {
    color: #031e44;
    background-color: #f7c903;
    transition: background-color 0.5s linear;
  }
}
