.banner-timer-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    flex-direction: column;

    &__text {
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-transform: capitalize;
    }

    &__time-box-container {
        display: flex;
        gap: 15px;
    }
}