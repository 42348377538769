.account {
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 140px 20% 0;

  &-menu {
    display: flex;
    flex: 1;
    padding: 20px 30px;
    max-width: 440px;
    height: fit-content;
    border-radius: 32px;
    background: #16183d;
  }

  &-subpage {
    flex: 2;
    overflow: auto;
  }
}

@media (max-width: 1920px) {
  .account {
    padding: 140px 7% 0;
  }
}

@media (max-width: 1400px) {
  .account {
    gap: 30px;

    &-menu {
      padding: 20px;
      max-width: 380px;
    }
  }
}

@media (max-width: 1399px) {
  .account {
    gap: 20px;
    padding: 140px 5% 0;

    &-menu {
      padding: 20px;
      max-width: 300px;
    }
  }
}

@media (max-width: 1199px) {
  .account {
    padding: 100px 70px 0;

    &-menu {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .account {
    padding: 100px 8% 0;
  }
}

@media (max-width: 767px) {
  .account {
    padding: 100px 20px 0;

    &-menu {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .account {
    padding: 100px 2% 0;
  }
}
