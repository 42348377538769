.transactions-table-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__headers-container {
        display: flex;
        flex-direction: row;
        height: 40px;
        padding: 0px 20px;
        align-items: center;
        align-self: stretch;
        justify-content: space-between;

        &__fixed-box{
            display: flex;
            width: 180px;
            align-items: center;
            flex-direction: row;
            gap: 20px;
            justify-content: flex-start;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            text-align: left;
        }
    
        &__auto-box{
            width: 13.33%;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
            text-align: left;
        }
    
        &__last-box{
            width: 11.11%;
            text-align: right;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
        }
    }

    &__row-cointainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        min-height: 40px;
        padding: 15px 20px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        justify-content: space-between;

        

        &__fixed-box {
            display: flex;
            width: 180px;
            align-items: center;
            flex-direction: row;
            gap: 20px;
            justify-content: flex-start;
            text-align: left;

            &__id-date-box {
                display: flex;
                flex-direction: column;

                &__id-box {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%; /* 20.8px */
                }

                &__type-box {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%; /* 20.8px */
                }

                &__date-box {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%; /* 18.2px */
                    text-wrap: nowrap;
                }
            }
            &__icon-box {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
            }
        }

        &__auto-box {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 13.33%;
            text-align: left;
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 20.8px */
            gap: 10px;

            &__type {
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 13.33%;
                text-align: left;
                color: #FFF;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; /* 20.8px */
                gap: 10px;
            }

            &__status {
                display: flex;
                flex-direction: row;
                align-items: center;

                &--declined{
                    width: 8px;
                    height: 8px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background-color: #E03828;
                }

                &--completed{
                    width: 8px;
                    height: 8px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background-color: #2CDB78;
                }

                &--pending{
                    width: 8px;
                    height: 8px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background-color: #F09A05;
                }

                &__box {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%; /* 20.8px */
                }
            }
        }

        &__last-box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 11.11%;
            text-align: right;
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 20.8px */
            text-wrap: nowrap;

            &__icon {
                justify-content: flex-end;
                align-items: center;
                color: #FFF;
                width: 38px;
                height: 38px;
                flex-shrink: 0;
                border-radius: 14px;
                margin-bottom: 4px;
                margin-right: -8px;
            }

            &__icon-accordion {
                justify-content: flex-end;
                align-items: center;
                color: #FFF;
                width: 38px;
                height: 38px;
                flex-shrink: 0;
                border-radius: 14px;
                margin-bottom: 4px;
                margin-right: -10px;
            }
        }
    }
}

.accordion-container {
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;

    &__id-type-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &__id-box {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal; 
            font-weight: 600;
            line-height: normal;
        }
        &__type-box {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &__delete-icon {
        margin-right: -16px;
    }
}

.background {
    background: rgba(255, 255, 255, 0.05);
}

.disabled {
    color: rgba(255, 255, 255, 0.40);
}


@media (max-width:  1023px) {
    .transactions-table-container { 
        &__row-cointainer {
            &__fixed-box {
                &__id-date-box {
                    &__id-box {
                        font-size: 14px;
                    }
                }
            }
            &__auto-box {
                font-size: 14px;
                &__status {
                    &__box {
                        font-size: 14px;
                    }
                }
                
            }

            &__last-box {
                font-size: 14px;
            }
        }
    }  
}

@media (max-width:  767px) {
    .transactions-table-container { 
        &__headers-container{
            display: none;
        }
        &__row-cointainer {
            &__fixed-box {
                &__id-date-box {
                    &__id-box {
                        display: none;
                    }
                }
            }
            &__auto-box{
                &__status {
                    &__box {
                        display: none;
                    }
                }
                &__type {
                    display: none;
                }
            }
            &__last-box {
                &__icon {
                    display: none;
                }
            }
        }
    } 
    .accordion-container {
        padding: 15px 20px;
        &__delete-icon {
            margin-right: -20px;
        }
    }  
} 

@media (min-width:  768px) {
    .transactions-table-container { 
        &__row-cointainer {
            &__fixed-box {
                &__id-date-box {
                    &__type-box {
                        display: none;
                    }
                }
            }
            &__last-box {
                &__icon-accordion {
                    display: none;
                }
            }
        }
    } 
} 

@media (max-width:  575px) {
    .transactions-table-container { 
        &__row-cointainer {
            padding: 15px;
            &__fixed-box {
                gap: 10px;
                width: 110px;
                &__id-date-box {
                    &__id-box {
                        font-size: 14px;
                    }
                    &__date-box {
                        font-size: 12px;
                    }

                    &__type-box {
                        font-size: 14px;
                    }
                }
            }
            
        }
    }
    .accordion-container {
        padding: 15px;
        &__delete-icon {
            margin-right: -14px;
        }
    }  
} 

@media (max-width:  425px) {
    .transactions-table-container { 
        &__row-cointainer {
            padding: 10px;
            &__fixed-box {
                gap: 5px;
            }
        }
    }  
    .accordion-container {
        padding: 10px;
        &__delete-icon {
            margin-right: -8px;
        }
    }  
} 

