.wallet-menu {
  margin-top: 48px;

  .MuiPaper-root {
    border-radius: 14px;
    min-width: 220px;
  }

  &__deposit {
    padding: 15px;
  }
}
