.landing-page {
  background-color: #0d0f36;
  min-height: 100vh;

  &-logo {
    width: 300px;
    cursor: pointer;
  }

  &-content {
    position: relative;
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding-top: 40px;
    width: 100%;
    min-height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0d0f36;
      clip-path: polygon(
        100% 100%,
        0% 100%,
        0% 92.08%,
        2% 90.88%,
        4% 89.68%,
        6% 88.49%,
        8% 87.33%,
        10% 86.21%,
        12% 85.17%,
        14% 84.2%,
        16% 83.34%,
        18% 82.58%,
        20% 81.95%,
        22% 81.46%,
        24% 81.1%,
        26% 80.89%,
        28% 80.83%,
        30% 80.92%,
        32% 81.17%,
        34% 81.55%,
        36% 82.08%,
        38% 82.74%,
        40% 83.52%,
        42% 84.41%,
        44% 85.39%,
        46% 86.46%,
        48% 87.58%,
        50% 88.75%,
        52% 89.95%,
        54% 91.15%,
        56% 92.34%,
        58% 93.5%,
        60% 94.62%,
        62% 95.66%,
        64% 96.63%,
        66% 97.49%,
        68% 98.25%,
        70% 98.88%,
        72% 99.38%,
        74% 99.73%,
        76% 99.94%,
        78% 100%,
        80% 99.91%,
        82% 99.66%,
        84% 99.28%,
        86% 98.75%,
        88% 98.09%,
        90% 97.31%,
        92% 96.42%,
        94% 95.44%,
        96% 94.37%,
        98% 93.25%,
        100% 92.08%
      );
    }

    &-section {
      display: flex;
      justify-content: center;

      &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;

        &-text {
          display: flex;
          flex-direction: column;
          margin-left: 15%;

          &--align-left {
            align-items: start;
          }

          &--align-center {
            align-items: center;
          }

          &__line {
            display: flex;
            justify-content: flex-start;
            white-space: nowrap;
            line-height: 100%;
            z-index: 3;

            &--font-size-small {
              font-size: 2em;
            }

            &--font-size-medium {
              font-size: 4em;
              line-height: 115%;
            }

            &--font-size-large {
              font-size: 6em;
            }
          }

          &__button-container {
            display: flex;
            justify-content: flex-start;
            padding-top: 13px;

            .landing-page-content-section__column-text__button {
              height: 54px;
              width: 184px;
              font-style: normal;
              font-weight: 600;
              font-size: 19px;
              line-height: 22px;
              color: #031e44;
              background: var(--yellow-gradient);

              &:hover {
                background: #fff;
                box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5);
                border: none;
              }
            }
          }
        }
      }
    }
  }

  &-steps {
    display: flex;
    margin-top: 20px;
    padding: 0 15%;
    width: 100%;
    border-radius: 32px;
    overflow: hidden;
    font-family: Poppins;

    &-step {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 20px;
      padding: 11px 0;
      background-color: #16183d99;
      text-transform: uppercase;
      text-align: center;
      color: white;

      &::before {
        content: '';
        display: flex;
        width: 1px;
        height: 50px;
        background-color: #fff;
        opacity: 10%;
      }

      &:first-of-type {
        border-radius: 32px 0 0 32px;

        &::before {
          content: none;
        }
      }

      &:last-of-type {
        border-radius: 0 32px 32px 0;
        border-right: none;
      }

      &-order {
        display: flex;
        align-items: center;
        padding-left: 60px;
        font-size: 72px;
        font-weight: 700;
        color: #f7c903;
      }

      &-text {
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    }
  }

  &-terms-conditions {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    color: #fff;
  }

  &-terms-conditions-button {
    display: flex;
    gap: 5px;
    margin-top: 10px;
    padding: 0;
    border: none;
    background: none;
    color: white;
    font-size: 19px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    font-family: Poppins;
    text-decoration: underline;
  }

  &-footer {
    display: flex;
    gap: 60px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 40px;
    width: 100%;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-legal-age {
      width: 48px;
      height: 48px;
      padding-left: 3px;
      border-radius: 100%;
      border: 2px solid #2575fc;
      font-family: Poppins;
      font-size: 18px;
      color: #fff;
    }
  }
}

@media (min-width: 0px) {
  .landing-page {
    &-logo {
      width: 172px;
    }

    &-content {
      min-height: 670px;

      &::before {
        clip-path: polygon(
          100% 100%,
          0% 100%,
          0% 76.81%,
          6.67% 77.2%,
          13.33% 78.05%,
          20% 79.28%,
          26.67% 80.82%,
          33.33% 82.58%,
          40% 84.45%,
          46.67% 86.31%,
          53.33% 88.04%,
          60% 89.53%,
          66.67% 90.69%,
          73.33% 91.45%,
          80% 91.75%,
          86.67% 91.59%,
          93.33% 90.97%,
          100% 89.92%
        );
      }

      &-section {
        flex-direction: column;

        &__logo {
          align-items: center;
        }

        &__column {
          align-items: center;
          justify-content: center;
          margin: 0;

          &-text {
            &__line {
              justify-content: center;
              width: 100%;

              &--font-size-small {
                font-size: 1em;
              }

              &--font-size-medium {
                font-size: 1.5em;
                line-height: 115%;
              }

              &--font-size-large {
                font-size: 2em;
              }
            }

            &__button-container {
              margin-top: 15px;
              padding: 0 15px;
              display: flex;
              width: 100%;
            }

            &__button {
              flex: 1;
              font-size: 1em;
            }
          }
        }
      }
    }

    &-steps {
      margin-top: 0;
      padding: 0 30px;
      flex-direction: column;

      &-step {
        position: relative;
        gap: 0;
        flex-direction: column;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          display: flex;
          width: 50px;
          height: 1px;
          transform: translate(-50%, 0);
        }

        &:first-of-type {
          border-radius: 32px 32px 0 0;
        }

        &:last-of-type {
          border-radius: 0 0 32px 32px;
        }

        &-order {
          padding: 0;
          font-size: 58px;
        }

        &-text {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
    }

    &-terms-conditions-button {
      font-size: 16px;
    }

    &-footer {
      gap: 50px;
      flex-wrap: wrap;
    }
  }
}

@media (min-width: 576px) {
  .landing-page {
    &-logo {
      width: 240px;
    }

    &-content {
      &-section {
        &__column {
          &-text {
            &__line {
              &--font-size-large {
                font-size: 4em;
              }
            }

            &__button-container {
              justify-content: center;
            }

            &__button {
              max-width: 350px;
            }
          }
        }
      }
    }

    &-steps {
      margin-top: 40px;

      &-step {
        &-text {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: 767px) {
  .landing-page {
    &-content {
      min-height: 540px;

      &::before {
        clip-path: polygon(
          100% 100%,
          0% 100%,
          0% 91.52%,
          2% 90.73%,
          4% 89.93%,
          6% 89.13%,
          8% 88.36%,
          10% 87.61%,
          12% 86.92%,
          14% 86.27%,
          16% 85.7%,
          18% 85.19%,
          20% 84.77%,
          22% 84.44%,
          24% 84.2%,
          26% 84.07%,
          28% 84.03%,
          30% 84.09%,
          32% 84.25%,
          34% 84.51%,
          36% 84.86%,
          38% 85.3%,
          40% 85.82%,
          42% 86.41%,
          44% 87.07%,
          46% 87.78%,
          48% 88.53%,
          50% 89.31%,
          52% 90.1%,
          54% 90.91%,
          56% 91.7%,
          58% 92.47%,
          60% 93.22%,
          62% 93.91%,
          64% 94.56%,
          66% 95.13%,
          68% 95.64%,
          70% 96.06%,
          72% 96.39%,
          74% 96.63%,
          76% 96.77%,
          78% 96.8%,
          80% 96.74%,
          82% 96.58%,
          84% 96.32%,
          86% 95.97%,
          88% 95.53%,
          90% 95.01%,
          92% 94.42%,
          94% 93.76%,
          96% 93.05%,
          98% 92.3%,
          100% 91.52%
        );
      }

      &-section {
        flex-direction: row;

        &__column {
          &-text {
            &--align-left {
              align-items: start;
            }

            &--align-center {
              align-items: center;
            }

            &__line {
              justify-content: start;
              padding-left: 40px;

              &--font-size-large {
                font-size: 4em;
              }
            }

            &__button-container {
              justify-content: start;
              padding: 0;
            }

            &__button {
              max-width: 200px;
            }
          }
        }
      }
    }

    &-steps {
      padding: 0 20%;

      &-step {
        &-text {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .landing-page {
    &-content {
      min-height: 540px;

      &::before {
        clip-path: polygon(
          100% 100%,
          0% 100%,
          0% 91.52%,
          2% 90.73%,
          4% 89.93%,
          6% 89.13%,
          8% 88.36%,
          10% 87.61%,
          12% 86.92%,
          14% 86.27%,
          16% 85.7%,
          18% 85.19%,
          20% 84.77%,
          22% 84.44%,
          24% 84.2%,
          26% 84.07%,
          28% 84.03%,
          30% 84.09%,
          32% 84.25%,
          34% 84.51%,
          36% 84.86%,
          38% 85.3%,
          40% 85.82%,
          42% 86.41%,
          44% 87.07%,
          46% 87.78%,
          48% 88.53%,
          50% 89.31%,
          52% 90.1%,
          54% 90.91%,
          56% 91.7%,
          58% 92.47%,
          60% 93.22%,
          62% 93.91%,
          64% 94.56%,
          66% 95.13%,
          68% 95.64%,
          70% 96.06%,
          72% 96.39%,
          74% 96.63%,
          76% 96.77%,
          78% 96.8%,
          80% 96.74%,
          82% 96.58%,
          84% 96.32%,
          86% 95.97%,
          88% 95.53%,
          90% 95.01%,
          92% 94.42%,
          94% 93.76%,
          96% 93.05%,
          98% 92.3%,
          100% 91.52%
        );
      }

      &-section {
        flex-direction: row;

        &__column {
          &-text {
            &--align-left {
              align-items: start;
            }

            &--align-center {
              align-items: center;
            }

            &__line {
              justify-content: start;
              padding-left: 40px;

              &--font-size-small {
                font-size: 1.5em;
              }

              &--font-size-medium {
                font-size: 2em;
              }

              &--font-size-large {
                font-size: 5em;
              }
            }

            &__button-container {
              justify-content: start;
              padding: 0;
            }

            &__button {
              max-width: 272px;
            }
          }
        }
      }
    }

    &-steps {
      flex-direction: row;
      padding: 0 15px;

      &-step {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;
        gap: 20px;
        padding: 11px 0;
        background-color: #16183d99;
        text-transform: uppercase;
        text-align: center;
        color: white;

        &::before {
          content: '';
          position: unset;
          top: unset;
          right: unset;
          display: flex;
          width: 1px;
          height: 50px;
          background-color: #fff;
          opacity: 10%;
          transform: translate(0, 0);
        }

        &:first-of-type {
          border-radius: 32px 0 0 32px;

          &::before {
            content: none;
          }
        }

        &:last-of-type {
          border-radius: 0 32px 32px 0;
          border-right: none;
        }

        &-order {
          display: flex;
          align-items: center;
          padding-left: 40px;
          font-size: 58px;
          font-weight: 700;
          color: #f7c903;
        }

        &-text {
          display: flex;
          align-items: center;
          font-size: 19px;
          font-weight: 500;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .landing-page {
    &-content {
      min-height: 560px;

      &-section {
        &__column {
          &-text {
            &__line {
              padding-left: 115px;
            }
          }
        }
      }
    }

    &-steps {
      padding: 0 115px;
    }
  }
}

@media (min-width: 1920px) {
  .landing-page {
    &-logo {
      width: 300px;
    }

    &-content {
      min-height: 830px;

      &-section {
        &__column {
          &-text {
            &__line {
              padding-left: 215px;

              &--font-size-small {
                font-size: 2em;
                line-height: 115%;
              }

              &--font-size-medium {
                font-size: 4em;
              }

              &--font-size-large {
                font-size: 6.5em;
              }
            }
          }
        }
      }
    }

    &-steps {
      padding: 0 215px;

      &-step {
        &-order {
          padding-left: 50px;
          font-size: 72px;
        }

        &-text {
          font-size: 24px;
        }
      }
    }

    &-terms-conditions-button {
      font-size: 19px;
    }
  }
}
