.deposit {
  margin-bottom: 200px;
  padding: 30px 14px;
  background-color: #16183d;
  border-radius: 32px;
  overflow: hidden;

  .deposit__accordion {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      color: #f7c903;

      &--done {
        color: #686a8c;
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
        border-radius: 100%;
        color: #212121;

        svg {
          font-size: 16px;
        }
      }
    }

    &-expand-icon {
      color: #fff;
    }

    &-content {
      display: flex;

      &__cards,
      &__coupons {
        flex-direction: column;
        gap: 10px;

        .custom-switch {
          color: #fff;
        }
      }

      &__payment-methods {
        gap: 10px;
        margin-top: 20px;
        flex-direction: row;
      }

      &__payment-amount {
        display: flex;
        gap: 10px;
        flex-direction: column;

        &__options {
          display: flex;
          gap: 10px;
          flex-direction: row;
          width: 100%;
        }
      }
    }

    &__input {
      input {
        color: #fff;
        z-index: 1;

        &::placeholder {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }
      }

      &:hover {
        fieldset {
          background-color: #2b2e52 !important;
          transition: background-color 0.3s !important;
          border: none !important;
        }
      }

      fieldset {
        background-color: #25274a;
        z-index: 0;
      }

      &-helper-text {
        margin-top: 5px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        opacity: 0.4;

        &--error {
          color: red;
          opacity: 1;
        }
      }

      &-adornment {
        padding: 0 6px;
        z-index: 2;
        color: #fff;

        p {
          font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          text-align: left;
          color: #fff;
        }
      }

      &-end-adornment {
        z-index: 10;

        span {
          button {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }
  }

  .checkout-summary {
    margin-top: 40px;
    padding: 0 16px;
    font-family: Poppins;

    &__title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 600;
      text-align: left;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      &--large-font {
        @extend .checkout-summary__row;

        font-size: 21px;
        font-weight: 600;
      }

      &--with-border {
        @extend .checkout-summary__row;

        margin-top: 16px;
        margin-bottom: 50px;
        padding-top: 16px;
        border-top: 1px solid #ffffff26;
      }

      &-label {
        display: flex;
        gap: 10px;
      }

      &-info {
        display: flex;
        gap: 10px;
      }
    }

    &__controls {
      display: flex;
      flex-direction: column;
      gap: 20px;

      button {
        max-width: 320px;

        &:disabled {
          color: #c3c3c3;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .deposit {
    padding: 10px 14px 30px;
  }
}

@media (max-width: 768px) {
  .deposit {
    padding: 10px 4px 30px;
  }
}

@media (max-width: 576px) {
  .deposit {
    padding: 10px 4px 30px;

    .deposit__accordion {
      &-title {
        font-size: 21px;
      }

      &-content {
        &__payment-methods {
          margin-top: 15px;
          flex-wrap: wrap;
        }
      }

      &__input {
        input {
          color: #fff;
          z-index: 1;

          &::placeholder {
            font-size: 14px;
          }
        }

        &-helper-text {
          font-size: 14px;
        }
      }
    }

    .checkout-summary {
      &__title {
        font-size: 21px;
      }

      &__row {
        font-size: 14px;

        &--large-font {
          @extend .checkout-summary__row;

          font-size: 19px;
        }
      }

      &__controls {
        button {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
