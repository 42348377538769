.tournament-card__extra-info {
  display: flex;
  gap: 30px;
  margin-top: 25px;
  padding: 15px 20px;
  border: 2px solid #ffd000;
  border-radius: 14px;

  &__prize,
  &__clock {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    flex-direction: column;

    section {
      display: flex;
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
    }

    &-value {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      width: 160px;
      height: 60px;
      background: #25274a;
      border-radius: 14px;
      font-size: 31px;

      &__img {
        width: 33px;
      }
    }

    &-title {
      justify-content: center;
      font-size: 12px;

      &--extra {
        @extend .tournament-card__extra-info__prize-title;
        display: none !important;
      }
    }
  }

  &__clock {
    section {
      display: flex;
      gap: 16px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
}

.tournament-card__details {
  display: flex;
  gap: 10px;
  margin-top: 10px;

  &-bet {
    position: relative;

    ::after {
      content: '';
      position: absolute;
      bottom: 6px;
      right: -6px;
      height: 12px;
      border-right: 1px solid white;
    }
  }
}

@media screen and (max-width: 960px) {
  .tournament-card__extra-info {
    flex-direction: column;
    width: 100%;

    &__prize,
    &__clock {
      section {
        justify-content: center;
        width: 100%;
      }

      &-title {
        display: none !important;

        &--extra {
          @extend .tournament-card__extra-info__prize-title;
          display: flex !important;
        }
      }
    }
  }
}
