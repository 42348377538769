.amount-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 13px 0;
  border-radius: 14px;
  background: #25274a;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #fff;

  &:hover {
    cursor: pointer;
    background-color: #2b2e52;
    transition: background-color 0.3s;
  }

  &--is-selected {
    color: #031e44;
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }
}

@media (max-width: 375px) {
  .amount-box {
    font-size: 19px;
  }
}
