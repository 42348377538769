.challenges-info {
  font-family: Poppins;
  color: white;
  text-align: left;

  h5 {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }

  &__heading {
    margin-top: 0 !important;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
  }

  &__text {
    &__read-more-button {
      display: none;
      padding: 5px 0;
      background-color: #0000;
      border: none;
      text-decoration: underline;
      font-family: Poppins;
      font-weight: 300;
      color: white;
      cursor: pointer;
    }
  }

  &__tasks {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    &-box {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 137px;

      &__title {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        opacity: 0.5;
      }
    }

    &-arrow {
      svg {
        position: relative;
        top: 50%;
        transform: translate(0, -100%);
      }
    }
  }

  &__badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    flex: 1;

    &-text {
      font-size: 12px;
      margin-top: 10px;
      opacity: 0.5;
    }
  }
}

@media (max-width: 767px) {
  .challenges-info {
    h5 {
      margin-top: 10px;
    }

    &__text {
      max-height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &--is-expanded {
        display: flex;
        height: auto;
        max-height: 300px;
        transition: max-height 0.25s ease-in;
      }

      &__read-more-button {
        display: block;
      }
    }

    &__row {
      .challenges-info__badge {
        &-image {
          width: 40px;
        }

        &-text {
          font-size: 9px;
        }
      }

      &-box {
        align-items: center;

        &__title {
          font-size: 9px;
        }
      }

      &-arrow {
        svg {
          top: 50%;
          transform: translate(0, -100%);
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 403px) {
  .challenges-info {
    &__row {
      &-arrow {
        svg {
          top: 50%;
          transform: translate(0, -175%);
        }
      }
    }
  }
}
