.bonuses {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;

  &-box {
    padding: 30px;
    background-color: #16183d;
    border-radius: 32px;
  }

  .bonuses__title {
    text-align: left;
    color: #f7c903;
    align-items: center;
    margin-bottom: 34px;
    font-family: Poppins;
    font-size: 31px;
    font-weight: 700;
    display: flex;
  }

  &__see-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 30px;
    padding: 0;
    border: none;
    background: none;
    color: white;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .bonuses {
    gap: 20px;

    .bonuses__title {
      font-size: 24px;
    }
  }
}

@media (max-width: 576px) {
  .bonuses {
    &-box {
      padding: 20px;
    }
  }
}
