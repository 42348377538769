
.banner-child-text-box-container {
    border: 1px solid var(--Border-Blue-Hover, #2470F0);
    color: var(--dark-blue, #031E44);
    background: var(--Yellow, #F7C903);
    border-radius: 3px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 1px 5px;
}