.withdrawal-summary {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  font-family: Poppins;
  font-weight: 600;

  &__title {
    margin: 20px 0 10px 0;
    font-size: 24px;
  }

  &__additional {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  &__total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    padding-top: 15px;
    font-size: 21px;
    border-top: 1px solid #ffffff26;
  }
}
