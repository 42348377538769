.main {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  
    &__content-box {
      display: flex;
      flex-flow: column;
      grid-column: span 12;
      
  
      @media (min-width: 960px) {
        grid-column: span 8;
      }

      &__inner-content {
        display: flex;
        flex-flow: column;
        align-items: center;
        

        &__container {
            max-width: 616px;
            width: 100%;

            &__email-inputs-container {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;

                @media (min-width: 960px) {
                    margin-left: 4rem;
                    margin-right: 4rem;
                }
            }

            &__button-container {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                @media (min-width: 960px) {
                    margin-left: 4rem;
                    margin-right: 4rem;
                }
            
                &__custom-button {
                  margin-top: 1rem !important;
                  margin-bottom: 2rem !important;
                  background: linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%) !important;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
                  color: var(--main-color) !important;
            
                  &:hover {
                    background: white !important;
                    box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
                  }
                }
            }

            &__check-box-container {
                margin-top: 1rem;
                margin-left: 3rem;
                margin-right: 2rem;
                width: 310px;

                @media (min-width: 960px) {
                    margin-left: 5rem;
                    margin-right: 5rem;
                }
            }
        }
      }
    }

    &__already-account {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;
        text-align: center;
    
        @media (min-width: 960px) {
          margin-bottom: 32px;
        }
    
        &__sign-in-link {
          text-decoration: none !important;
          cursor: pointer !important;

          &--disabled {
            cursor: not-allowed !important;
            pointer-events: none !important;
            text-decoration: none !important;
            color: gray !important;
          }
        }
      }
  
   
  
    &__background-box {
      background-image: url('../../../assets/login.svg');
      background-repeat: no-repeat;
      background-color: var(--main-color);
      background-size: cover;
      background-position: center;
      display: none;
  
      @media (min-width: 960px) {
        display: block;
        grid-column: span 4;
      }
    }
    

   
}

.input-label {
    height: 22px !important;
    padding: 12px 0 !important;
    &:-webkit-autofill {
        -webkit-text-fill-color: #2575FC;
    }
}

.form-control-label {
    margin-top: 5px;
    @media (min-width: 960px) {
        margin-top: 15px;
    }
}

.checkbox-label {
    font-style: normal;
    font-weight: normal ;
    font-size: 12px ;
    line-height: 15px ;
    color: #838A99 ;

    @media (min-width: 960px) {
        line-height: 22px !important;
    }
}

.currency-input-adornment {
    padding-left: 13px;
}

.sms-switch-container {
  margin-left: 10px;
  margin-top: 10px;
}
  