.category {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 5%);
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: calc(100% + -20px);
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover,
  &--selected {
    background-color: rgba(255, 255, 255, 15%);
  }

  &--xxs {
    padding: 5px 20px;
    border-radius: 8px;
  }

  &--xs {
    padding: 10px 20px;
  }

  &--m {
    padding: 20px;
    width: 236px;
    height: 90px;
  }
}

@media screen and (max-width: 1540px) {
  .category {
    &--m {
      padding: 15px 20px;
      min-width: 160px;
      width: 160px;
      height: 70px;
      background-size: 25%;
      background-position-x: 90%;
    }
  }
}
