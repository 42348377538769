.empty-bonus-section {
  display: flex;
  flex-flow: column;
  gap: 16px;
  align-items: center;

  &__save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0d1365;
    box-shadow: 0px 0px 5px rgba(37, 117, 252, 0.5);
    border-radius: 50px;
    height: 52px;
    width: 52px;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 12px;
  }
}
