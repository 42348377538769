.suggested-bonus-controls {
  display: flex;
  align-items: center;
  gap: 15px;

  .suggested-bonus-controls__button {
    width: 140px;
    height: 40px;
    padding: 0 16px;
    color: #031e44;
    background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #fff;
      box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5);
    }

    &:disabled {
      color: #c3c3c3;
    }
  }
}
