.tournament {
  position: relative;
  display: flex;
  gap: 24px;
  padding: 50px;
  width: 100%;
  max-width: 1200px;
  border-radius: 32px;
  background: #16183d;
  font-family: Poppins;
  overflow: hidden;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/tournaments/tournamentBackground.svg);
    background-position: 100% 0;
    background-repeat: no-repeat;
    z-index: 0;
  }

  &--passed {
    &::before {
      opacity: 50%;
      mix-blend-mode: luminosity;
    }
  }

  &-info {
    display: flex;
    flex-flow: column;
    align-items: start;
    flex-grow: 1;
    width: 100%;
    z-index: 1;

    &__name {
      display: flex;
      align-items: start;
      color: #fff;
      font-family: Poppins;
      font-size: 39px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
    }

    &__details {
      display: flex;
      flex-direction: row;
      gap: 30px;
      min-width: 500px;

      &--highlighted {
        color: #f7c903;
      }

      section {
        display: flex;
        gap: 5px;
        font-family: Poppins;
        font-size: 20px;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 20px;

    &--extra-top-margin {
      @extend .tournament-actions;
      margin-top: 25px;
    }

    &__main-button {
      padding-left: 40px !important;
      padding-right: 40px !important;
      font-size: 19px !important;
    }
  }
}

.tournament-dialog {
  &__extra-info {
    color: white;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;

    &__date {
      display: flex;
      gap: 5px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    &__text {
      max-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &--expanded {
        max-height: 500px;
        display: flex;
        height: auto;
        transition: max-height 0.25s ease-in;
      }
    }

    &__button {
      padding: 5px 0;
      background-color: #0000;
      border: none;
      text-decoration: underline;
      font-family: Poppins;
      font-weight: 300;
      color: white;
      cursor: pointer;
    }
  }
}

.info-pairs-container__row__key:has(.prize-row) {
  display: flex;
  width: 100%;
}

.prize-row {
  display: flex;
  flex: 1;
  justify-content: space-between;

  &--is-header {
    @extend .prize-row;

    .prize-row-cell {
      &:first-of-type {
        em {
          padding-left: 6px;
          width: 22px;
          height: 22px;
          font-style: normal;
        }
      }
    }
  }

  &-cell {
    display: flex;
    flex: 1;
    gap: 10px;
    align-items: center;

    &--aligned-right {
      @extend .prize-row-cell;
      justify-content: end;
      font-weight: 600;
    }

    &--centered {
      @extend .prize-row-cell;
      padding-left: 20%;
    }
  }
}

.prize-item {
  display: flex;
  gap: 10px;
  align-items: center;

  &__place {
    display: flex;
    height: 22px;
    width: 22px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &--winning {
      display: flex;
      border-radius: 50px;
      background: var(--yellow, linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%));
      color: #000;
      height: 22px;
      width: 22px;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1199px) {
  .tournament {
    &::before {
      background-position: 130% 0;
    }

    &-info {
      &__name {
        font-size: 31px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .tournament {
    flex-direction: column;
    padding: 30px;

    &::before {
      background-position: 115% 0;
      background-size: 250px;
    }

    &-actions {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .prize-row {
    &-cell {
      &:first-of-type {
        min-width: 100px;
      }
    }

    &-cell {
      &--centered {
        padding: 5%;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .tournament {
    &::before {
      background-position: 130% 0;
    }

    &-info {
      &__name {
        font-size: 24px;
      }

      &__details {
        gap: 0;

        flex-direction: column-reverse;

        section {
          font-size: 16px;
        }
      }
    }

    &-actions {
      &__main-button {
        font-size: 16px !important;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .tournament {
    &-info {
      > section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
      }

      &__name {
        width: 50%;
        text-align: left;
        font-size: 20px;
      }

      &__details {
        &--highlighted {
          gap: 0 !important;
          flex-direction: column;
          align-items: start;

          .separator {
            display: none;
          }
        }
      }
    }
  }
}
