.free-spins__prizes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  height: 100%;
  background-color: #16183d;
  border-radius: 32px;

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
  }

  &__title {
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    color: var(--Yellow-text, #f7c903);
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;

    &-title,
    &-value {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1199px) {
  .free-spins__prizes {
    padding: 20px;

    &__title {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .free-spins__prizes {
    padding: 20px;

    &__list {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &__controls {
      margin-top: 56px;
    }
  }
}

@media screen and (max-width: 576px) {
  .free-spins__prizes {
    &__list {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
}

@media screen and (max-width: 375px) {
  .free-spins__prizes {
    &__list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
