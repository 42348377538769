$main-color: var(--main-color);

.group-container {
  height: 40px;
  background: rgba(255, 255, 255, 0.10) !important;
  border-radius: 60px !important;
  color: white !important;
}

.group-button {
  color: white !important;
  height: 100% !important;
  min-width: 100px !important;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 100px !important;

  &--select {
    min-width: 100px !important;
    background: white !important;
    color: $main-color !important;
    height: 100% !important;
    border-radius: 60px !important;
    border: none !important;
    font-size: 14px !important;
  }
}
