
.personal-details-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #16183D;
    padding: 30px;
    border-radius: 32px;
    height: auto;
    gap: 20px;
}


.personal-details-title-container {
    background: #16183D;
    width: 100%;
    height: auto;
    border-radius: 32px;

    &__status-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        &__title-box {
            color: #F7C903;
            font-family: Poppins;
            font-size: 31px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 40.3px */
        }
    
        &__status-box {
            background: rgba(255, 255, 255, 0.05);
            border-radius: 14px;
            padding: 10px 20px;
        }
    }
}
.personal-details-form-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;

    &__child {
        grid-column: span 2;

        &--custom {
            grid-column: span 2;
        }
    }

    &--span-4 {
        grid-column: span 4; 
    }

    &--span-1 {
        margin-top: 30px;
        grid-column: span 1; 
    }

    &--flex-span-2 {
        grid-column: span 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

    }
}
.personal-details-edit-button {
    text-wrap: nowrap !important;
    height: 54px !important;
    padding: 16px 32px !important;
    font-size: 19px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22px !important; /* 115.789% */
    text-transform: capitalize !important;
    min-width: 240px !important;
}

.personal-details-save-button,
.personal-details-cancel-button {
    text-wrap: nowrap !important;
    height: 54px !important;
    padding: 16px 32px !important;
    font-size: 19px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22px !important; /* 115.789% */
    text-transform: capitalize !important;
    min-width: 240px !important;
}

.personal-details-cancel-button{
    border: 1px solid #FFF !important;
    background: #16183D !important;
    color: #FFF !important;
}

@media (max-width: 768px) {
    .personal-details-main-container{
        padding: 20px;
    }
    .personal-details-title-container {
        &__status-title-box {
            &__title-box {
                font-size: 24px;
            }
        }
    }
}
 
@media (max-width: 700px) {
    .personal-details-edit-button {
        min-width: 320px !important;
        height: 42px !important;
    }
    .personal-details-save-button,
    .personal-details-cancel-button  {
        width: auto !important;
        min-width: 0 !important;
        flex: 1;
        height: 42px !important;
    }
    .personal-details-form-container{
            &--flex-span-2 {
                grid-column: span 4;
                display: flex;
                align-items: center;
                gap: 20px;
        }
        
    }
}

@media (max-width: 540px) {
    .personal-details-edit-button {
        min-width: 160px !important;
        height: 42px !important;
        padding: 8px 16px !important;
    }
    .personal-details-form-container{
            &--flex-span-2 {
                grid-column: span 4;
                gap: 10px;
            }
        
    }
    .personal-details-title-container{
        &__status-title-box {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: flex-start;
            padding-bottom: 10px;
        }
    }
}

@media (max-width: 600px) {
    .personal-details-form-container{
        &__child {
            &--custom {
                grid-column: span 4;
            }
        }
    }
}

