.dialog {

    &__title-icon-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        &__title-box {
            display: flex;
            color: #F7C903;
            font-family: Poppins;
            font-size: 31px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 40.3px */
            justify-content: flex-start;
        }

        &__close-icon-box {
            display: flex;
            color: #F7C903;
            font-family: Poppins;
            font-size: 31px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 40.3px */
            justify-content: flex-end;
        }
    }
    &__form-row-container {
        width: 100%;
    }

    &__form-submit-button-container {
        width: 100%;
        display: flex;
        margin-top: 20px;
        justify-content: flex-start;
    }
}
.mui-dialog {
    margin: 0 !important;
    border-radius: 32px !important;
    width: 560px !important;
    height: 560px !important;
    padding: 30px !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
    background: rgba(37, 39, 74, 0.90) !important;
    backdrop-filter: blur(10px) !important;
}

.visibility-icon {
    color: #FFFFFF;
    margin-right: 7px !important;
}

@media (max-width: 899px) {
    .dialog {
      &__title-icon-container {
        &__title-box {
            font-size: 24px;
      }
    }
  }
  .mui-dialog {
    width: 345px !important;
  }
}