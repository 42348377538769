.reset-pass-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 2rem;
  margin-right: 2rem;

  @media (min-width: 600px) {
    // sm breakpoint
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: 960px) {
    // md breakpoint
    margin-top: 8rem;
    margin-bottom: 8rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  &__title {
    color: var(--main-color);
    font-weight: 700;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;

    @media (min-width: 960px) {
      font-size: 40px;
    }
  }

  &__button {
    margin-top: 3rem !important;
    margin-bottom: 2rem;
    background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--main-color) !important; // Define this variable to match your primary.main color

    &:hover {
      background: #ffffff !important; // Use the white color or theme variable if needed
      box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5);
    }
  }
}
