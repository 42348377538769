.details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    margin-bottom: 15%;

    &__top-box {
        background: #16183D;
        width: 100%;
        height: auto;
        border-radius: 32px;
        padding: 30px;
    }

    &__status-user-badge-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 30px;
    }

    &__status-user-box {
        gap: 3px;
    }

    &__level-information-box {
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        width: 100%;
    }

    &__balance-box-container {
        display: flex;
        align-items: center;
        // grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        width: 100%;
    }

}


@media (max-width: 1920px) {
    .details-container { 
        &__balance-box-container {
            gap: 20px;
        }
    }  
}

@media (max-width: 1024px) {
    .details-container { 
        margin-bottom: 35%;
        &__balance-box-container {
            flex-direction: column;
            gap: 15px;
        }
    }  
}

@media (max-width: 500px) {
    .details-container { 
        &__status-user-badge-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
        }
    }  
}

@media (max-width: 375px) {
    .details-container { 
        margin-bottom: 300px;
    }  
}