.user-details-header {
    position: relative;

    &__title {
        font-size: 24px !important;
        font-weight: 600 !important;
        color: var(--main-color) !important;

        // @media (max-width: 375px) {
        //     font-size: 20px !important;
        //   }
    }
}

.user-details-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__content {
        display: flex;
        gap: 20px;
        width: 100%;

        &__input {
            width: 100%;
        }
    }
}

.mui-dialog-details-popup {
    display: flex !important;
    flex-flow: column !important;
    margin: 0 !important;
    width: 450px !important;
    padding: 24px !important;
    gap: 14px !important;
    border-radius: 30px !important;
}

.continue-button-container {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    
    &__custom-continue-button {
      margin-top: 1rem !important;
      margin-bottom: 2rem !important;
      background: linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%) !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      color: var(--main-color) !important;

      &:hover {
        background: white !important;
        box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
      }
    }
}