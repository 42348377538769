.verify-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #16183D;
    width: 100%;
    height: auto;
    border-radius: 14px;
    padding: 20px;
    gap: 20px;

    &__image-wrapper {
        padding: 20px 30px;
        position: relative;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img-box {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background:url(../../../assets/bubble.svg) no-repeat center center;
        background-size: cover;
        z-index: 1;
    }

    &__img {
        position: absolute;
        width: 70%;
        height: 70%;
        opacity: 1;
        background:url(../../../assets/shield-temp.png) no-repeat center center;
        background-size: contain;
        z-index: 2;
    }

    &__text-box {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
    }
}

.verify-button {
    text-wrap: nowrap !important;
    padding: 10px 32px !important;
    min-height: 42px !important;
}

@media (max-width: 700px) {
    .verify-box-container {
        flex-direction: column;
        padding: 30px 20px;

        &__text-box {
            text-align: center;
        }
    }
}