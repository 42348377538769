.multiselect {
  width: 100%;
  height: 46px;
  background-color: #25274a;
  font: 16px Poppins !important;
  z-index: 1 !important;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: -10px;
    bottom: 0;
    width: 450px;
    height: 60px;
    background-color: #25274a;
    border-radius: 14px 14px 0 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &--is-open {
    &::after {
      opacity: 1;
    }
  }

  .multiselect__input {
    display: flex;
    justify-content: start;

    &--has-selected-options {
      justify-content: space-between;
    }

    &__placeholder {
      padding-top: 1px;
      padding-left: 10px;
      color: #fff;
    }
  }

  .multiselect__input__chip {
    background: var(--yellow-gradient);
  }

  svg {
    color: #fff;
  }

  &__list {
    padding-right: 4px;
    width: 450px !important;
    height: 450px !important;
    transform: translate(0, 0) !important;
    background-color: #25274a !important;
    border-radius: 0 0 14px 14px !important;
    overflow: hidden !important;
    // z-index: 1 !important;

    // &::before {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 60px;
    // }

    ul {
      margin-top: 5px;
      padding-top: 0;
      padding-bottom: 0;
      height: 380px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
        padding-right: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d9d9d9;
      }
    }

    &__item {
      &::after {
        content: '';
        display: block;
        width: 80px;
        height: 40px;
        background-repeat: no-repeat;
        opacity: 0.3;
      }

      &--selected {
        background-color: none !important;

        &::after {
          opacity: 1;
        }
      }

      &__checkbox {
        &--checked {
          > svg {
            color: #ffd308 !important;
          }
        }

        > svg {
          color: #454765;
        }
      }

      &__text {
        color: #fff;
      }
    }

    &__extra-actions {
      position: fixed;
      bottom: 0;
      display: flex;
      gap: 10px;
      padding: 5px 10px 10px;
      width: 100%;
      background-color: #25274a;

      button {
        width: 50%;
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .multiselect {
    &__list {
      height: 360px !important;
    }
  }
}
