.badge {
  display: flex;
  align-items: center;
  gap: 8px;

  #badge-name {
    color: #f7c903;
    font-size: 17px;
    font-weight: 700;
  }

  &--with-background {
    gap: 0;

    #badge-img {
      z-index: 1;
      border-radius: 60px;
      box-shadow: 0px 0px 10px 0px #ffd41b80;
    }

    #badge-name {
      padding: 8px 15px 8px 20px;
      transform: translate(-15px);
      font-size: 12px;
      text-transform: uppercase;
      color: #0d1365;
      background: var(--yellow-gradient);
      border-radius: 0 50px 50px 0;
      box-shadow: 0px 0px 10px 0px #ffd41b80;
    }
  }
}

@media (max-width: 899px) {
  .badge {
    &--with-background {
      #badge-img {
        width: 40px !important;
      }
    }

    #badge-img {
      width: 28px;
    }

    #badge-name {
      font-size: 12px;
    }
  }
}
