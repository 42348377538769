.custom-switch {
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__title {
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .custom-switch {
    &__title {
      font-size: 14px;
    }
  }
}
