.shop-page__info-box {
  font-family: Poppins;
  color: white;

  &__title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;

    &--clamped {
      @extend .shop-page__info-box__text;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 36px;
    }

    &--is-expanded {
      display: flex;
      max-height: 500px;
      height: auto;
      transition: max-height 0.25s ease-in;
    }
  }

  &__read-more-button {
    margin-bottom: 20px;
    padding: 5px 0;
    background-color: #0000;
    border: none;
    text-decoration: underline;
    font-family: Poppins;
    font-weight: 300;
    color: white;
    cursor: pointer;
  }

  &__slider {
    margin: 10px 0 20px;
    height: 100px;
    background-size: 100%;
    background-position-y: 25px;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/shop/slider.svg');

    &-icon {
      animation: popup 4s 0.5s ease-in-out backwards;
      animation-iteration-count: infinite;
    }
  }

  &__free-spins {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 30px;

    &-box {
      display: flex;
      align-items: end;
      justify-content: center;
      width: 184px;
      height: 184px;
      font-size: 12px;
      font-weight: 500;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url('../../../../assets/shop/selectQuantityBox.svg');

      &--wide {
        @extend .shop-page__info-box__free-spins-box;
        width: 256px;
        background-image: url('../../../../assets/shop/selectGameBox.svg');
      }
    }

    &-separator {
      display: flex;
      align-items: center;
      transform: translate(0, -20px);
    }
  }
}

@media (max-width: 768px) {
  .shop-page__info-box {
    &__slider {
      height: 65px;
    }

    &__free-spins {
      margin: 15px 0 0;

      &-box {
        height: 125px;

        &--wide {
          @extend .shop-page__info-box__free-spins-box;
        }
      }
    }
  }
}

@keyframes popup {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(40, 0);
  }

  35% {
    transform: translate(35, 0);
  }

  50% {
    transform: translate(35, 0);
  }

  75% {
    transform: translate(35, 0);
  }

  80% {
    transform: translate(35, 0);
  }

  85% {
    transform: translate(-6px, 0);
  }

  90% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
