.wallet-menu-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;

  &__info {
    display: flex;
    flex-direction: column;

    &__title {
      color: var(--Text-Semi-Black, rgba(33, 33, 33, 0.5));
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 16.8px */
    }

    &__value {
      display: flex;
      align-items: center;
      gap: 5px;
      &__text {
        color: #212121;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
      }
    }
  }
  &__button {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 40px;
    background: rgba(3, 30, 68, 0.35);
    cursor: pointer;

    &__icon {
      width: 100%;
      height: 100%;
      color: white !important;
    }
  }
}
