.fullscreen-list {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 78px;
  height: 100%;
  font-family: Poppins;
  background-color: #25274a;
  z-index: 1100;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--title {
      padding: 20px 15px;
    }

    &--search {
      padding: 0 15px;
      margin-top: 40px;

      label {
        margin-bottom: 15px;
        text-transform: none;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0em;
      }
    }
  }

  &__title {
    font-size: 29px;
    font-weight: 700;
  }

  &__custom-search {
    > div {
      fieldset {
        background-color: transparent !important;
        border: 1px solid rgba(255, 255, 255, 0.15);
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;

    .fullscreen-list__item {
      padding: 0 10px;
      min-height: 55px !important;

      &::after {
        content: '';
        display: block;
        margin-right: 10px;
        width: 80px;
        height: 40px;
        background-repeat: no-repeat;
        opacity: 0.3;
      }

      &--selected {
        background-color: none !important;

        &::after {
          opacity: 1;
        }
      }

      &__checkbox {
        &--checked {
          > svg {
            color: #ffd308 !important;
          }
        }

        > svg {
          color: #454765;
        }
      }

      &__text {
        padding-left: 5px;
        text-align: left;
        color: #fff;
      }
    }
  }

  &__actions {
    position: fixed;
    bottom: 0;
    display: flex;
    gap: 10px;
    padding: 16px;
    width: 100%;
    background-color: #25274a;
  }
}
