.main-login {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  
    &__content-box {
      display: flex;
      flex-flow: column;
      grid-column: span 12;
      
  
      @media (min-width: 960px) {
        grid-column: span 8;
      }

      &__inner-content {
        display: flex;
        flex-flow: column;
        align-items: center;
        

        &__container {
            max-width: 616px;
            width: 100%;

            @media (min-width: 960px) {
              min-width: 616px;
            }

            &__info-text-container {
              margin-top: 1rem;
              margin-left: 2rem;
              margin-right: 2rem;
              color: var(--main-color);
              font-weight: 400;
              font-family: 'Poppins';
              font-size: 11px;

              @media (min-width: 960px) {
                margin-left: 4rem;
                margin-right: 4rem;
                font-size: 12px;
              }
            }
            
            &__email-inputs-container {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;

                @media (min-width: 960px) {
                    margin-left: 4rem;
                    margin-right: 4rem;
                }
            }

            &__signin-button-container {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                @media (min-width: 960px) {
                    margin-left: 4rem;
                    margin-right: 4rem;
                }
            
                &__custom-signin-button {
                  margin-top: 1rem !important;
                  margin-bottom: 2rem !important;
                  background: linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%) !important;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
                  color: var(--main-color) !important;
            
                  &:hover {
                    background: white !important;
                    box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
                  }
                }
            }

            &__check-box-container {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: auto;
            
                @media (min-width: 960px) {
                    margin-left: 5rem;
                    margin-right: 5rem;
                }
            }

            &__create-an-account {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                width: auto;
                color: rgb(131, 140, 154);
            
                @media (min-width: 960px) {
                    margin-left: 4rem;
                    margin-right: 4rem;
                }

                &__divider-span-5 {
                    grid-column: span 5;
                }

                &__or {
                    position: relative;
                    top: -9px;
                    text-align: center;
                    font-family: Poppins;
                }

                &__divider-span-6 {
                    grid-column: span 6;
                }
            }

            &__dont-have-account-text {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                color: rgb(131, 140, 154);
                font-size: 14px;
                font-weight: 500;
                font-family: Poppins;
            
            }
            &__create-account-button-container {
                margin-top: 1rem;
                margin-left: 2rem;
                margin-right: 2rem;
                display: flex;
                justify-content: center;
                flex-direction: column;
            
                @media (min-width: 960px) {
                    margin-left: 4rem;
                    margin-right: 4rem;
                }
            
                &__custom-signin-button {
                  margin-top: 1rem !important;
                  margin-bottom: 2rem !important;
                  color: rgb(3, 30, 68) !important;
                  box-shadow: 0px 0px 5px  rgba(37, 117, 252, 0.5) !important;
                  border: 2px solid rgb(37, 117, 252) !important;
                  
                  &:hover {
                    background: white !important;
                    box-shadow: 0px 0px 5px rgba(37, 117, 252, 0.5) !important;
                    border: none !important;
                  }
                }
            }
            
        }
      }
    }
    &__background-box {
      background-image: url('../../../assets/login.svg');
      background-repeat: no-repeat;
      background-color: var(--main-color);
      background-size: cover;
      background-position: center;
      display: none;
  
      @media (min-width: 960px) {
        display: block;
        grid-column: span 4;
      }
    }
}

.check-box-remember {
    color: rgb(131, 140, 154);
    font-weight: 400;
    .MuiFormControlLabel-label {
      font-size: 14px !important;

      @media (min-width: 960px) {
        font-size: 16px !important;
      }
    }
    
    
}

.forgot-password-link-button {
    text-decoration: none !important;
    cursor: pointer !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 14px !important;

    @media (min-width: 960px) {
      font-size: 16px !important;
    }
}
  