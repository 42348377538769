.bonuses {
  &__recent {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__add-coupon {
    margin-top: 20px;
    max-width: 240px;
    width: 50%;
  }

  .bonuses__new-coupon {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    input {
      color: #fff;
      z-index: 1;

      &::placeholder {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }
    }

    &:hover {
      fieldset {
        background-color: #2b2e52 !important;
        transition: background-color 0.3s !important;
        border: none !important;
      }
    }

    fieldset {
      background-color: #25274a;
      z-index: 0;
    }

    &-helper-text {
      margin-top: 5px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      opacity: 0.4;

      &--error {
        color: red;
        opacity: 1;
      }
    }

    &-adornment {
      padding: 0 6px;
      z-index: 2;
      color: #fff;

      p {
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        color: #fff;
      }
    }

    &-end-adornment {
      z-index: 10;

      span {
        button {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    button {
      max-width: 340px;

      &:disabled {
        color: #c3c3c3 !important;
      }
    }
  }
}

@media (max-width: 576px) {
  .bonuses {
    &__recent {
      gap: 15px;
    }

    &__add-coupon {
      width: auto;
      margin-top: 15px;
    }
  }
}
