.cashback-screen {
    display: flex;
    flex-direction: column;
    height: auto;
    background: #16183D;
    border-radius: 14px;

    &__container {
        display: flex;
        height: 90px;
        width: 100%;
        background: #16183D;
        border-radius: 14px;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
    
        &__left-side {
            display: flex;
            flex-direction: row;
            gap: 15px;
            align-items: center;  
    
            &__image {
                width: 70px;
                height: 70px;
            }
    
            &__text {
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: center;
                align-items: flex-start;
    
                &__title {
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 26.6px */
                }
            }
        }
    
        &__right-side {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            
    
            &__cashback-amount {
                color: #F7C903;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%; /* 20.8px */
                text-wrap: nowrap;

                &--mobile {
                    text-wrap: nowrap;
                    color: #F7C903;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%; /* 20.8px */
                    text-wrap: nowrap;
                    margin-bottom: 20px;
                }
            }

            &--bottom-mobile {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;
                margin-right: 20px;
                margin-left: 20px;
            }
    
    
        }
    }
}

.chasback-bonus-collect {
    &__button {
        width: 141px !important;
        height: 42px !important;
        padding: 10 32px !important;
        color: #031e44 !important;
        background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    
        &:hover {
          background: #fff !important;
          box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
        }

        &--mobile {
            width: 100% !important;
            height: 42px !important;
            padding: 10 32px !important;
            color: #031e44 !important;
            background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
            margin-bottom: 20px !important;
        }
      }
    
}

  @media (max-width: 767px) {
    .cashback-screen {
        &__container {
            height: auto;
        }
    }
    .chasback-bonus-collect{
        display: none;
    }
  }

  @media (max-width: 575px) {
    .cashback-screen {
        &__container {
            &__right-side {
                &__cashback-amount {
                    display: none;
                }
            }
        }
    }
    .chasback-bonus-collect{
        display: none;
    }
  } 

  