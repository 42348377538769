.custom-icon-button {
  width: 40px !important;
  height: 40px !important;

  &:disabled {
    mix-blend-mode: luminosity;
  }

  &--small {
    width: 24px !important;
    height: 24px !important;
  }

  &--gold {
    background: var(--yellow-gradient) !important;

    > svg {
      color: #25274a;
    }
  }

  &--white {
    background: white !important;
  }

  &--transparent {
    background: transparent !important;
  }

  &--text {
    color: #031e44 !important;

    &-white {
      color: #fff !important;
    }

    &-darkblue {
      color: #031e44 !important;
    }
  }
}

.custom-icon-button__badge {
  > span {
    transform: translate(-125%, 125%);
    color: #000;
    background: var(--yellow-gradient) !important;
  }
}
