.username-container {
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
}

@media (max-width: 1023px) {
    .username-container { 
        font-size: 21px;
    }  
}

@media (max-width: 700px) {
    .username-container { 
        font-size: 19px;
    }  
} 