.edit-message-container {
    display: flex;
    padding: 15px;
    align-items: center;
    gap: 14px;
    align-self: stretch;
    border-radius: 14px;
    height: 54px;
    margin-top: 30px;
    padding: 15px;
    border: 1px solid #F7C903;

    &__text-box {
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
}

@media (max-width: 576px) {
    .edit-message-container {
        height: auto;
    }
}