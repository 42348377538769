.challenges {
  font-family: Poppins;

  &__islands {
    padding-top: 112px;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/challenges/mainBackground.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 700;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 0;
        width: 135px;
        border: none;
        background: none;
        color: white;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        cursor: pointer;
      }
    }

    &-container {
      padding: 0 5%;

      &-row {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__cards {
    display: grid !important;
    gap: 16px;
  }
}

.challenge-island {
  position: relative;

  &-info {
    transform: translate(0, -20%);
  }

  &:hover {
    .challenge-island-info {
      display: flex;
      animation: fadeInPosition 0.5s ease-out;
    }
  }
}

@media (max-width: 899px) {
  .challenge-island {
    align-self: flex-start;

    &:nth-of-type(even) {
      align-self: flex-end;
    }
  }
}

@media (max-width: 768px) {
  .challenges__title {
    gap: 0 !important;
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media (min-width: 0px) {
  .challenges__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .challenges__cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1200px) {
  .challenges__cards {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1620px) {
  .challenges__cards {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 1920px) {
  .challenges__cards {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media (min-width: 2160px) {
  .challenges__cards {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@keyframes fadeInPosition {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate(0, 5%);
    user-select: none;
  }

  1% {
    visibility: visible;
    opacity: 0;
    transform: translate(0, 5%);
    user-select: none;
  }

  100% {
    visibility: visible;
    display: flex;
    opacity: 1;
    transform: translate(0, -20%);
    user-select: initial;
  }
}
