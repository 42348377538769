.shop-balance-box-earn-more-banner-container {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f7c903;
  border-radius: 16px;
  height: 60px !important;
  padding: 5px 15px !important;
}
.shop-page-container {
  display: flex;
  gap: 180px;
  flex-direction: column;
  margin-bottom: 90px;
}

.shop-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 140px 20% 0;
  font-family: Poppins;

  &__button-tabs {
    display: none;
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;

    &-item {
      &--wide {
        flex-basis: 70%;
      }

      &--narrow {
        flex-basis: 30%;
      }
    }
  }

  &__title {
    display: flex;
    flex: 6;
    flex-direction: column;

    &-text {
      font-size: 39px;
      font-weight: 700;
    }
  }

  &__balance-box {
    flex: 4;
  }
}

//TODO move styles to CustomTextIconButton component when component is created
.custom-text-icon-button {
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 0;
  width: max-content;
  text-align: left;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 1600px) {
  .shop-page {
    padding: 140px 10% 0;
  }
}

@media (max-width: 1400px) {
  .shop-page {
    padding: 140px 6% 0;
  }
}

@media (max-width: 1200px) {
  .shop-page {
    padding: 140px 70px 0;
  }
}

@media (max-width: 1023px) {
  .shop-page {
    padding: 140px 70px 0;
    &__row {
      flex-direction: column;
      gap: 10px;
    }
  }
}

@media (max-width: 899px) {
  .shop-page {
    padding: 140px 70px 0;
  }
}

@media (max-width: 700px) {
  .shop-page {
    padding: 140px 20px 0;
  }
}

@media (max-width: 767px) {
  .shop-page {
    &__button-tabs {
      display: flex;
      margin-top: 32px;

      .group-container {
        display: flex;
        flex: 1;
        margin-top: -24px;

        .group-button,
        .group-button--select {
          flex: 1;
          padding: 6px 12px;
          font-size: 12 !important;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .shop-balance-box-earn-more-banner-container {
      width: 140px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1160px) {
  .shop-balance-box-earn-more-banner-container {
      width: 170px;
  }
}

@media (max-width:  767px) {
  .shop-page-container {
    gap: 132px;
  }
}
