.profile-menu {
  margin-top: 48px;

  .MuiPaper-root {
    border-radius: 16px;
    min-width: 220px;
  }

  .MuiMenu-list {
    padding: 4px 0;
  }

  .profile-menu__item {
    display: flex;
    gap: 20px;

    section {
      display: flex;
      align-items: center;

      .profile-menu__item-icon {
        color: #0d1365 !important;

        .profile-menu__item-icon__item {
          width: 24px !important;
          height: 24px !important;
        }
      }

      .profile-menu__item-text {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
