.button-group {
    height: 40px;
    background: #2575FC !important;
    border-radius: 60px !important;
    color: white !important;
  
      &__option {
        color: white !important;
        height: 100% !important;
        min-width: 100px !important;
        font-size: 14px !important;
        font-weight: 600;
        border-radius: 100px !important;
  
        &--active {
          min-width: 100px !important;
          background: rgba(37, 39, 74, 0.90) !important;
          color: white !important;
          height: 100% !important;
          border-radius: 60px !important;
          border: none !important;
          font-size: 14px !important;
        }
  
        &:hover {
          background-color: rgba(37, 39, 74, 0.90) !important;
        }
      }

      @media (max-width: 576px) {

          &__option {
            font-size: 12px !important;
            padding: 8px !important;
            line-height: 32px !important;
          }
      }
  }

  .forgot-password-box {
    display: flex;
    justify-content: flex-end;
  }

  .signin-button-container {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 960px) {
        margin-left: 4rem;
        margin-right: 4rem;
    }

    &__custom-signin-button {
      margin-top: 1rem !important;
      margin-bottom: 2rem !important;
      background: linear-gradient(90deg, #E3AB00 0%, #FFD000 50%, #FFE539 100%) !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      color: var(--main-color) !important;

      &:hover {
        background: white !important;
        box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
      }
    }
}

.create-account-button-container {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 960px) {
        margin-left: 4rem;
        margin-right: 4rem;
    }

    &__custom-signin-button {
      margin-top: 1rem !important;
      margin-bottom: 2rem !important;
      color: rgb(3, 30, 68) !important;
      box-shadow: 0px 0px 5px  rgba(37, 117, 252, 0.5) !important;
      border: 2px solid rgb(37, 117, 252) !important;
      
      &:hover {
        background: white !important;
        box-shadow: 0px 0px 5px rgba(37, 117, 252, 0.5) !important;
        border: none !important;
      }
    }
}

.mui-dialog-login {
    display: flex !important;
    flex-flow: column !important;
    margin: 0 !important;
    width: 450px !important;
    padding: 24px !important;
    gap: 14px !important
}

.signin-title {
    color: var(--main-color);
    font-weight: 700;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-icon-button {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    color: #9e9e9e !important;
}

.info-text {
  color: var(--main-color);
  font-weight: 400;
  font-family: 'Poppins';
  font-size: 11px;

  @media (min-width: 960px) {
    font-size: 12px;
  }
}