.account-details-balance-box-container {
    display: flex;
    background: #16183D;
    padding: 20px 30px;
    align-items: center;
    border-radius: 16px;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
    flex: 1;

    &__text-value-box {
        display: flex;
        border-radius: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__text-box {
        gap: 7px;
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */ 
        text-wrap: nowrap;
    }

    &__value-stars-box {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: flex-start;
    }

    &__value-box {
        color: #FFF;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 31.2px */
    }

    &__icon-box {
        color: #FFF;
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 8px;
        border-radius: 14px;
        background: rgba(255, 255, 255, 0.05);
        width: 44px;
        height: 44px;
    }
}

.stars-icon {
    width: 20px;
    height: 20px;
    color: #ffd000;
}


.icon-button {
    padding: 0px;
    margin: 0px; 
    background-color: transparent;
    &:hover {
        background-color: transparent;
    }
}

