$button-height: 40px;

.flag-img {
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &--border-blue {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 2px solid blue;
  }

  &--border-white {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 2px solid white;
  }
}

.language-menu {
  margin-top: 48px;
  border-radius: 14px !important;
}

.languages-menu-item {
  display: flex !important;
  gap: 10px !important;
  justify-content: flex-end !important;
  border-radius: 14px !important;

  &__display-name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.change-language-tooltip {
  background: rgba(255, 255, 255, 0.15) !important;
  padding-left: 10px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-right: 16px !important;
  border-radius: 24px !important;
  width: 57px !important;
}

.arrow-drop-icon {
  margin: 0 !important;
  color: white !important;
}

.button {
  &__wallet {
    border: 2px solid rgb(227, 171, 0) !important;
    color: white !important;
    margin-right: 16px !important;
    height: $button-height !important;
    padding: 0 15px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 14px !important;
  }

  &__signup {
    background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
    color: var(--main-color);
    height: $button-height !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    margin-right: 16px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    &:hover {
      background: white !important;
      border: none !important;
      box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
    }
  }
  &__signin {
    border: 2px solid !important;
    color: rgb(227, 171, 0) !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    height: 40px !important;
    line-height: 20px !important;
    margin-right: 16px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    &:hover {
      color: var(--info-main) !important;
    }
  }
}

.profile-menu__badge {
  > span {
    top: 5px;
    right: 35px;
  }
}

.profile-menu__badge--mobile {
  top: -15px;
  right: 0;
}
