.payment-method {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 80px;
  border-radius: 14px;
  overflow: hidden;
  background-color: #25274a;

  &:hover {
    cursor: pointer;
    background-color: #2b2e52;
    transition: background-color 0.3s;
  }

  &--is-selected {
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }
}

@media (max-width: 1024px) {
  .payment-method {
    width: 156px;
    height: 80px;
  }
}

@media (max-width: 768px) {
  .payment-method {
    width: 112px;
    height: 56px;
  }
}

@media (max-width: 576px) {
  .payment-method {
    width: 98px;
    height: 56px;
  }
}
