.receiving-details__bank {
  font-family: Poppins;

  &-info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__option-image {
      width: 24px;
      height: 24px;
      border-radius: 100%;
    }

    .receiving-details__bank-info__dropdown {
      input {
        padding-left: 20px !important;
      }
    }

    .receiving-details__bank-info__input {
      input {
        padding-left: 20px;
        color: #fff;
        z-index: 1;
        font-family: Poppins !important;

        &::placeholder {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }
      }

      &:hover {
        fieldset {
          background-color: #2b2e52 !important;
          transition: background-color 0.3s !important;
          border: none !important;
        }
      }

      fieldset {
        background-color: #25274a;
        font-family: Poppins !important;

        z-index: 0;
      }

      > div {
        > p {
          margin: 0;
        }
      }

      &-helper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        &-text {
          margin-top: 5px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          opacity: 0.4;

          &--error {
            color: red;
            opacity: 1;
          }
        }

        &-button {
          background-color: #25274a;
          border: none;
          padding: 10px 20px;
          gap: 8px;
          border-radius: 14px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          color: #fff;

          &:hover {
            cursor: pointer;
            background-color: #2b2e52;
            transition: background-color 0.3s;
          }
        }
      }

      &-adornment {
        padding: 0 6px;
        z-index: 2;
        color: #fff;

        p {
          font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          text-align: left;
          color: #fff;
        }
      }

      &-end-adornment {
        z-index: 10;

        span {
          button {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
    }
  }

  &-details {
    display: block;
    flex-direction: column;
    margin-top: 20px;
    color: #fff;

    &-title {
      font-size: 21px;
      font-weight: 600;
    }

    &-row {
      display: flex;
      gap: 15px;
      margin-top: 10px;

      &__input-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;

        label {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .receiving-details__bank {
    &-details {
      &-row {
        flex-direction: column;
      }
    }
  }
}
