.balance-box {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #f7c903;
  border-radius: 16px;

  &__title {
    display: flex;
    flex-direction: column;
    text-align: right;

    &-text {
      font-size: 14px;
      font-weight: 400;
    }

    &-currency {
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
    }

    &-currency-earn-more { 
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 19px;
      font-weight: 700;
      gap: 2px;
    }

    &-text-earn-more {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      text-align: left;
    }
  }
}


@media (max-width: 700px) {
  .balance-box {
    &__title {
      &-text-earn-more {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-wrap: nowrap;
       }
    }
  }
}
