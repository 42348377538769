.promotions {
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  &__title {
    font-weight: 700;
    font-size: 64px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;

    &::after {
      content: '';
      width: 720px;
    }
  }
}

@media screen and (max-width: 2560px) {
  .promotions {
    padding: 0 10%;
  }
}

@media screen and (max-width: 1920px) {
  .promotions {
    padding: 0;
  }
}

@media screen and (max-width: 1680px) {
  .promotions {
    &__container {
      &::after {
        width: 610px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .promotions {
    &__container {
      &::after {
        width: 510px;
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .promotions {
    &__container {
      &::after {
        width: 422px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .promotions {
    padding: 0 5%;

    &__title {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 576px) {
  .promotions {
    gap: 32px;
    padding: 0 15px;
  }
}
