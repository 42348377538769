.otp-dialog {

    &__title-icon-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        &__title-box {
            display: flex;
            color: #F7C903;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 40.3px */
            justify-content: flex-start;
        }

        &__close-icon-box {
            display: flex;
            color: #F7C903;
            font-family: Poppins;
            font-size: 31px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 40.3px */
            justify-content: flex-end;
        }
    }

    &__subtitle-box {
        text-align: center;
        color: #F7C903;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 40.3px */
    }
    &__form-row-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-self: center;
        gap: 10px;
    }

    &__form-submit-button-container {
        width: 100%;
        display: flex;
        margin-top: 20px;
        justify-content: flex-start;
    }

    &__form-resend-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}

.mui-dialog-otp {
    margin: 0 !important;
    border-radius: 32px !important;
    width: 370px !important;
    height: 400px !important;
    padding: 30px !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
    background: rgba(37, 39, 74, 0.90) !important;
    backdrop-filter: blur(10px) !important;
}