.tournament-card__clock-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  &--earn-more-banner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }

  &--extra-content {
    position: relative;

    &::after {
      content: ':';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(11px, -90%);
      font-size: 20px;
    }
  }

  &__value {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    width: 72px;
    height: 60px;
    background-color: #25274a;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
  }

  &--earn-more-banner-value {
    display: flex;
      flex-flow: column;
      flex-grow: 1;
      width: 50px;
      height: 50px;
      background-color: rgba(37, 39, 74, 0.80);
      border-radius: 14px;
      align-items: center;
      justify-content: center;
      font-family: Poppins;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      color: #fff;
  }
}

@media screen and (max-width: 960px) {
  .tournament-card__clock-box {
    flex: 1;

    &__value {
      width: 100%;
    }
  }
}
