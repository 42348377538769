.requirements-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__icon-label-box {
    display: flex;
    align-items: center;
    gap: 10px;

    &__label {
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.28px;
      &--dark {
        color: var(--main-color);
      }
    }
  }
}

.check-circle-icon {
  color: #f7c903;
}

.cancel-icon {
  color: rgba(255, 255, 255, 0.3);
}
