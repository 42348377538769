.container {
    display: inline-flex;
    align-items: center;
    gap: 7px;

    &__status-text {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
    }

    &__icon-box {
        display: inline-flex;
        align-items: center;
        width: 16px;
        height: 20px;
    }
}

@media (max-width: 700px) {
    .container {  
        &__status-text {
            font-size: 14px;
        }
    }  
}