.exchange-slider {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 32px;
  background: #16183d;

  &__slider {
    width: 100%;
    padding-left: 10px;
  }

  &__title {
    color: var(--Yellow-text, #f7c903);
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    padding-bottom: 10px;
  }

  &__values {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-current {
      display: flex;
      gap: 10px;
      align-items: center;
      color: #fff;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;

      &-img {
        width: 40px;
        height: 40px;
      }
    }
    &-max {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      gap: 8px;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0.05);
      color: #fff;
      cursor: pointer;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      &:hover {
        background-color: rgba(255, 255, 255, 15%);
      }
    }
  }
  &__legend {
    display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;

    &-rate {
      display: flex;
      gap: 5px;
      align-items: center;
      &-text {
        color: var(--white, #fff);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
      }
      &-icon {
        width: 16px !important;
        height: 16px !important;
        color: #ffd000;
      }
    }

    &-text {
      color: var(--white, #fff);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
    }
  }
}

.custom-slider {
  .MuiSlider-thumb {
    background: white;
    height: 20px;
    width: 20px;
  }

  .MuiSlider-track {
    border: none;
    height: 15px;
  }

  .MuiSlider-rail {
    box-shadow: inset 0px 0px 4px -2px #000;
    background-color: rgba(255, 255, 255, 0.1);
    height: 12px;
  }

  .MuiSlider-mark {
    width: 1px;
    height: 8px;
    background: white;
    opacity: 0.2;
  }
}

@media (min-width: 768px) {
  .exchange-slider {
    padding: 30px;

    &__title {
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .exchange-slider {
    &__title {
      font-size: 31px;
    }
  }
}
