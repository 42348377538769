.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  max-width: 233px;
  min-height: 352px;
  background-color: #16183d;
  border-radius: 16px;
  box-shadow: inset 0px 0px 0px 2px rgba(13, 61, 142, 0);

  &:hover {
    box-shadow: inset 0px 0px 0px 2px rgba(13, 61, 142, 1);
    transition: box-shadow 1.25s;

    .card-image {
      opacity: 1;
      transition: opacity 1.25s;
    }
  }

  &--is-locked {
    &:hover {
      box-shadow: none;

      .card-image {
        opacity: 0.5;
      }
    }

    .card-image {
      mix-blend-mode: luminosity;
    }

    .card-title {
      opacity: 0.3;
    }
  }

  &--is-claimed {
    &:hover {
      box-shadow: none;
    }

    .card-image {
      opacity: 1 !important;
    }

    .card-description {
      opacity: 0.3;
    }
  }

  &--is-compact {
    max-width: 137px;
    max-height: 163px;
    min-height: auto;

    &:hover {
      box-shadow: none;

      .card-image {
        opacity: 0.5;
      }
    }

    .card__icon-container {
      top: 10%;
      right: 10%;
    }

    .card-image {
      width: 60px;
      height: 60px;
      margin-top: 0;
      margin-bottom: 16px;
      opacity: 0.5;
    }

    .card-title {
      margin-top: 0;
      font-size: 14px;
      font-weight: 600;
    }

    .card-subtitle {
      font-size: 19px;
      font-weight: 600;
    }
  }

  &__icon-container {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;

    &--adjusted {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: white;
      color: #16183d;

      svg {
        width: 16px;
        height: 17px;
      }
    }
  }

  &-image {
    margin-top: 16px;
    opacity: 0.5;
  }

  &-title {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
  }

  &-subtitle {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    font-weight: 600;
    color: #f7c903;
  }

  &-description {
    display: flex;
    margin-top: 20px;
    padding: 0 24px;
    font-size: 12px;
    font-weight: 400;
  }

  &__popup {
    display: none;
  }
}

.card--is-compact.card--is-claimed {
  .card-image {
    opacity: 1 !important;
  }
}

@media (max-width: 1199px) {
  .card {
    min-height: auto;
    padding-bottom: 32px;

    &-image {
      width: 110px;
    }

    &-description {
      padding: 0;
      margin-top: 10px;
    }
  }
}

@media (max-width: 980px) {
  .card {
    &-description {
      padding: 0;
    }
  }
}

@media (max-width: 899px) {
  .card {
    &-image {
      width: 90px;
    }
  }
}

@media (max-width: 767px) {
  .card {
    min-height: 200px;
    padding-bottom: 16px;

    &--is-compact {
      min-height: auto;
      width: 78px;
      max-height: 111px;
      padding: 11px 6px;

      .card-image {
        width: 34px;
        height: 34px;
        margin-top: 12px;
        margin-bottom: 12px;
      }

      .card-title {
        font-size: 9px;
      }

      .card-subtitle {
        font-size: 12px;

        svg {
          font-size: 16px;
        }
      }
    }

    &__icon-container {
      width: 24px !important;
      height: 24px !important;

      &--adjusted {
        svg {
          width: 12px;
          height: 13px;
        }
      }
    }

    &-title {
      font-size: 14px;
    }

    &-subtitle {
      font-size: 21px;
    }

    &-description {
      display: none;
    }

    &__popup {
      &--is-visible {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1200;
      }

      &-content {
        position: relative;
        padding-bottom: 32px;
        max-width: 233px;
        min-height: 352px !important;
        transform: translate(-20px, 0);
        box-shadow: inset 0px 0px 0px 2px rgba(13, 61, 142, 1);
        animation: fadeInFromNone 0.25s ease-out;

        &__close {
          position: relative;
          top: -200px;
          left: 210px;
          z-index: 1;
        }

        .card-image {
          width: unset;
        }

        .card-title {
          font-size: 18px;
        }

        .card-subtitle {
          font-size: 24px;
        }

        .card-description {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .card {
    min-height: auto;

    &-image {
      width: 80px;
    }
  }
}

@media (max-width: 375px) {
  .card {
    &-image {
      width: 60px;
    }

    &-subtitle {
      font-size: 19px;
    }
  }
}

@keyframes fadeInFromNone {
  0% {
    display: flex;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
  }

  100% {
    display: flex;
    opacity: 1;
  }
}
