.info-pairs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__row {
        &:nth-child(odd) {
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.05);
        }
        
        display: flex;
        height: 40px;
        padding: 0px 15px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: var(--white, #FFF);
        font-style: normal;
        font-size: 14px;
        line-height: 130%; 

        &__key {
            font-weight: 400;
        }
        &__value {
            font-weight: 600;
        }
    }
}