.receiving-details {
  &__card-info {
    &__input {
      &-adornment {
        z-index: 20;

        svg {
          color: white;
        }

        &-img {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          width: 24px;
          height: 16px;
          border-radius: 4px;

          .visa {
            width: 90%;
            height: 16px;
            filter: invert(1);
          }

          .mastercard {
            width: 125%;
          }
        }
      }
    }
  }
}

.receiving-details__card-info__input {
  input {
    padding-left: 5px !important;
  }
}
