.multiline-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 10px;

  &__game {
    display: flex;

    &:first-child {
      grid-column: span 2;

      &::before {
        padding-bottom: calc(2 / 3 * 100%);
      }
    }

    &::before {
      content: '';
      display: block;
      padding-bottom: calc(3 / 2 * 100%);
      width: 0;
      height: 0;
    }
  }

  &__game--single-row {
    flex-shrink: 0;
    width: 240px;
    height: 360px;
  }
}

.multiline-container--900 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  .multiline-container__game {
    &:first-child {
      grid-column: span 3;
    }
  }
}

.multiline-container--710 {
  grid-template-columns: repeat(auto-fit, minmax(153px, 1fr));

  .multiline-container__game {
    &:first-child {
      grid-column: span 3;
    }
  }
}
.multiline-container--570 {
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));

  .multiline-container__game {
    &:first-child {
      grid-column: span 3;
    }
  }
}

@media (max-width: 900px) {
  .multiline-container {
    &__game--single-row {
      width: 144px;
      height: 216px;
    }
  }
}

@media (max-width: 457px) {
  .multiline-container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    &__game {
      &:first-child {
        grid-column: span 3;
      }
    }
  }
}

@media (max-width: 388px) {
  .multiline-container {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}
