.receiving-details__bitcoin {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Poppins;

  input {
    padding-left: 20px;
    color: #fff;
    z-index: 1;

    &::placeholder {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }
  }

  &:hover {
    fieldset {
      background-color: #2b2e52 !important;
      transition: background-color 0.3s !important;
      border: none !important;
    }
  }

  fieldset {
    background-color: #25274a;
    z-index: 0;
  }

  > div {
    > p {
      margin: 0;
    }
  }

  &-helper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &-text {
      margin-top: 5px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      opacity: 0.4;

      &--error {
        color: red;
        opacity: 1;
      }
    }

    &-button {
      background-color: #25274a;
      border: none;
      padding: 10px 20px;
      gap: 8px;
      border-radius: 14px;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      color: #fff;

      &:hover {
        cursor: pointer;
        background-color: #2b2e52;
        transition: background-color 0.3s;
      }
    }
  }

  .receiving-details__bitcoin-adornment {
    padding: 0 6px;
    z-index: 2;
    color: #fff;

    p {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      text-align: left;
      color: #fff;
    }
  }
}
