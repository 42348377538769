.pagination-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;

  .pagination {
    .pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      color: white;
      background-color: #08287c66;

      &--is-selected {
        color: #031e44;
        background-color: #fff;

        &:hover {
          color: #031e44;
          background-color: #fff;
        }
      }

      &:has(div.pagination-item-nav) {
        background-color: transparent;
      }

      &-nav {
        display: flex;
        width: 18px;
        height: 18px;
        align-items: center;
        justify-content: center;
        font-size: 10px;

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
