.primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 46px !important;
    background: var(--yellow-gradient);
    color: var(--main-color) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

    &:hover {
        background: white !important;
        box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5) !important;
    }
}