.settings-container {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 32px;
    background: #16183D;

    &__title {
        padding-bottom: 10px;
        margin-bottom: 20px;
        color: #F7C903;
        font-family: Poppins;
        font-size: 31px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 40.3px */
    }

    &__row-container {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        &__text-box {
            display: flex;
            justify-content: flex-start;
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 22.4px */
            flex: 1;
        }

        &__status-button-box {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            flex: 0;
        }

        &--verify-button-box-column {
            width: 100%;
        }

        &--verify-button-box-row {
        }

        &__cancel-button-box {
        }
    }

    &__row-container-password {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        &__text-box {
            display: flex;
            justify-content: flex-start;
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 22.4px */
            flex: 1;
        }

        &__cancel-button-box {
        }
    }

    &__horizontal-line {
        border: 1px solid rgba(255, 255, 255, 0.15);
        width: 100%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

.verify-button {
    display: flex;
    height: 42px !important;
    min-width: 140px !important;
    padding: 10px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px;
}

@media (max-width: 1023px) {
    .settings-container { 
        &__title {
            font-size: 24px;
        }
    }  
}

@media (max-width: 767px) {
    .settings-container { 
        &__row-container {
            &__status-button-box {
                padding-right: 6px;
            }
            &--verify-button-box-row {
                display: none;
            }
        }
        &__row-container-password {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: auto;

            &__cancel-button-box {
                width: 100%;
            }
        }
    }  
} 

@media (min-width: 768px) {
    .settings-container { 
        &__row-container {
            &--verify-button-box-column {
                display: none;
            }
        }
    } 
}