:root {
  --main-color: #031e44;
  --yellow-rgb: #e3ab00;
  --yellow-gradient: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
  --button-background-dark: #25274a;
}

.custom-button {
  height: 54px;
  text-transform: none !important;
  border-radius: 100px !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.custom-button:disabled {
  color: #fff !important;
  background: #e6e8eb !important;
}

#root ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#root ::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px gray;
}

#root ::-webkit-scrollbar-thumb {
  background: #2575fc;
  border-radius: 10px;
}

#load {
  width: 600px;
  height: 36px;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  margin-left: -300px;
  position: absolute;
  top: 40%;
  left: 50%;
  overflow: visible;
}

#load div {
  opacity: 0;
  color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  animation: 2s linear infinite move;
  position: absolute;
  transform: rotate(180deg);
}

#load div:nth-child(2) {
  animation-delay: .2s;
}

#load div:nth-child(3) {
  animation-delay: .4s;
}

#load div:nth-child(4) {
  animation-delay: .6s;
}

#load div:nth-child(5) {
  animation-delay: .8s;
}

#load div:nth-child(6) {
  animation-delay: 1s;
}

#load div:nth-child(7) {
  animation-delay: 1.2s;
}

@keyframes move {
  0% {
    opacity: 0;
    left: 0;
  }

  35% {
    opacity: 1;
    left: 41%;
    transform: rotate(0);
  }

  65% {
    opacity: 1;
    left: 59%;
    transform: rotate(0);
  }

  100% {
    opacity: 0;
    left: 100%;
    transform: rotate(-180deg);
  }
}

.box-element {
  background: #0d1365cc;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 32px;
}

.box-header {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.box-subheader {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.banner_container p {
  margin: 0;
}

@keyframes buzz-out-on-hover {
  10% {
    transform: translateX(3px)rotate(2deg);
  }

  20% {
    transform: translateX(-3px)rotate(-2deg);
  }

  30% {
    transform: translateX(3px)rotate(2deg);
  }

  40% {
    transform: translateX(-3px)rotate(-2deg);
  }

  50% {
    transform: translateX(2px)rotate(1deg);
  }

  60% {
    transform: translateX(-2px)rotate(-1deg);
  }

  70% {
    transform: translateX(2px)rotate(1deg);
  }

  80% {
    transform: translateX(-2px)rotate(-1deg);
  }

  90% {
    transform: translateX(1px)rotate(0);
  }

  100% {
    transform: translateX(-1px)rotate(0);
  }
}

.buzz-out-on-hover {
  vertical-align: middle;
  display: inline-block;
  transform: perspective(1px)translateZ(0);
  box-shadow: 0 0 1px #0000;
}

.buzz-out-on-hover:hover, .buzz-out-on-hover:focus, .buzz-out-on-hover:active {
  animation-name: buzz-out-on-hover;
  animation-duration: .75s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes moveUpAndDown {
  0% {
    transform: translateY(200px);
  }

  50% {
    transform: rotateY(180deg)translate(50px, -100px);
  }

  100% {
    transform: translateY(200px);
  }
}

@keyframes downUpAndUp {
  0% {
    transform: translateY(-100px);
  }

  50% {
    transform: rotateY(-180deg)translate(50px, 200px);
  }

  100% {
    transform: translateY(-100px);
  }
}

.glitch {
  color: attr(data-glitch-color);
  letter-spacing: 5px;
  z-index: 1;
  line-height: 1.2;
  position: relative;
}

.glitch:before {
  content: attr(data-glitch);
  width: 100%;
  color: attr(data-glitch-color);
  clip: rect(0, 900px, 0, 0);
  animation: 3s linear infinite alternate-reverse noise-before;
  position: absolute;
  top: 0;
  left: -2px;
  overflow: hidden;
}

.glitch:after {
  content: attr(data-glitch);
  width: 100%;
  color: attr(data-glitch-color);
  clip: rect(0, 900px, 0, 0);
  animation: 2s linear infinite alternate-reverse noise-after;
  position: absolute;
  top: 0;
  left: 2px;
}

@keyframes noise-before {
  0% {
    clip: rect(61px, 9999px, 52px, 0);
  }

  5% {
    clip: rect(33px, 9999px, 144px, 0);
  }

  10% {
    clip: rect(121px, 9999px, 115px, 0);
  }

  15% {
    clip: rect(144px, 9999px, 162px, 0);
  }

  20% {
    clip: rect(62px, 9999px, 180px, 0);
  }

  25% {
    clip: rect(34px, 9999px, 42px, 0);
  }

  30% {
    clip: rect(147px, 9999px, 179px, 0);
  }

  35% {
    clip: rect(99px, 9999px, 63px, 0);
  }

  40% {
    clip: rect(188px, 9999px, 122px, 0);
  }

  45% {
    clip: rect(154px, 9999px, 14px, 0);
  }

  50% {
    clip: rect(63px, 9999px, 37px, 0);
  }

  55% {
    clip: rect(161px, 9999px, 147px, 0);
  }

  60% {
    clip: rect(109px, 9999px, 175px, 0);
  }

  65% {
    clip: rect(157px, 9999px, 88px, 0);
  }

  70% {
    clip: rect(173px, 9999px, 131px, 0);
  }

  75% {
    clip: rect(62px, 9999px, 70px, 0);
  }

  80% {
    clip: rect(24px, 9999px, 153px, 0);
  }

  85% {
    clip: rect(138px, 9999px, 40px, 0);
  }

  90% {
    clip: rect(79px, 9999px, 136px, 0);
  }

  95% {
    clip: rect(25px, 9999px, 34px, 0);
  }

  100% {
    clip: rect(173px, 9999px, 166px, 0);
  }
}

@keyframes noise-after {
  0% {
    clip: rect(26px, 9999px, 33px, 0);
  }

  5% {
    clip: rect(140px, 9999px, 198px, 0);
  }

  10% {
    clip: rect(184px, 9999px, 89px, 0);
  }

  15% {
    clip: rect(121px, 9999px, 6px, 0);
  }

  20% {
    clip: rect(181px, 9999px, 99px, 0);
  }

  25% {
    clip: rect(154px, 9999px, 133px, 0);
  }

  30% {
    clip: rect(134px, 9999px, 169px, 0);
  }

  35% {
    clip: rect(26px, 9999px, 187px, 0);
  }

  40% {
    clip: rect(147px, 9999px, 137px, 0);
  }

  45% {
    clip: rect(31px, 9999px, 52px, 0);
  }

  50% {
    clip: rect(191px, 9999px, 109px, 0);
  }

  55% {
    clip: rect(74px, 9999px, 54px, 0);
  }

  60% {
    clip: rect(145px, 9999px, 75px, 0);
  }

  65% {
    clip: rect(153px, 9999px, 198px, 0);
  }

  70% {
    clip: rect(99px, 9999px, 136px, 0);
  }

  75% {
    clip: rect(118px, 9999px, 192px, 0);
  }

  80% {
    clip: rect(1px, 9999px, 83px, 0);
  }

  85% {
    clip: rect(145px, 9999px, 98px, 0);
  }

  90% {
    clip: rect(121px, 9999px, 154px, 0);
  }

  95% {
    clip: rect(156px, 9999px, 44px, 0);
  }

  100% {
    clip: rect(67px, 9999px, 122px, 0);
  }
}

.nav-page {
  text-align: center;
  margin-bottom: 130px;
  padding-top: 112px;
}

.bubble {
  width: 100px;
  height: 100px;
  opacity: .2;
  z-index: 10;
  animation: 15s ease-in-out infinite bubble, 4s ease-in-out infinite alternate sideWays;
  position: absolute;
  bottom: 0;
}

@keyframes bubble {
  0% {
    opacity: .06;
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100vh);
  }
}

@keyframes sideWays {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 10%;
  }
}

.bubble--1 {
  opacity: .3;
  animation-duration: 16s;
  animation-delay: .5s;
  left: 10%;
}

.bubble--2 {
  width: 15px;
  height: 15px;
  opacity: .1;
  animation-duration: 10s;
  animation-delay: 1s;
  left: 40%;
}

.bubble--3 {
  width: 10px;
  height: 10px;
  opacity: .3;
  animation-duration: 20s;
  animation-delay: 5s;
  left: 30%;
}

.bubble--4 {
  width: 25px;
  height: 25px;
  opacity: .2;
  animation-duration: 17s;
  animation-delay: 8s;
  left: 40%;
}

.bubble--5 {
  width: 30px;
  height: 30px;
  opacity: .1;
  animation-duration: 15s;
  animation-delay: 10s;
  left: 60%;
}

.bubble--6 {
  width: 10px;
  height: 10px;
  opacity: .4;
  animation-duration: 30s;
  animation-delay: 3s;
  left: 80%;
}

.bubble--7 {
  width: 15px;
  height: 15px;
  opacity: .3;
  animation-duration: 25s;
  animation-delay: -7s;
  left: 83%;
}

.bubble--9 {
  width: 20px;
  height: 20px;
  opacity: .2;
  animation-duration: 19s;
  animation-delay: -5s;
  bottom: 30px;
  left: 50%;
}

.bubble--10 {
  width: 40px;
  height: 40px;
  opacity: .3;
  animation-duration: 16s;
  animation-delay: -21s;
  bottom: 30px;
  left: 30%;
}

.bubble--11 {
  width: 30px;
  height: 30px;
  opacity: .3;
  width: 25px;
  height: 25px;
  opacity: .3;
  animation-duration: 19s;
  animation-delay: -10.5s;
  bottom: 30px;
  left: 83%;
}

.primary-button {
  background: var(--yellow-gradient);
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  height: 46px !important;
  color: var(--main-color) !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

.primary-button:hover {
  background: #fff !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

@keyframes blink {
  0% {
    border-color: #2cdb78;
  }

  50% {
    border-color: #fff;
  }

  100% {
    border-color: #2cdb78;
  }
}

.border-blink {
  border: 2px solid #2cdb78;
  animation: 2s linear infinite blink;
}

.new_label {
  z-index: 3;
  color: var(--main-color);
  background: var(--yellow-gradient);
  border-radius: 0 50px 50px 0;
  padding: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  position: absolute;
  top: 8px;
  left: 0;
}

.single-line-container {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-flow: row;
  display: flex;
  overflow-x: auto;
}

.single-line-container::-webkit-scrollbar {
  display: none;
}

.multiline-container {
  grid-gap: 10px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  display: grid;
}

.multiline-container__game {
  display: flex;
}

.multiline-container__game:first-child {
  grid-column: span 2;
}

.multiline-container__game:first-child:before {
  padding-bottom: 66.6667%;
}

.multiline-container__game:before {
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 150%;
  display: block;
}

.multiline-container__game--single-row {
  width: 240px;
  height: 360px;
  flex-shrink: 0;
}

.multiline-container--900 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.multiline-container--900 .multiline-container__game:first-child {
  grid-column: span 3;
}

.multiline-container--710 {
  grid-template-columns: repeat(auto-fit, minmax(153px, 1fr));
}

.multiline-container--710 .multiline-container__game:first-child {
  grid-column: span 3;
}

.multiline-container--570 {
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
}

.multiline-container--570 .multiline-container__game:first-child {
  grid-column: span 3;
}

@media (max-width: 900px) {
  .multiline-container__game--single-row {
    width: 144px;
    height: 216px;
  }
}

@media (max-width: 457px) {
  .multiline-container {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .multiline-container__game:first-child {
    grid-column: span 3;
  }
}

@media (max-width: 388px) {
  .multiline-container {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}

.earn-more-banner-container {
  width: 640px;
  min-height: 420px;
  height: auto;
  border: 1px solid var(--Border-Blue-Hover, #2470f0);
  justify-content: space-between;
  align-items: flex-start;
  gap: auto;
  box-sizing: border-box;
  will-change: transform, opacity;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 32px;
  flex-direction: column;
  flex-shrink: 0;
  padding: 50px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.earn-more-banner-container__child {
  width: 300px;
  min-height: 100px;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.earn-more-banner-container__banner {
  width: 300px;
  height: 200px;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.earn-more-banner-container__banner__title {
  color: var(--white, #fff);
  max-width: 300px;
  text-transform: capitalize;
  align-self: stretch;
  font-family: Poppins;
  font-size: 39px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}

.earn-more-banner-container__banner__body {
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.earn-more-banner-container__banner__button {
  text-wrap: nowrap;
  padding: 16px 32px;
  width: 272px !important;
  min-height: 54px !important;
  text-transform: capitalize !important;
  border-radius: 100px !important;
  font-family: Poppins !important;
  font-size: 19px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.earn-more-banner-container:after {
  content: "";
  width: 325px;
  min-height: 420px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 32px;
  position: absolute;
  top: 0;
  left: 50%;
}

.earn-more-banner-container--LuckyWheel:after {
  background-image: url("wheel.aefe87d0.png");
}

.earn-more-banner-container--Promotion:after {
  background-image: url("promotion.00d123ae.png");
}

.earn-more-banner-container--Shop:after {
  background-image: url("shop.12509e64.png");
}

.earn-more-banner-container--Challenges:after {
  background-image: url("challenge.49eeda95.png");
}

.earn-more-banner-container--Tournaments:after {
  background-image: url("tournament.738cea2f.png");
}

.earn-more-banner-container--LuckyWheel {
  background-image: url("wheel_background.eec89901.png");
}

.earn-more-banner-container--Promotion {
  background-image: url("promotion_background.52549326.png");
}

.earn-more-banner-container--Shop {
  background-image: url("shop_background.c7b10c38.png");
}

.earn-more-banner-container--Challenges {
  background-image: url("challenge_background.f347ce82.png");
}

.earn-more-banner-container--Tournaments {
  background-image: url("tournament_background.e64740fd.png");
}

@media screen and (max-width: 575px) {
  .earn-more-banner-container {
    width: 345px;
    min-height: 310px;
    padding: 30px;
  }

  .earn-more-banner-container__banner {
    width: 185px;
    gap: 10px;
  }

  .earn-more-banner-container__banner__title {
    font-size: 24px;
  }

  .earn-more-banner-container__banner__body {
    font-size: 14px;
  }

  .earn-more-banner-container__banner__button {
    min-height: 42px !important;
    width: auto !important;
    max-width: 216px !important;
    min-width: 139px !important;
    margin-top: 10px !important;
    font-size: 16px !important;
  }

  .earn-more-banner-container:after {
    width: 160px;
    min-height: 310px;
  }

  .earn-more-banner-container--LuckyWheel:after, .earn-more-banner-container--Promotion:after, .earn-more-banner-container--Shop:after, .earn-more-banner-container--Challenges:after, .earn-more-banner-container--Tournaments:after {
    width: 100%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .earn-more-banner-container {
    width: 546px;
    min-height: 340px;
    padding: 30px;
  }

  .earn-more-banner-container__banner__title {
    font-size: 31px;
  }

  .earn-more-banner-container__banner__button {
    height: 42px !important;
    font-size: 16px !important;
  }

  .earn-more-banner-container:after {
    min-height: 340px;
  }

  .earn-more-banner-container--LuckyWheel:after, .earn-more-banner-container--Promotion:after, .earn-more-banner-container--Shop:after, .earn-more-banner-container--Challenges:after, .earn-more-banner-container--Tournaments:after {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .earn-more-banner-container {
    width: 560px;
    min-height: 340px;
    padding: 30px;
  }

  .earn-more-banner-container__banner__title {
    font-size: 31px;
  }

  .earn-more-banner-container__banner__button {
    height: 42px !important;
    font-size: 16px !important;
  }

  .earn-more-banner-container:after {
    min-height: 340px;
  }

  .earn-more-banner-container--LuckyWheel:after, .earn-more-banner-container--Promotion:after, .earn-more-banner-container--Shop:after, .earn-more-banner-container--Challenges:after, .earn-more-banner-container--Tournaments:after {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1399px) {
  .earn-more-banner-container {
    width: 540px;
    min-height: 340px;
    padding: 30px;
  }

  .earn-more-banner-container__banner__title {
    font-size: 31px;
  }

  .earn-more-banner-container__banner__button {
    height: 42px !important;
    font-size: 16px !important;
  }

  .earn-more-banner-container:after {
    min-height: 340px;
  }

  .earn-more-banner-container--LuckyWheel:after, .earn-more-banner-container--Promotion:after, .earn-more-banner-container--Shop:after, .earn-more-banner-container--Challenges:after, .earn-more-banner-container--Tournaments:after {
    width: 100%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1920px) {
  .earn-more-banner-container {
    width: 580px;
  }

  .earn-more-banner-container:after {
    width: 290px;
  }

  .earn-more-banner-container--LuckyWheel:after, .earn-more-banner-container--Promotion:after, .earn-more-banner-container--Shop:after, .earn-more-banner-container--Challenges:after, .earn-more-banner-container--Tournaments:after {
    width: 100%;
  }
}

.shop-balance-box-earn-more-banner-container {
  border: 1px solid #f7c903;
  border-radius: 16px;
  justify-content: space-between;
  display: flex;
  height: 60px !important;
  padding: 5px 15px !important;
}

.shop-page-container {
  flex-direction: column;
  gap: 180px;
  margin-bottom: 90px;
  display: flex;
}

.shop-page {
  flex-direction: column;
  gap: 40px;
  padding: 140px 20% 0;
  font-family: Poppins;
  display: flex;
}

.shop-page__button-tabs {
  display: none;
}

.shop-page__row {
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  display: flex;
}

.shop-page__row-item--wide {
  flex-basis: 70%;
}

.shop-page__row-item--narrow {
  flex-basis: 30%;
}

.shop-page__title {
  flex-direction: column;
  flex: 6;
  display: flex;
}

.shop-page__title-text {
  font-size: 39px;
  font-weight: 700;
}

.shop-page__balance-box {
  flex: 4;
}

.custom-text-icon-button {
  width: max-content;
  text-align: left;
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

@media (max-width: 1600px) {
  .shop-page {
    padding: 140px 10% 0;
  }
}

@media (max-width: 1400px) {
  .shop-page {
    padding: 140px 6% 0;
  }
}

@media (max-width: 1200px) {
  .shop-page {
    padding: 140px 70px 0;
  }
}

@media (max-width: 1023px) {
  .shop-page {
    padding: 140px 70px 0;
  }

  .shop-page__row {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 899px) {
  .shop-page {
    padding: 140px 70px 0;
  }
}

@media (max-width: 700px) {
  .shop-page {
    padding: 140px 20px 0;
  }
}

@media (max-width: 767px) {
  .shop-page__button-tabs {
    margin-top: 32px;
    display: flex;
  }

  .shop-page__button-tabs .group-container {
    flex: 1;
    margin-top: -24px;
    display: flex;
  }

  .shop-page__button-tabs .group-container .group-button, .shop-page__button-tabs .group-container .group-button--select {
    flex: 1;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px !important;
  }
}

@media (max-width: 700px) {
  .shop-balance-box-earn-more-banner-container {
    width: 140px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1160px) {
  .shop-balance-box-earn-more-banner-container {
    width: 170px;
  }
}

@media (max-width: 767px) {
  .shop-page-container {
    gap: 132px;
  }
}

.banner-child-text-box-container {
  border: 1px solid var(--Border-Blue-Hover, #2470f0);
  color: var(--dark-blue, #031e44);
  background: var(--Yellow, #f7c903);
  border-radius: 3px;
  padding: 1px 5px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.badge {
  align-items: center;
  gap: 8px;
  display: flex;
}

.badge #badge-name {
  color: #f7c903;
  font-size: 17px;
  font-weight: 700;
}

.badge--with-background {
  gap: 0;
}

.badge--with-background #badge-img {
  z-index: 1;
  border-radius: 60px;
  box-shadow: 0 0 10px #ffd41b80;
}

.badge--with-background #badge-name {
  text-transform: uppercase;
  color: #0d1365;
  background: var(--yellow-gradient);
  border-radius: 0 50px 50px 0;
  padding: 8px 15px 8px 20px;
  font-size: 12px;
  transform: translate(-15px);
  box-shadow: 0 0 10px #ffd41b80;
}

@media (max-width: 899px) {
  .badge--with-background #badge-img {
    width: 40px !important;
  }

  .badge #badge-img {
    width: 28px;
  }

  .badge #badge-name {
    font-size: 12px;
  }
}

.balance-box {
  border: 1px solid #f7c903;
  border-radius: 16px;
  justify-content: space-between;
  padding: 15px;
  display: flex;
}

.balance-box__title {
  text-align: right;
  flex-direction: column;
  display: flex;
}

.balance-box__title-text {
  font-size: 14px;
  font-weight: 400;
}

.balance-box__title-currency {
  justify-content: end;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  display: flex;
}

.balance-box__title-currency-earn-more {
  justify-content: start;
  align-items: center;
  gap: 2px;
  font-size: 19px;
  font-weight: 700;
  display: flex;
}

.balance-box__title-text-earn-more {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

@media (max-width: 700px) {
  .balance-box__title-text-earn-more {
    text-wrap: nowrap;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}

.banner-timer-container {
  flex-flow: column wrap;
  gap: 5px;
  display: flex;
}

.banner-timer-container__text {
  color: #fff;
  text-transform: capitalize;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.banner-timer-container__time-box-container {
  gap: 15px;
  display: flex;
}

.tournament-card__clock-box {
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  gap: 5px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.tournament-card__clock-box--earn-more-banner {
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  gap: 5px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.tournament-card__clock-box--extra-content {
  position: relative;
}

.tournament-card__clock-box--extra-content:after {
  content: ":";
  font-size: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(11px, -90%);
}

.tournament-card__clock-box__value {
  width: 72px;
  height: 60px;
  color: #fff;
  background-color: #25274a;
  border-radius: 14px;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.tournament-card__clock-box--earn-more-banner-value {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: #25274acc;
  border-radius: 14px;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

@media screen and (max-width: 960px) {
  .tournament-card__clock-box {
    flex: 1;
  }

  .tournament-card__clock-box__value {
    width: 100%;
  }
}

.level-information {
  color: #fff;
  min-width: 155px;
  flex-direction: column;
  gap: 10px;
  font-family: Poppins;
  font-size: 14px;
  display: flex;
}

.level-information--max-width {
  width: 100%;
}

.level-information__row {
  justify-content: space-between;
  display: flex;
}

.level-information__row .level-information__title {
  font-weight: 600;
  display: flex;
}

.level-information__row .level-information__link {
  color: #fff;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  display: flex;
}

.level-information__row .level-information__link-icon {
  font-size: 18px;
}

.level-information__row .level-information__bar {
  width: 100%;
  height: 12px;
  background-color: #ffffff1a;
  border-radius: 10px;
  padding: 2px;
}

.level-information__row .level-information__bar-progress {
  height: 100%;
  background: var(--yellow-gradient);
  max-width: 100%;
  border-radius: 10px;
}

.level-information__row .level-information__progress-title {
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.level-information__row .level-information__progress-title-icon {
  color: #ffd000;
  font-size: 20px;
}

.menu-item-container {
  cursor: pointer;
  text-transform: capitalize;
  color: #fff;
  align-items: center;
  gap: 4px;
  padding: 14px 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
}

.menu-item-container--rotate-270 {
  transform: rotate(270deg);
}

.mobile-menu-container {
  width: 300px;
  min-height: 100vh;
  flex-flow: column;
  justify-content: space-between;
  padding: 20px 16px 30px;
  display: flex;
}

.mobile-menu-container__logo-row {
  align-items: center;
  gap: 16px;
  margin-bottom: 15px;
  display: flex;
}

.mobile-menu-container__actions {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.mobile-menu-logo {
  width: 144px;
  height: 30px;
  cursor: pointer;
}

.mobile-menu-burger {
  width: 18px;
  height: 12px;
  cursor: pointer;
}

.my-account-nav {
  border-top: 1px solid #ffffff26;
  margin-top: 10px;
  padding-top: 10px;
}

.change-language-mobile-tooltip {
  width: 268px !important;
  justify-content: space-between !important;
  padding-top: 14px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.change-language-mobile-tooltip__img-display-name-box {
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.arrow-drop-mobile-icon {
  color: #fff !important;
  margin: 0 !important;
}

.flag-img-mobile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  justify-content: left !important;
}

.flag-img-mobile--border-white {
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.arrow-drop-icon-mobile {
  color: #fff !important;
  margin: 0 !important;
}

.languages-menu-item-mobile {
  border-radius: 14px !important;
  justify-content: flex-start !important;
  gap: 8px !important;
  display: flex !important;
}

.languages-menu-item-mobile__display-name, .languages-menu-item-mobile__tooltip-display-name {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.language-menu-mobile {
  width: 270px !important;
  background: #ffffff1a !important;
  border-radius: 14px !important;
}

.profile-menu {
  margin-top: 48px;
}

.profile-menu .MuiPaper-root {
  min-width: 220px;
  border-radius: 16px;
}

.profile-menu .MuiMenu-list {
  padding: 4px 0;
}

.profile-menu .profile-menu__item {
  gap: 20px;
  display: flex;
}

.profile-menu .profile-menu__item section {
  align-items: center;
  display: flex;
}

.profile-menu .profile-menu__item section .profile-menu__item-icon {
  color: #0d1365 !important;
}

.profile-menu .profile-menu__item section .profile-menu__item-icon .profile-menu__item-icon__item {
  width: 24px !important;
  height: 24px !important;
}

.profile-menu .profile-menu__item section .profile-menu__item-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.wallet-menu-item {
  justify-content: space-between;
  padding: 10px 15px;
  display: flex;
}

.wallet-menu-item__info {
  flex-direction: column;
  display: flex;
}

.wallet-menu-item__info__title {
  color: var(--Text-Semi-Black, #21212180);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.wallet-menu-item__info__value {
  align-items: center;
  gap: 5px;
  display: flex;
}

.wallet-menu-item__info__value__text {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.wallet-menu-item__button {
  width: 40px;
  cursor: pointer;
  background: #031e4459;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

.wallet-menu-item__button__icon {
  width: 100%;
  height: 100%;
  color: #fff !important;
}

.wallet-menu {
  margin-top: 48px;
}

.wallet-menu .MuiPaper-root {
  min-width: 220px;
  border-radius: 14px;
}

.wallet-menu__deposit {
  padding: 15px;
}

.flag-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.flag-img--border-blue {
  width: 24px;
  height: 24px;
  border: 2px solid #00f;
  border-radius: 50%;
}

.flag-img--border-white {
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.language-menu {
  margin-top: 48px;
  border-radius: 14px !important;
}

.languages-menu-item {
  border-radius: 14px !important;
  justify-content: flex-end !important;
  gap: 10px !important;
  display: flex !important;
}

.languages-menu-item__display-name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.change-language-tooltip {
  width: 57px !important;
  background: #ffffff26 !important;
  border-radius: 24px !important;
  padding: 8px 16px 8px 10px !important;
}

.arrow-drop-icon {
  color: #fff !important;
  margin: 0 !important;
}

.button__wallet {
  color: #fff !important;
  height: 40px !important;
  border: 2px solid #e3ab00 !important;
  margin-right: 16px !important;
  padding: 0 15px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.button__signup {
  color: var(--main-color);
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
  height: 40px !important;
  margin-right: 16px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.button__signup:hover {
  background: #fff !important;
  border: none !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

.button__signin {
  color: #e3ab00 !important;
  height: 40px !important;
  border: 2px solid !important;
  margin-right: 16px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.button__signin:hover {
  color: var(--info-main) !important;
}

.profile-menu__badge > span {
  top: 5px;
  right: 35px;
}

.profile-menu__badge--mobile {
  top: -15px;
  right: 0;
}

.banner__grid-container {
  display: flex;
}

.banner__grid {
  background-position: center;
  background-size: cover;
  align-items: center;
}

.banner__grid:before {
  content: "";
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 92.08%, 2% 90.88%, 4% 89.68%, 6% 88.49%, 8% 87.33%, 10% 86.21%, 12% 85.17%, 14% 84.2%, 16% 83.34%, 18% 82.58%, 20% 81.95%, 22% 81.46%, 24% 81.1%, 26% 80.89%, 28% 80.83%, 30% 80.92%, 32% 81.17%, 34% 81.55%, 36% 82.08%, 38% 82.74%, 40% 83.52%, 42% 84.41%, 44% 85.39%, 46% 86.46%, 48% 87.58%, 50% 88.75%, 52% 89.95%, 54% 91.15%, 56% 92.34%, 58% 93.5%, 60% 94.62%, 62% 95.66%, 64% 96.63%, 66% 97.49%, 68% 98.25%, 70% 98.88%, 72% 99.38%, 74% 99.73%, 76% 99.94%, 78% 100%, 80% 99.91%, 82% 99.66%, 84% 99.28%, 86% 98.75%, 88% 98.09%, 90% 97.31%, 92% 96.42%, 94% 95.44%, 96% 94.37%, 98% 93.25%, 100% 92.08%);
  clip-path: polygon(100% 100%, 0% 100%, 0% 92.08%, 2% 90.88%, 4% 89.68%, 6% 88.49%, 8% 87.33%, 10% 86.21%, 12% 85.17%, 14% 84.2%, 16% 83.34%, 18% 82.58%, 20% 81.95%, 22% 81.46%, 24% 81.1%, 26% 80.89%, 28% 80.83%, 30% 80.92%, 32% 81.17%, 34% 81.55%, 36% 82.08%, 38% 82.74%, 40% 83.52%, 42% 84.41%, 44% 85.39%, 46% 86.46%, 48% 87.58%, 50% 88.75%, 52% 89.95%, 54% 91.15%, 56% 92.34%, 58% 93.5%, 60% 94.62%, 62% 95.66%, 64% 96.63%, 66% 97.49%, 68% 98.25%, 70% 98.88%, 72% 99.38%, 74% 99.73%, 76% 99.94%, 78% 100%, 80% 99.91%, 82% 99.66%, 84% 99.28%, 86% 98.75%, 88% 98.09%, 90% 97.31%, 92% 96.42%, 94% 95.44%, 96% 94.37%, 98% 93.25%, 100% 92.08%);
  background: #0d0f36;
  position: absolute;
  top: 0;
  left: 0;
}

.banner__grid-item--right {
  height: 100%;
}

.banner__grid-item--right .spine-container {
  width: 100%;
  height: 100%;
}

.banner__grid-item-image {
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  position: relative;
}

.banner-text {
  flex-direction: column;
  padding-left: 15%;
  display: flex;
}

.banner-text--align-left {
  align-items: left;
}

.banner-text--align-center {
  align-items: center;
}

.banner-text__line {
  white-space: nowrap;
  z-index: 3;
  justify-content: flex-start;
  line-height: 125%;
  display: flex;
}

.banner-text__line--font-size-small {
  font-size: 2em;
}

.banner-text__line--font-size-medium {
  font-size: 4em;
}

.banner-text__line--font-size-large {
  font-size: 6em;
}

.banner-text__button-container {
  justify-content: flex-start;
  padding-top: 13px;
  display: flex;
}

.banner-text__button-container .banner-text__button {
  height: 54px;
  width: 184px;
  color: #031e44;
  background: var(--yellow-gradient);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.banner-text__button-container .banner-text__button:hover {
  background: #fff;
  border: none;
  box-shadow: 0 0 10px #ffd41b80;
}

@media screen and (max-width: 1199px) {
  .banner__grid:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
  }

  .banner-text__line--font-size-small {
    font-size: 1.75em;
  }

  .banner-text__line--font-size-medium {
    font-size: 2.5em;
  }

  .banner-text__line--font-size-large {
    font-size: 5em;
  }
}

@media screen and (max-width: 767px) {
  .banner__grid:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
  }

  .banner__grid-item {
    justify-items: center;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .banner__grid-item--flex {
    height: 360px;
    justify-content: center;
    justify-items: center;
    display: flex;
  }

  .banner__grid-item--flex img {
    max-width: none;
    max-height: none;
  }

  .banner-text {
    padding-top: 80px;
    padding-left: 0;
  }

  .banner-text__line, .banner-text__button-container {
    justify-content: center;
  }

  .banner-text__line--font-size-small {
    font-size: 1.5em;
  }

  .banner-text__line--font-size-medium {
    font-size: 2em;
  }

  .banner-text__line--font-size-large {
    font-size: 4em;
  }

  .banner-text__button-container .banner-text__button {
    width: 286px;
  }
}

@media screen and (max-width: 576px) {
  .banner__grid:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 76.81%, 6.67% 77.2%, 13.33% 78.05%, 20% 79.28%, 26.67% 80.82%, 33.33% 82.58%, 40% 84.45%, 46.67% 86.31%, 53.33% 88.04%, 60% 89.53%, 66.67% 90.69%, 73.33% 91.45%, 80% 91.75%, 86.67% 91.59%, 93.33% 90.97%, 100% 89.92%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 76.81%, 6.67% 77.2%, 13.33% 78.05%, 20% 79.28%, 26.67% 80.82%, 33.33% 82.58%, 40% 84.45%, 46.67% 86.31%, 53.33% 88.04%, 60% 89.53%, 66.67% 90.69%, 73.33% 91.45%, 80% 91.75%, 86.67% 91.59%, 93.33% 90.97%, 100% 89.92%);
  }
}

@media screen and (max-width: 476px) {
  .banner-text__button-container .banner-text__button {
    width: 90%;
  }
}

.banner__grid-container {
  display: flex;
}

.banner__grid {
  background-position: center;
  background-size: cover;
  align-items: center;
}

.banner__grid:before {
  content: "";
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 92.08%, 2% 90.88%, 4% 89.68%, 6% 88.49%, 8% 87.33%, 10% 86.21%, 12% 85.17%, 14% 84.2%, 16% 83.34%, 18% 82.58%, 20% 81.95%, 22% 81.46%, 24% 81.1%, 26% 80.89%, 28% 80.83%, 30% 80.92%, 32% 81.17%, 34% 81.55%, 36% 82.08%, 38% 82.74%, 40% 83.52%, 42% 84.41%, 44% 85.39%, 46% 86.46%, 48% 87.58%, 50% 88.75%, 52% 89.95%, 54% 91.15%, 56% 92.34%, 58% 93.5%, 60% 94.62%, 62% 95.66%, 64% 96.63%, 66% 97.49%, 68% 98.25%, 70% 98.88%, 72% 99.38%, 74% 99.73%, 76% 99.94%, 78% 100%, 80% 99.91%, 82% 99.66%, 84% 99.28%, 86% 98.75%, 88% 98.09%, 90% 97.31%, 92% 96.42%, 94% 95.44%, 96% 94.37%, 98% 93.25%, 100% 92.08%);
  clip-path: polygon(100% 100%, 0% 100%, 0% 92.08%, 2% 90.88%, 4% 89.68%, 6% 88.49%, 8% 87.33%, 10% 86.21%, 12% 85.17%, 14% 84.2%, 16% 83.34%, 18% 82.58%, 20% 81.95%, 22% 81.46%, 24% 81.1%, 26% 80.89%, 28% 80.83%, 30% 80.92%, 32% 81.17%, 34% 81.55%, 36% 82.08%, 38% 82.74%, 40% 83.52%, 42% 84.41%, 44% 85.39%, 46% 86.46%, 48% 87.58%, 50% 88.75%, 52% 89.95%, 54% 91.15%, 56% 92.34%, 58% 93.5%, 60% 94.62%, 62% 95.66%, 64% 96.63%, 66% 97.49%, 68% 98.25%, 70% 98.88%, 72% 99.38%, 74% 99.73%, 76% 99.94%, 78% 100%, 80% 99.91%, 82% 99.66%, 84% 99.28%, 86% 98.75%, 88% 98.09%, 90% 97.31%, 92% 96.42%, 94% 95.44%, 96% 94.37%, 98% 93.25%, 100% 92.08%);
  background: #0d0f36;
  position: absolute;
  top: 0;
  left: 0;
}

.banner__grid-item-image {
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  position: relative;
}

.banner-text {
  flex-direction: column;
  padding-left: 15%;
  display: flex;
}

.banner-text--align-left {
  align-items: left;
}

.banner-text--align-center {
  align-items: center;
}

.banner-text__line {
  white-space: nowrap;
  z-index: 3;
  justify-content: flex-start;
  line-height: 125%;
  display: flex;
}

.banner-text__line--font-size-small {
  font-size: 2em;
}

.banner-text__line--font-size-medium {
  font-size: 4em;
}

.banner-text__line--font-size-large {
  font-size: 6em;
}

.banner-text__button-container {
  justify-content: flex-start;
  padding-top: 13px;
  display: flex;
}

.banner-text__button-container .banner-text__button {
  height: 54px;
  width: 184px;
  color: #031e44;
  background: var(--yellow-gradient);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.banner-text__button-container .banner-text__button:hover {
  background: #fff;
  border: none;
  box-shadow: 0 0 10px #ffd41b80;
}

@media screen and (max-width: 1199px) {
  .banner__grid:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
  }

  .banner-text__line--font-size-small {
    font-size: 1.75em;
  }

  .banner-text__line--font-size-medium {
    font-size: 2.5em;
  }

  .banner-text__line--font-size-large {
    font-size: 5em;
  }
}

@media screen and (max-width: 767px) {
  .banner__grid:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
  }

  .banner__grid-item {
    justify-items: center;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .banner__grid-item--flex {
    height: 360px;
    justify-content: center;
    justify-items: center;
    display: flex;
  }

  .banner__grid-item--flex img {
    max-width: none;
    max-height: none;
  }

  .banner-text {
    padding-top: 80px;
    padding-left: 0;
  }

  .banner-text__line, .banner-text__button-container {
    justify-content: center;
  }

  .banner-text__line--font-size-small {
    font-size: 1.5em;
  }

  .banner-text__line--font-size-medium {
    font-size: 2em;
  }

  .banner-text__line--font-size-large {
    font-size: 4em;
  }

  .banner-text__button-container .banner-text__button {
    width: 286px;
  }
}

@media screen and (max-width: 576px) {
  .banner__grid:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 76.81%, 6.67% 77.2%, 13.33% 78.05%, 20% 79.28%, 26.67% 80.82%, 33.33% 82.58%, 40% 84.45%, 46.67% 86.31%, 53.33% 88.04%, 60% 89.53%, 66.67% 90.69%, 73.33% 91.45%, 80% 91.75%, 86.67% 91.59%, 93.33% 90.97%, 100% 89.92%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 76.81%, 6.67% 77.2%, 13.33% 78.05%, 20% 79.28%, 26.67% 80.82%, 33.33% 82.58%, 40% 84.45%, 46.67% 86.31%, 53.33% 88.04%, 60% 89.53%, 66.67% 90.69%, 73.33% 91.45%, 80% 91.75%, 86.67% 91.59%, 93.33% 90.97%, 100% 89.92%);
  }
}

@media screen and (max-width: 476px) {
  .banner-text__button-container .banner-text__button {
    width: 90%;
  }
}

.main {
  height: 100vh;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  display: grid;
}

.main__content-box {
  flex-flow: column;
  grid-column: span 12;
  display: flex;
}

@media (min-width: 960px) {
  .main__content-box {
    grid-column: span 8;
  }
}

.main__content-box__inner-content {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.main__content-box__inner-content__container {
  max-width: 616px;
  width: 100%;
}

.main__content-box__inner-content__container__email-inputs-container {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

@media (min-width: 960px) {
  .main__content-box__inner-content__container__email-inputs-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.main__content-box__inner-content__container__button-container {
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (min-width: 960px) {
  .main__content-box__inner-content__container__button-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.main__content-box__inner-content__container__button-container__custom-button {
  color: var(--main-color) !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

.main__content-box__inner-content__container__button-container__custom-button:hover {
  background: #fff !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

.main__content-box__inner-content__container__check-box-container {
  width: 310px;
  margin-top: 1rem;
  margin-left: 3rem;
  margin-right: 2rem;
}

@media (min-width: 960px) {
  .main__content-box__inner-content__container__check-box-container {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

.main__already-account {
  text-align: center;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

@media (min-width: 960px) {
  .main__already-account {
    margin-bottom: 32px;
  }
}

.main__already-account__sign-in-link {
  cursor: pointer !important;
  text-decoration: none !important;
}

.main__already-account__sign-in-link--disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  color: gray !important;
  text-decoration: none !important;
}

.main__background-box {
  background-image: url("login.8e5384dc.svg");
  background-repeat: no-repeat;
  background-color: var(--main-color);
  background-position: center;
  background-size: cover;
  display: none;
}

@media (min-width: 960px) {
  .main__background-box {
    grid-column: span 4;
    display: block;
  }
}

.input-label {
  height: 22px !important;
  padding: 12px 0 !important;
}

.input-label:-webkit-autofill {
  -webkit-text-fill-color: #2575fc;
}

.form-control-label {
  margin-top: 5px;
}

@media (min-width: 960px) {
  .form-control-label {
    margin-top: 15px;
  }
}

.checkbox-label {
  color: #838a99;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
}

@media (min-width: 960px) {
  .checkbox-label {
    line-height: 22px !important;
  }
}

.currency-input-adornment {
  padding-left: 13px;
}

.sms-switch-container {
  margin-top: 10px;
  margin-left: 10px;
}

.custom-icon-button {
  width: 40px !important;
  height: 40px !important;
}

.custom-icon-button:disabled {
  mix-blend-mode: luminosity;
}

.custom-icon-button--small {
  width: 24px !important;
  height: 24px !important;
}

.custom-icon-button--gold {
  background: var(--yellow-gradient) !important;
}

.custom-icon-button--gold > svg {
  color: #25274a;
}

.custom-icon-button--white {
  background: #fff !important;
}

.custom-icon-button--transparent {
  background: none !important;
}

.custom-icon-button--text {
  color: #031e44 !important;
}

.custom-icon-button--text-white {
  color: #fff !important;
}

.custom-icon-button--text-darkblue {
  color: #031e44 !important;
}

.custom-icon-button__badge > span {
  color: #000;
  transform: translate(-125%, 125%);
  background: var(--yellow-gradient) !important;
}

.otp-dialog__title-icon-container {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.otp-dialog__title-icon-container__title-box {
  color: #f7c903;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.otp-dialog__title-icon-container__close-icon-box {
  color: #f7c903;
  justify-content: flex-end;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.otp-dialog__subtitle-box {
  text-align: center;
  color: #f7c903;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.otp-dialog__form-row-container {
  width: 100%;
  justify-self: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.otp-dialog__form-submit-button-container {
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
  display: flex;
}

.otp-dialog__form-resend-button-container {
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: flex;
}

.mui-dialog-otp {
  width: 370px !important;
  height: 400px !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  background: #25274ae6 !important;
  border-radius: 32px !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 20px !important;
  margin: 0 !important;
  padding: 30px !important;
}

.logo-container {
  margin-top: 16px;
  margin-left: 16px;
  font-family: Poppins;
}

@media (min-width: 960px) {
  .logo-container {
    margin-top: 45px;
    margin-left: 45px;
  }
}

.logo-container img {
  cursor: pointer;
  width: 184px;
}

.title-container {
  color: var(--main-color);
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

@media (min-width: 960px) {
  .title-container {
    margin-left: 4rem;
    margin-right: 4rem;
    font-size: 40px;
  }
}

.subtitle-container {
  color: var(--main-color);
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

@media (min-width: 960px) {
  .subtitle-container {
    margin-left: 4rem;
    margin-right: 4rem;
    font-size: 18px;
  }
}

.group-container {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

@media (min-width: 960px) {
  .group-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.group-container__button-group {
  height: 40px;
  color: #fff !important;
  background: #2575fc !important;
  border-radius: 60px !important;
}

.group-container__button-group__option {
  font-weight: 600;
  color: #fff !important;
  height: 100% !important;
  min-width: 100px !important;
  border-radius: 100px !important;
  font-size: 14px !important;
}

.group-container__button-group__option--active {
  min-width: 100px !important;
  color: #fff !important;
  height: 100% !important;
  background: #25274ae6 !important;
  border: none !important;
  border-radius: 60px !important;
  font-size: 14px !important;
}

.group-container__button-group__option:hover {
  background-color: #25274ae6 !important;
}

@media (max-width: 576px) {
  .group-container__button-group__option {
    padding: 8px !important;
    font-size: 12px !important;
    line-height: 32px !important;
  }
}

.custom-switch {
  align-items: center;
  gap: 10px;
  display: flex;
}

.custom-switch:hover {
  cursor: pointer;
}

.custom-switch__title {
  font-weight: 500;
}

@media (max-width: 576px) {
  .custom-switch__title {
    font-size: 14px;
  }
}

.requirements-container {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.requirements-container__icon-label-box {
  align-items: center;
  gap: 10px;
  display: flex;
}

.requirements-container__icon-label-box__label {
  color: #fff;
  letter-spacing: .28px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.requirements-container__icon-label-box__label--dark {
  color: var(--main-color);
}

.check-circle-icon {
  color: #f7c903;
}

.cancel-icon {
  color: #ffffff4d;
}

.reset-pass-container {
  flex-direction: column;
  align-items: center;
  margin: 4rem 2rem;
  display: flex;
}

@media (min-width: 600px) {
  .reset-pass-container {
    margin: 4rem 2rem;
  }
}

@media (min-width: 960px) {
  .reset-pass-container {
    margin: 8rem 4rem;
  }
}

.reset-pass-container__title {
  color: var(--main-color);
  font-family: Poppins, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

@media (min-width: 960px) {
  .reset-pass-container__title {
    font-size: 40px;
  }
}

.reset-pass-container__button {
  margin-bottom: 2rem;
  box-shadow: 0 4px 4px #00000040;
  color: var(--main-color) !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  margin-top: 3rem !important;
}

.reset-pass-container__button:hover {
  box-shadow: 0 0 10px #ffd41b80;
  background: #fff !important;
}

.amount-box {
  text-align: left;
  color: #fff;
  background: #25274a;
  border-radius: 14px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 13px 0;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.amount-box:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.amount-box--is-selected {
  color: #031e44;
  background-color: #fff;
}

.amount-box--is-selected:hover {
  background-color: #fff;
}

@media (max-width: 375px) {
  .amount-box {
    font-size: 19px;
  }
}

.payment-method {
  width: 180px;
  height: 80px;
  background-color: #25274a;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.payment-method:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.payment-method--is-selected, .payment-method--is-selected:hover {
  background-color: #fff;
}

@media (max-width: 1024px) {
  .payment-method {
    width: 156px;
    height: 80px;
  }
}

@media (max-width: 768px) {
  .payment-method {
    width: 112px;
    height: 56px;
  }
}

@media (max-width: 576px) {
  .payment-method {
    width: 98px;
    height: 56px;
  }
}

.element-row {
  width: 100%;
  height: 80px;
  color: #fff;
  background-color: #25274a;
  border-radius: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  font-family: Poppins;
  display: flex;
}

.element-row:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.element-row--is-selected, .element-row--is-selected:hover {
  background-color: #fff;
}

.element-row--is-selected .element-row__details-logo {
  border: 1px solid #e1e1e8;
}

.element-row--is-selected .element-row__details-text, .element-row--is-selected .element-row__controls {
  color: #031e44;
}

.element-row--is-highlighted {
  border: 1px solid #f7c903;
}

.element-row--is-highlighted .element-row__details-text-main {
  color: #f7c903;
}

.element-row--is-disabled {
  opacity: .6;
}

.element-row__details, .element-row__controls {
  align-items: center;
  gap: 15px;
  display: flex;
  box-shadow: none !important;
}

.element-row__details-logo {
  height: 48px;
  width: 80px;
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.element-row__details-logo--visa {
  width: 36px;
  filter: drop-shadow(0 1000px #152682);
  transform: translateY(-1000px);
}

.element-row__details-logo--mastercard {
  height: 36px;
}

.element-row__details-text {
  text-align: left;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  line-height: 22.4px;
  display: flex;
}

.element-row__details-text-main {
  text-transform: capitalize;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  display: flex;
}

.element-row__details-text-additional {
  font-weight: 400;
}

@media (max-width: 768px) {
  .element-row {
    justify-content: space-between;
    padding: 15px;
  }

  .element-row--with-custom-controls {
    height: auto;
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }

  .element-row--with-custom-controls .element-row__details, .element-row--with-custom-controls .element-row__controls {
    width: 100%;
  }

  .element-row--with-custom-controls .element-row__details .progress-bar, .element-row--with-custom-controls .element-row__details .element-row__details-text, .element-row--with-custom-controls .element-row__details .element-row__controls-custom, .element-row--with-custom-controls .element-row__details .element-row__details-text-main, .element-row--with-custom-controls .element-row__details .element-row__details-text-additional, .element-row--with-custom-controls .element-row__controls .progress-bar, .element-row--with-custom-controls .element-row__controls .element-row__details-text, .element-row--with-custom-controls .element-row__controls .element-row__controls-custom, .element-row--with-custom-controls .element-row__controls .element-row__details-text-main, .element-row--with-custom-controls .element-row__controls .element-row__details-text-additional {
    max-width: 100%;
    width: 100%;
  }

  .element-row--with-custom-controls .element-row__details .element-row__controls-custom .suggested-bonus-controls button, .element-row--with-custom-controls .element-row__controls .element-row__controls-custom .suggested-bonus-controls button {
    width: 100%;
  }

  .element-row__details, .element-row__controls {
    gap: 10px;
  }

  .element-row__details-logo {
    min-width: 64px;
    width: 64px;
  }

  .element-row__details-text {
    font-size: 14px;
  }

  .element-row__details-text-main, .element-row__details-text-additional {
    max-width: 360px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .element-row__details-text-main, .element-row__details-text-additional {
    max-width: 250px;
  }
}

.deposit {
  background-color: #16183d;
  border-radius: 32px;
  margin-bottom: 200px;
  padding: 30px 14px;
  overflow: hidden;
}

.deposit .deposit__accordion {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

.deposit .deposit__accordion-title {
  text-align: left;
  color: #f7c903;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  display: flex;
}

.deposit .deposit__accordion-title--done {
  color: #686a8c;
}

.deposit .deposit__accordion-title-icon {
  width: 24px;
  height: 24px;
  color: #212121;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.deposit .deposit__accordion-title-icon svg {
  font-size: 16px;
}

.deposit .deposit__accordion-expand-icon {
  color: #fff;
}

.deposit .deposit__accordion-content {
  display: flex;
}

.deposit .deposit__accordion-content__cards, .deposit .deposit__accordion-content__coupons {
  flex-direction: column;
  gap: 10px;
}

.deposit .deposit__accordion-content__cards .custom-switch, .deposit .deposit__accordion-content__coupons .custom-switch {
  color: #fff;
}

.deposit .deposit__accordion-content__payment-methods {
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

.deposit .deposit__accordion-content__payment-amount {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.deposit .deposit__accordion-content__payment-amount__options {
  width: 100%;
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.deposit .deposit__accordion__input input {
  color: #fff;
  z-index: 1;
}

.deposit .deposit__accordion__input input::placeholder {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.deposit .deposit__accordion__input:hover fieldset {
  background-color: #2b2e52 !important;
  border: none !important;
  transition: background-color .3s !important;
}

.deposit .deposit__accordion__input fieldset {
  z-index: 0;
  background-color: #25274a;
}

.deposit .deposit__accordion__input-helper-text {
  color: #fff;
  opacity: .4;
  margin-top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.deposit .deposit__accordion__input-helper-text--error {
  color: red;
  opacity: 1;
}

.deposit .deposit__accordion__input-adornment {
  z-index: 2;
  color: #fff;
  padding: 0 6px;
}

.deposit .deposit__accordion__input-adornment p {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
}

.deposit .deposit__accordion__input-end-adornment {
  z-index: 10;
}

.deposit .deposit__accordion__input-end-adornment span button {
  width: 24px !important;
  height: 24px !important;
}

.deposit .checkout-summary {
  margin-top: 40px;
  padding: 0 16px;
  font-family: Poppins;
}

.deposit .checkout-summary__title {
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}

.deposit .checkout-summary__row, .deposit .checkout-summary__row--with-border, .deposit .checkout-summary__row--large-font {
  justify-content: space-between;
  display: flex;
}

.deposit .checkout-summary__row--large-font {
  font-size: 21px;
  font-weight: 600;
}

.deposit .checkout-summary__row--with-border {
  border-top: 1px solid #ffffff26;
  margin-top: 16px;
  margin-bottom: 50px;
  padding-top: 16px;
}

.deposit .checkout-summary__row-label, .deposit .checkout-summary__row-info {
  gap: 10px;
  display: flex;
}

.deposit .checkout-summary__controls {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.deposit .checkout-summary__controls button {
  max-width: 320px;
}

.deposit .checkout-summary__controls button:disabled {
  color: #c3c3c3;
}

@media (max-width: 1200px) {
  .deposit {
    padding: 10px 14px 30px;
  }
}

@media (max-width: 768px) {
  .deposit {
    padding: 10px 4px 30px;
  }
}

@media (max-width: 576px) {
  .deposit {
    padding: 10px 4px 30px;
  }

  .deposit .deposit__accordion-title {
    font-size: 21px;
  }

  .deposit .deposit__accordion-content__payment-methods {
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .deposit .deposit__accordion__input input {
    color: #fff;
    z-index: 1;
  }

  .deposit .deposit__accordion__input input::placeholder, .deposit .deposit__accordion__input-helper-text {
    font-size: 14px;
  }

  .deposit .checkout-summary__title {
    font-size: 21px;
  }

  .deposit .checkout-summary__row, .deposit .checkout-summary__row--large-font, .deposit .checkout-summary__row--with-border {
    font-size: 14px;
  }

  .deposit .checkout-summary__row--large-font {
    font-size: 19px;
  }

  .deposit .checkout-summary__controls button {
    width: 100%;
    max-width: 100%;
  }
}

.user-details-header {
  position: relative;
}

.user-details-header__title {
  color: var(--main-color) !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.user-details-content-container {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.user-details-content-container__content {
  width: 100%;
  gap: 20px;
  display: flex;
}

.user-details-content-container__content__input {
  width: 100%;
}

.mui-dialog-details-popup {
  width: 450px !important;
  border-radius: 30px !important;
  flex-flow: column !important;
  gap: 14px !important;
  margin: 0 !important;
  padding: 24px !important;
  display: flex !important;
}

.continue-button-container {
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

.continue-button-container__custom-continue-button {
  color: var(--main-color) !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

.continue-button-container__custom-continue-button:hover {
  background: #fff !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

.pagination-container {
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.pagination-container .pagination .pagination-item {
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: #08287c66;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-container .pagination .pagination-item--is-selected, .pagination-container .pagination .pagination-item--is-selected:hover {
  color: #031e44;
  background-color: #fff;
}

.pagination-container .pagination .pagination-item:has(div.pagination-item-nav) {
  background-color: #0000;
}

.pagination-container .pagination .pagination-item-nav {
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  display: flex;
}

.pagination-container .pagination .pagination-item-nav > svg {
  width: 100%;
  height: 100%;
}

.group-container {
  height: 40px;
  color: #fff !important;
  background: #ffffff1a !important;
  border-radius: 60px !important;
}

.group-button {
  font-weight: 600;
  color: #fff !important;
  height: 100% !important;
  min-width: 100px !important;
  border-radius: 100px !important;
  font-size: 14px !important;
}

.group-button--select {
  min-width: 100px !important;
  color: var(--main-color) !important;
  height: 100% !important;
  background: #fff !important;
  border: none !important;
  border-radius: 60px !important;
  font-size: 14px !important;
}

.withdrawal-summary {
  flex-direction: column;
  padding: 0 16px;
  font-family: Poppins;
  font-weight: 600;
  display: flex;
}

.withdrawal-summary__title {
  margin: 20px 0 10px;
  font-size: 24px;
}

.withdrawal-summary__additional {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.withdrawal-summary__total {
  border-top: 1px solid #ffffff26;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  font-size: 21px;
  display: flex;
}

.receiving-details__card-info__input-adornment {
  z-index: 20;
}

.receiving-details__card-info__input-adornment svg {
  color: #fff;
}

.receiving-details__card-info__input-adornment-img {
  width: 24px;
  height: 16px;
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.receiving-details__card-info__input-adornment-img .visa {
  width: 90%;
  height: 16px;
  filter: invert();
}

.receiving-details__card-info__input-adornment-img .mastercard {
  width: 125%;
}

.receiving-details__card-info__input input {
  padding-left: 5px !important;
}

.receiving-details__warning {
  width: 100%;
  color: #fff;
  border: 1px solid #f7c903;
  border-radius: 14px;
  align-items: center;
  gap: 15px;
  padding: 15px;
  font-size: 14px;
  display: flex;
}

.receiving-details__bitcoin {
  flex-direction: column;
  gap: 10px;
  font-family: Poppins;
  display: flex;
}

.receiving-details__bitcoin input {
  color: #fff;
  z-index: 1;
  padding-left: 20px;
}

.receiving-details__bitcoin input::placeholder {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.receiving-details__bitcoin:hover fieldset {
  background-color: #2b2e52 !important;
  border: none !important;
  transition: background-color .3s !important;
}

.receiving-details__bitcoin fieldset {
  z-index: 0;
  background-color: #25274a;
}

.receiving-details__bitcoin > div > p {
  margin: 0;
}

.receiving-details__bitcoin-helper {
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.receiving-details__bitcoin-helper-text {
  color: #fff;
  opacity: .4;
  margin-top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.receiving-details__bitcoin-helper-text--error {
  color: red;
  opacity: 1;
}

.receiving-details__bitcoin-helper-button {
  color: #fff;
  background-color: #25274a;
  border: none;
  border-radius: 14px;
  gap: 8px;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.receiving-details__bitcoin-helper-button:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.receiving-details__bitcoin .receiving-details__bitcoin-adornment {
  z-index: 2;
  color: #fff;
  padding: 0 6px;
}

.receiving-details__bitcoin .receiving-details__bitcoin-adornment p {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
}

.receiving-details__bank {
  font-family: Poppins;
}

.receiving-details__bank-info {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.receiving-details__bank-info__option-image {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.receiving-details__bank-info .receiving-details__bank-info__dropdown input {
  padding-left: 20px !important;
}

.receiving-details__bank-info .receiving-details__bank-info__input input {
  color: #fff;
  z-index: 1;
  padding-left: 20px;
  font-family: Poppins !important;
}

.receiving-details__bank-info .receiving-details__bank-info__input input::placeholder {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.receiving-details__bank-info .receiving-details__bank-info__input:hover fieldset {
  background-color: #2b2e52 !important;
  border: none !important;
  transition: background-color .3s !important;
}

.receiving-details__bank-info .receiving-details__bank-info__input fieldset {
  z-index: 0;
  background-color: #25274a;
  font-family: Poppins !important;
}

.receiving-details__bank-info .receiving-details__bank-info__input > div > p {
  margin: 0;
}

.receiving-details__bank-info .receiving-details__bank-info__input-helper {
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.receiving-details__bank-info .receiving-details__bank-info__input-helper-text {
  color: #fff;
  opacity: .4;
  margin-top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.receiving-details__bank-info .receiving-details__bank-info__input-helper-text--error {
  color: red;
  opacity: 1;
}

.receiving-details__bank-info .receiving-details__bank-info__input-helper-button {
  color: #fff;
  background-color: #25274a;
  border: none;
  border-radius: 14px;
  gap: 8px;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.receiving-details__bank-info .receiving-details__bank-info__input-helper-button:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.receiving-details__bank-info .receiving-details__bank-info__input-adornment {
  z-index: 2;
  color: #fff;
  padding: 0 6px;
}

.receiving-details__bank-info .receiving-details__bank-info__input-adornment p {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
}

.receiving-details__bank-info .receiving-details__bank-info__input-end-adornment {
  z-index: 10;
}

.receiving-details__bank-info .receiving-details__bank-info__input-end-adornment span button {
  width: 24px !important;
  height: 24px !important;
}

.receiving-details__bank-details {
  color: #fff;
  flex-direction: column;
  margin-top: 20px;
  display: block;
}

.receiving-details__bank-details-title {
  font-size: 21px;
  font-weight: 600;
}

.receiving-details__bank-details-row {
  gap: 15px;
  margin-top: 10px;
  display: flex;
}

.receiving-details__bank-details-row__input-container {
  flex-direction: column;
  flex: 1;
  gap: 8px;
  display: flex;
}

.receiving-details__bank-details-row__input-container label {
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .receiving-details__bank-details-row {
    flex-direction: column;
  }
}

.withdraw, .verification {
  background-color: #16183d;
  border-radius: 32px;
  margin-bottom: 20px;
  padding: 30px 14px;
  display: flex;
  overflow: hidden;
}

.verification {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 25px;
  font-family: Poppins;
}

.verification__message {
  max-width: 680px;
  text-align: left;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.verification__button {
  max-width: 185px;
}

.withdraw {
  flex-direction: column;
}

.withdraw .withdraw__accordion {
  box-shadow: none;
  background-color: #0000;
  border: none;
}

.withdraw .withdraw__accordion--is-disabled {
  background-color: #0000 !important;
}

.withdraw .withdraw__accordion-title {
  text-align: left;
  color: #f7c903;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  display: flex;
}

.withdraw .withdraw__accordion-title--done {
  color: #686a8c;
}

.withdraw .withdraw__accordion-title-icon {
  width: 24px;
  height: 24px;
  color: #212121;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.withdraw .withdraw__accordion-title-icon svg {
  font-size: 16px;
}

.withdraw .withdraw__accordion-expand-icon {
  color: #fff;
}

.withdraw .withdraw__accordion-content {
  display: flex;
}

.withdraw .withdraw__accordion-content__cards {
  gap: 10px;
  display: flex;
}

.withdraw .withdraw__accordion-content__cards__element {
  height: 80px;
  background-color: #25274a;
  border-radius: 14px;
  flex: 1;
  justify-content: center;
  padding: 30px;
  display: flex;
  position: relative;
}

.withdraw .withdraw__accordion-content__cards__element:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.withdraw .withdraw__accordion-content__cards__element-image--card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.withdraw .withdraw__accordion-content__cards__element--is-selected, .withdraw .withdraw__accordion-content__cards__element--is-selected:hover {
  background-color: #fff;
}

.withdraw .withdraw__accordion-content__cards__element--is-selected .withdraw__accordion-content__cards__element-image {
  filter: invert();
}

.withdraw .withdraw__accordion-content__cards__element--is-disabled:hover {
  cursor: default;
  background-color: #25274a;
}

.withdraw .withdraw__accordion-content__cards__element--is-disabled .withdraw__accordion-content__cards__element-image {
  opacity: .3;
}

.withdraw .withdraw__accordion-content__cards__element--is-disabled .withdraw__accordion-content__cards__element-lock {
  color: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
}

.withdraw .withdraw__accordion__input input {
  color: #fff;
  z-index: 1;
}

.withdraw .withdraw__accordion__input input::placeholder {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.withdraw .withdraw__accordion__input:hover fieldset {
  background-color: #2b2e52 !important;
  border: none !important;
  transition: background-color .3s !important;
}

.withdraw .withdraw__accordion__input fieldset {
  z-index: 0;
  background-color: #25274a;
}

.withdraw .withdraw__accordion__input > div > p {
  margin: 0;
}

.withdraw .withdraw__accordion__input-helper {
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.withdraw .withdraw__accordion__input-helper-text {
  color: #fff;
  opacity: .4;
  margin-top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.withdraw .withdraw__accordion__input-helper-text--error {
  color: red;
  opacity: 1;
}

.withdraw .withdraw__accordion__input-helper-button {
  color: #fff;
  background-color: #25274a;
  border: none;
  border-radius: 14px;
  gap: 8px;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.withdraw .withdraw__accordion__input-helper-button:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.withdraw .withdraw__accordion__input-adornment {
  z-index: 2;
  color: #fff;
  padding: 0 6px;
}

.withdraw .withdraw__accordion__input-adornment p {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
}

.withdraw .withdraw__accordion__input-end-adornment {
  z-index: 10;
}

.withdraw .withdraw__accordion__input-end-adornment span button {
  width: 24px !important;
  height: 24px !important;
}

.withdraw .withdraw__button {
  max-width: 320px;
  margin: 40px 0 0 16px;
}

@media (max-width: 1024px) {
  .withdraw {
    padding-left: 4px;
    padding-right: 4px;
  }

  .withdraw .withdraw__accordion-title {
    font-size: 21px;
  }

  .withdraw .withdraw__accordion-content__cards {
    flex-direction: row;
  }

  .withdraw .withdraw__accordion-content__cards__element {
    width: 33.3%;
    height: auto;
    padding: 18px;
  }

  .withdraw .withdraw__accordion-content__cards__element-image {
    width: 110px;
  }

  .withdraw .withdraw__accordion-content__cards__element--is-disabled .withdraw__accordion-content__cards__element-lock {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
  }
}

@media (max-width: 768px) {
  .verification {
    flex-direction: column;
  }

  .verification__message {
    text-align: center;
  }
}

.free-spins {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
}

.free-spins__tile {
  background: #25274a;
  border-radius: 14px;
  flex: 45%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 10px 28px 10px 10px;
  display: flex;
}

.free-spins__tile:hover {
  cursor: pointer;
  background-color: #2b2e52;
  transition: background-color .3s;
}

.free-spins__tile--disabled {
  mix-blend-mode: luminosity;
}

.free-spins__tile-info {
  flex-direction: row;
  gap: 15px;
  display: flex;
}

.free-spins__tile-info__image {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.free-spins__tile-info__text-container {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.free-spins__tile-info__text-container__row {
  gap: 5px;
  display: flex;
}

.free-spins__tile-info__text-container__row--bold {
  font-weight: 700;
}

.free-spins__tile-info__text-container__row--capitalized {
  text-transform: capitalize;
}

@media (max-width: 1024px) {
  .free-spins__tile {
    gap: 0;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .free-spins {
    flex-direction: column;
    gap: 15px;
  }

  .free-spins__tile-info__text-container {
    font-size: 14px;
  }
}

.custom-chip {
  color: #fff;
  background: #08287c;
  border-radius: 3px;
  align-items: center;
  padding: 1px 5px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  display: flex;
}

.custom-chip--success {
  background: #5bb524;
}

.custom-chip--highlighted {
  color: #031e44;
  background: #f7c903;
}

.custom-chip--condensed {
  font-size: 12px;
}

.custom-chip--disabled {
  color: #fff;
  background: #686a8c;
}

@media screen and (max-width: 960px) {
  .custom-chip {
    font-size: 12px;
  }
}

.bonuses__past {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.past-bonus-controls {
  align-items: center;
  gap: 20px;
  display: flex;
}

.past-bonus-controls__text {
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .bonuses__past {
    gap: 15px;
  }
}

.progress-bar {
  flex-direction: column;
  gap: 5px;
  font-family: Poppins;
  font-size: 16px;
  display: flex;
}

.progress-bar__label {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.progress-bar__label-text {
  font-weight: 600;
}

.progress-bar__label-percentage {
  font-weight: 400;
}

.progress-bar__bar {
  min-width: 200px;
  background: #ffffff1a;
  border-radius: 10px;
  padding: 2px;
  display: flex;
  overflow: hidden;
}

.progress-bar__bar-progress {
  height: 10px;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
  border-radius: 10px;
  display: flex;
}

.active-bonus-controls {
  align-items: center;
  gap: 15px;
  display: flex;
}

.suggested-bonus-controls {
  align-items: center;
  gap: 15px;
  display: flex;
}

.suggested-bonus-controls .suggested-bonus-controls__button {
  width: 140px;
  height: 40px;
  color: #031e44;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%);
  padding: 0 16px;
  box-shadow: 0 4px 4px #00000040;
}

.suggested-bonus-controls .suggested-bonus-controls__button:hover {
  background: #fff;
  box-shadow: 0 0 10px #ffd41b80;
}

.suggested-bonus-controls .suggested-bonus-controls__button:disabled {
  color: #c3c3c3;
}

.bonuses-table {
  flex-direction: column;
  gap: 34px;
  display: flex;
}

.bonuses-table__head {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.bonuses-table__head .bonuses-table__head-title {
  align-items: center;
  gap: 20px;
  display: flex;
}

.bonuses-table__head .bonuses-table__head-title .bonuses__title {
  margin: 0;
}

.bonuses-table__head-filter {
  width: 300px;
}

.bonuses-table__content {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

@media (max-width: 768px) {
  .bonuses-table {
    gap: 29px;
  }

  .bonuses-table__head {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .bonuses-table__head-filter {
    width: 100%;
  }

  .bonuses-table .element-row--with-custom-controls .element-row__controls-custom .past-bonus-controls {
    justify-content: center;
  }

  .bonuses-table .element-row--with-custom-controls .element-row__controls-custom .past-bonus-controls__text {
    display: none;
  }
}

.bonuses__recent {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.bonuses__add-coupon {
  max-width: 240px;
  width: 50%;
  margin-top: 20px;
}

.bonuses .bonuses__new-coupon {
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

.bonuses .bonuses__new-coupon input {
  color: #fff;
  z-index: 1;
}

.bonuses .bonuses__new-coupon input::placeholder {
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.bonuses .bonuses__new-coupon:hover fieldset {
  background-color: #2b2e52 !important;
  border: none !important;
  transition: background-color .3s !important;
}

.bonuses .bonuses__new-coupon fieldset {
  z-index: 0;
  background-color: #25274a;
}

.bonuses .bonuses__new-coupon-helper-text {
  color: #fff;
  opacity: .4;
  margin-top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
}

.bonuses .bonuses__new-coupon-helper-text--error {
  color: red;
  opacity: 1;
}

.bonuses .bonuses__new-coupon-adornment {
  z-index: 2;
  color: #fff;
  padding: 0 6px;
}

.bonuses .bonuses__new-coupon-adornment p {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
}

.bonuses .bonuses__new-coupon-end-adornment {
  z-index: 10;
}

.bonuses .bonuses__new-coupon-end-adornment span button {
  width: 24px !important;
  height: 24px !important;
}

.bonuses .bonuses__new-coupon button {
  max-width: 340px;
}

.bonuses .bonuses__new-coupon button:disabled {
  color: #c3c3c3 !important;
}

@media (max-width: 576px) {
  .bonuses__recent {
    gap: 15px;
  }

  .bonuses__add-coupon {
    width: auto;
    margin-top: 15px;
  }
}

.bonuses {
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;
  display: flex;
}

.bonuses-box {
  background-color: #16183d;
  border-radius: 32px;
  padding: 30px;
}

.bonuses .bonuses__title {
  text-align: left;
  color: #f7c903;
  align-items: center;
  margin-bottom: 34px;
  font-family: Poppins;
  font-size: 31px;
  font-weight: 700;
  display: flex;
}

.bonuses__see-all {
  color: #fff;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
  padding: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  display: flex;
}

@media (max-width: 1200px) {
  .bonuses {
    gap: 20px;
  }

  .bonuses .bonuses__title {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .bonuses-box {
    padding: 20px;
  }
}

.free-spins-table {
  flex-direction: column;
  gap: 34px;
  display: flex;
}

.free-spins-table__head {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.free-spins-table__head-title {
  align-items: center;
  gap: 20px;
  display: flex;
}

.free-spins-table__head-title .bonuses__title {
  margin: 0;
}

.free-spins-table__head .free-spins-table__head-filter {
  width: 300px;
}

.free-spins-table__head .free-spins-table__head-filter .free-spins-table__head-filter__search > div {
  background-color: #0000 !important;
}

.free-spins-table__head .free-spins-table__head-filter .free-spins-table__head-filter__search input {
  color: #fff !important;
}

.free-spins-table__head .free-spins-table__head-filter .free-spins-table__head-filter__search svg {
  z-index: 1;
}

.free-spins-table__head .free-spins-table__head-filter .free-spins-table__head-filter__search fieldset {
  background-color: #25274a;
  border: 1px solid #ffffff26 !important;
}

.free-spins-table__content {
  flex-flow: wrap;
  gap: 20px;
  display: flex;
}

.free-spins-table__content .free-spins__tile {
  max-width: calc(50% - 10px);
}

@media (max-width: 768px) {
  .free-spins-table__content {
    flex-direction: column;
    gap: 15px;
  }

  .free-spins-table__content .free-spins__tile {
    max-width: 100%;
  }
}

.cashback-screen {
  height: auto;
  background: #16183d;
  border-radius: 14px;
  flex-direction: column;
  display: flex;
}

.cashback-screen__container {
  height: 90px;
  width: 100%;
  background: #16183d;
  border-radius: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  display: flex;
}

.cashback-screen__container__left-side {
  flex-direction: row;
  align-items: center;
  gap: 15px;
  display: flex;
}

.cashback-screen__container__left-side__image {
  width: 70px;
  height: 70px;
}

.cashback-screen__container__left-side__text {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.cashback-screen__container__left-side__text__title {
  color: #fff;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.cashback-screen__container__right-side {
  flex-direction: row;
  align-items: center;
  gap: 15px;
  display: flex;
}

.cashback-screen__container__right-side__cashback-amount {
  color: #f7c903;
  text-wrap: nowrap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.cashback-screen__container__right-side__cashback-amount--mobile {
  text-wrap: nowrap;
  color: #f7c903;
  text-wrap: nowrap;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.cashback-screen__container__right-side--bottom-mobile {
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}

.chasback-bonus-collect__button {
  width: 141px !important;
  height: 42px !important;
  color: #031e44 !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  padding: 10px 32px !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

.chasback-bonus-collect__button:hover {
  background: #fff !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

.chasback-bonus-collect__button--mobile {
  width: 100% !important;
  height: 42px !important;
  color: #031e44 !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  margin-bottom: 20px !important;
  padding: 10px 32px !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

@media (max-width: 767px) {
  .cashback-screen__container {
    height: auto;
  }

  .chasback-bonus-collect {
    display: none;
  }
}

@media (max-width: 575px) {
  .cashback-screen__container__right-side__cashback-amount, .chasback-bonus-collect {
    display: none;
  }
}

.menu-tabs {
  gap: 10px;
  display: flex;
}

.menu-tabs-vertical {
  width: 100%;
  flex-direction: column;
}

.menu-tabs__button {
  color: #fff;
  cursor: pointer;
  background-color: #16183d;
  border: none;
  border-radius: 14px;
  align-items: center;
  gap: 15px;
  padding: 10px;
  display: flex;
}

.menu-tabs__button:hover, .menu-tabs__button--is-selected {
  background-color: #222447;
  transition: background-color .3s !important;
}

.menu-tabs__button-icon {
  min-width: 44px;
  min-height: 44px;
  background-color: #222447;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu-tabs__button-icon--rotate-270 {
  transform: rotate(270deg);
}

.menu-tabs__button-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
}

.details-container {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 15%;
  display: flex;
}

.details-container__top-box {
  width: 100%;
  height: auto;
  background: #16183d;
  border-radius: 32px;
  padding: 30px;
}

.details-container__status-user-badge-box {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  display: flex;
}

.details-container__status-user-box {
  gap: 3px;
}

.details-container__level-information-box {
  width: 100%;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.details-container__balance-box-container {
  width: 100%;
  align-items: center;
  gap: 30px;
  display: flex;
}

@media (max-width: 1920px) {
  .details-container__balance-box-container {
    gap: 20px;
  }
}

@media (max-width: 1024px) {
  .details-container {
    margin-bottom: 35%;
  }

  .details-container__balance-box-container {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width: 500px) {
  .details-container__status-user-badge-box {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    display: flex;
  }
}

@media (max-width: 375px) {
  .details-container {
    margin-bottom: 300px;
  }
}

.container {
  align-items: center;
  gap: 7px;
  display: inline-flex;
}

.container__status-text {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.container__icon-box {
  width: 16px;
  height: 20px;
  align-items: center;
  display: inline-flex;
}

@media (max-width: 700px) {
  .container__status-text {
    font-size: 14px;
  }
}

.username-container {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media (max-width: 1023px) {
  .username-container {
    font-size: 21px;
  }
}

@media (max-width: 700px) {
  .username-container {
    font-size: 19px;
  }
}

.horizontal-line-container {
  width: 100%;
  height: 1px;
  background: #ffffff26;
  margin-top: 15px;
  margin-bottom: 15px;
}

.account-details-balance-box-container {
  width: 100%;
  background: #16183d;
  border-radius: 16px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 20px 30px;
  display: flex;
  overflow: hidden;
}

.account-details-balance-box-container__text-value-box {
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.account-details-balance-box-container__text-box {
  color: #fff;
  text-wrap: nowrap;
  gap: 7px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.account-details-balance-box-container__value-stars-box {
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  display: flex;
}

.account-details-balance-box-container__value-box {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.account-details-balance-box-container__icon-box {
  color: #fff;
  width: 44px;
  height: 44px;
  background: #ffffff0d;
  border-radius: 14px;
  align-items: center;
  gap: 8px;
  padding: 10px;
  display: flex;
}

.stars-icon {
  width: 20px;
  height: 20px;
  color: #ffd000;
}

.icon-button {
  background-color: #0000;
  margin: 0;
  padding: 0;
}

.icon-button:hover {
  background-color: #0000;
}

.verify-box-container {
  width: 100%;
  height: auto;
  background: #16183d;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.verify-box-container__image-wrapper {
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.verify-box-container__img-box {
  width: 100%;
  height: 100%;
  opacity: .8;
  z-index: 1;
  background: url("bubble.bc0eba30.svg") center / cover no-repeat;
  position: absolute;
}

.verify-box-container__img {
  width: 70%;
  height: 70%;
  opacity: 1;
  z-index: 2;
  background: url("shield-temp.cdc0bc00.png") center / contain no-repeat;
  position: absolute;
}

.verify-box-container__text-box {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.verify-button {
  text-wrap: nowrap !important;
  min-height: 42px !important;
  padding: 10px 32px !important;
}

@media (max-width: 700px) {
  .verify-box-container {
    flex-direction: column;
    padding: 30px 20px;
  }

  .verify-box-container__text-box {
    text-align: center;
  }
}

.edit-message-container {
  height: 54px;
  border: 1px solid #f7c903;
  border-radius: 14px;
  align-self: stretch;
  align-items: center;
  gap: 14px;
  margin-top: 30px;
  padding: 15px;
  display: flex;
}

.edit-message-container__text-box {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width: 576px) {
  .edit-message-container {
    height: auto;
  }
}

.personal-details-main-container {
  width: 100%;
  height: auto;
  background: #16183d;
  border-radius: 32px;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  display: flex;
}

.personal-details-title-container {
  width: 100%;
  height: auto;
  background: #16183d;
  border-radius: 32px;
}

.personal-details-title-container__status-title-box {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  display: flex;
}

.personal-details-title-container__status-title-box__title-box {
  color: #f7c903;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.personal-details-title-container__status-title-box__status-box {
  background: #ffffff0d;
  border-radius: 14px;
  padding: 10px 20px;
}

.personal-details-form-container {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  display: grid;
}

.personal-details-form-container__child, .personal-details-form-container__child--custom {
  grid-column: span 2;
}

.personal-details-form-container--span-4 {
  grid-column: span 4;
}

.personal-details-form-container--span-1 {
  grid-column: span 1;
  margin-top: 30px;
}

.personal-details-form-container--flex-span-2 {
  grid-column: span 2;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.personal-details-edit-button, .personal-details-save-button, .personal-details-cancel-button {
  text-wrap: nowrap !important;
  height: 54px !important;
  text-transform: capitalize !important;
  min-width: 240px !important;
  padding: 16px 32px !important;
  font-size: 19px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
}

.personal-details-cancel-button {
  color: #fff !important;
  background: #16183d !important;
  border: 1px solid #fff !important;
}

@media (max-width: 768px) {
  .personal-details-main-container {
    padding: 20px;
  }

  .personal-details-title-container__status-title-box__title-box {
    font-size: 24px;
  }
}

@media (max-width: 700px) {
  .personal-details-edit-button {
    min-width: 320px !important;
    height: 42px !important;
  }

  .personal-details-save-button, .personal-details-cancel-button {
    flex: 1;
    width: auto !important;
    min-width: 0 !important;
    height: 42px !important;
  }

  .personal-details-form-container--flex-span-2 {
    grid-column: span 4;
    align-items: center;
    gap: 20px;
    display: flex;
  }
}

@media (max-width: 540px) {
  .personal-details-edit-button {
    min-width: 160px !important;
    height: 42px !important;
    padding: 8px 16px !important;
  }

  .personal-details-form-container--flex-span-2 {
    grid-column: span 4;
    gap: 10px;
  }

  .personal-details-title-container__status-title-box {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 10px;
    display: flex;
  }
}

@media (max-width: 600px) {
  .personal-details-form-container__child--custom {
    grid-column: span 4;
  }
}

.account-transactions-container {
  width: 100%;
  height: auto;
  background: #16183d;
  border-radius: 32px;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20%;
  padding: 30px;
  display: flex;
}

.account-transactions-container__title {
  color: #f7c903;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.account-transactions-container__title-filter-box {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  display: flex;
}

.account-transactions-container__filters-box {
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 15px;
  display: grid;
}

.account-transactions-container__filters-box--type, .account-transactions-container__filters-box--period {
  grid-column: span 1;
}

@media (max-width: 1399px) {
  .account-transactions-container {
    margin-bottom: 45%;
  }
}

@media (max-width: 1199px) {
  .account-transactions-container {
    margin-bottom: 20%;
  }
}

@media (max-width: 1023px) {
  .account-transactions-container__title {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .account-transactions-container {
    margin-bottom: 30%;
  }

  .account-transactions-container__filters-box {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }

  .account-transactions-container__filters-box--type {
    grid-column: span 2;
  }

  .account-transactions-container__filters-box--period {
    grid-column: span 1;
  }
}

@media (max-width: 575px) {
  .account-transactions-container {
    margin-bottom: 100%;
  }
}

@media (min-width: 576px) {
  .account-transactions-container {
    margin-bottom: 100%;
  }

  .account-transactions-container__filters-button {
    display: none;
  }
}

.transactions-table-container {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.transactions-table-container__headers-container {
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.transactions-table-container__headers-container__fixed-box {
  width: 180px;
  color: #fff;
  text-align: left;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.transactions-table-container__headers-container__auto-box {
  width: 13.33%;
  color: #fff;
  text-align: left;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.transactions-table-container__headers-container__last-box {
  width: 11.11%;
  text-align: right;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.transactions-table-container__row-cointainer {
  width: 100%;
  min-height: 40px;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 15px 20px;
  display: flex;
}

.transactions-table-container__row-cointainer__fixed-box {
  width: 180px;
  text-align: left;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.transactions-table-container__row-cointainer__fixed-box__id-date-box {
  flex-direction: column;
  display: flex;
}

.transactions-table-container__row-cointainer__fixed-box__id-date-box__id-box, .transactions-table-container__row-cointainer__fixed-box__id-date-box__type-box {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.transactions-table-container__row-cointainer__fixed-box__id-date-box__date-box {
  color: #fff;
  text-wrap: nowrap;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.transactions-table-container__row-cointainer__fixed-box__icon-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.transactions-table-container__row-cointainer__auto-box, .transactions-table-container__row-cointainer__auto-box__type {
  width: 13.33%;
  text-align: left;
  color: #fff;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
}

.transactions-table-container__row-cointainer__auto-box__status {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.transactions-table-container__row-cointainer__auto-box__status--declined {
  width: 8px;
  height: 8px;
  background-color: #e03828;
  border-radius: 50%;
  flex-shrink: 0;
}

.transactions-table-container__row-cointainer__auto-box__status--completed {
  width: 8px;
  height: 8px;
  background-color: #2cdb78;
  border-radius: 50%;
  flex-shrink: 0;
}

.transactions-table-container__row-cointainer__auto-box__status--pending {
  width: 8px;
  height: 8px;
  background-color: #f09a05;
  border-radius: 50%;
  flex-shrink: 0;
}

.transactions-table-container__row-cointainer__auto-box__status__box {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.transactions-table-container__row-cointainer__last-box {
  width: 11.11%;
  text-align: right;
  color: #fff;
  text-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
}

.transactions-table-container__row-cointainer__last-box__icon {
  color: #fff;
  width: 38px;
  height: 38px;
  border-radius: 14px;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
  margin-right: -8px;
}

.transactions-table-container__row-cointainer__last-box__icon-accordion {
  color: #fff;
  width: 38px;
  height: 38px;
  border-radius: 14px;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 4px;
  margin-right: -10px;
}

.accordion-container {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  display: flex;
}

.accordion-container__id-type-box {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.accordion-container__id-type-box__id-box {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.accordion-container__id-type-box__type-box {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.accordion-container__delete-icon {
  margin-right: -16px;
}

.background {
  background: #ffffff0d;
}

.disabled {
  color: #fff6;
}

@media (max-width: 1023px) {
  .transactions-table-container__row-cointainer__fixed-box__id-date-box__id-box, .transactions-table-container__row-cointainer__auto-box, .transactions-table-container__row-cointainer__auto-box__status__box, .transactions-table-container__row-cointainer__last-box {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .transactions-table-container__headers-container, .transactions-table-container__row-cointainer__fixed-box__id-date-box__id-box, .transactions-table-container__row-cointainer__auto-box__status__box, .transactions-table-container__row-cointainer__auto-box__type, .transactions-table-container__row-cointainer__last-box__icon {
    display: none;
  }

  .accordion-container {
    padding: 15px 20px;
  }

  .accordion-container__delete-icon {
    margin-right: -20px;
  }
}

@media (min-width: 768px) {
  .transactions-table-container__row-cointainer__fixed-box__id-date-box__type-box, .transactions-table-container__row-cointainer__last-box__icon-accordion {
    display: none;
  }
}

@media (max-width: 575px) {
  .transactions-table-container__row-cointainer {
    padding: 15px;
  }

  .transactions-table-container__row-cointainer__fixed-box {
    width: 110px;
    gap: 10px;
  }

  .transactions-table-container__row-cointainer__fixed-box__id-date-box__id-box {
    font-size: 14px;
  }

  .transactions-table-container__row-cointainer__fixed-box__id-date-box__date-box {
    font-size: 12px;
  }

  .transactions-table-container__row-cointainer__fixed-box__id-date-box__type-box {
    font-size: 14px;
  }

  .accordion-container {
    padding: 15px;
  }

  .accordion-container__delete-icon {
    margin-right: -14px;
  }
}

@media (max-width: 425px) {
  .transactions-table-container__row-cointainer {
    padding: 10px;
  }

  .transactions-table-container__row-cointainer__fixed-box {
    gap: 5px;
  }

  .accordion-container {
    padding: 10px;
  }

  .accordion-container__delete-icon {
    margin-right: -8px;
  }
}

.account {
  justify-content: center;
  gap: 40px;
  padding: 140px 20% 0;
  display: flex;
}

.account-menu {
  max-width: 440px;
  height: -moz-fit-content;
  height: fit-content;
  background: #16183d;
  border-radius: 32px;
  flex: 1;
  padding: 20px 30px;
  display: flex;
}

.account-subpage {
  flex: 2;
  overflow: auto;
}

@media (max-width: 1920px) {
  .account {
    padding: 140px 7% 0;
  }
}

@media (max-width: 1400px) {
  .account {
    gap: 30px;
  }

  .account-menu {
    max-width: 380px;
    padding: 20px;
  }
}

@media (max-width: 1399px) {
  .account {
    gap: 20px;
    padding: 140px 5% 0;
  }

  .account-menu {
    max-width: 300px;
    padding: 20px;
  }
}

@media (max-width: 1199px) {
  .account {
    padding: 100px 70px 0;
  }

  .account-menu {
    display: none;
  }
}

@media (max-width: 1024px) {
  .account {
    padding: 100px 8% 0;
  }
}

@media (max-width: 767px) {
  .account {
    padding: 100px 20px 0;
  }

  .account-menu {
    display: none;
  }
}

@media (max-width: 576px) {
  .account {
    padding: 100px 2% 0;
  }
}

.settings-container {
  background: #16183d;
  border-radius: 32px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  padding: 30px;
  display: flex;
}

.settings-container__title {
  color: #f7c903;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.settings-container__row-container {
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  display: flex;
}

.settings-container__row-container__text-box {
  color: #fff;
  flex: 1;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  display: flex;
}

.settings-container__row-container__status-button-box {
  flex: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  display: flex;
}

.settings-container__row-container--verify-button-box-column {
  width: 100%;
}

.settings-container__row-container-password {
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  display: flex;
}

.settings-container__row-container-password__text-box {
  color: #fff;
  flex: 1;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  display: flex;
}

.settings-container__horizontal-line {
  width: 100%;
  border: 1px solid #ffffff26;
  margin-top: 20px;
  margin-bottom: 20px;
}

.verify-button {
  gap: 10px;
  display: flex;
  height: 42px !important;
  min-width: 140px !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px 32px !important;
}

@media (max-width: 1023px) {
  .settings-container__title {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .settings-container__row-container__status-button-box {
    padding-right: 6px;
  }

  .settings-container__row-container--verify-button-box-row {
    display: none;
  }

  .settings-container__row-container-password {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .settings-container__row-container-password__cancel-button-box {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .settings-container__row-container--verify-button-box-column {
    display: none;
  }
}

.dialog__title-icon-container {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.dialog__title-icon-container__title-box {
  color: #f7c903;
  justify-content: flex-start;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.dialog__title-icon-container__close-icon-box {
  color: #f7c903;
  justify-content: flex-end;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.dialog__form-row-container {
  width: 100%;
}

.dialog__form-submit-button-container {
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
  display: flex;
}

.mui-dialog {
  width: 560px !important;
  height: 560px !important;
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  background: #25274ae6 !important;
  border-radius: 32px !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 20px !important;
  margin: 0 !important;
  padding: 30px !important;
}

.visibility-icon {
  color: #fff;
  margin-right: 7px !important;
}

@media (max-width: 899px) {
  .dialog__title-icon-container__title-box {
    font-size: 24px;
  }

  .mui-dialog {
    width: 345px !important;
  }
}

.multiselect {
  width: 100%;
  height: 46px;
  background-color: #25274a;
  z-index: 1 !important;
  font: 16px Poppins !important;
}

.multiselect:after {
  content: "";
  width: 450px;
  height: 60px;
  opacity: 0;
  z-index: -1;
  background-color: #25274a;
  border-radius: 14px 14px 0 0;
  transition: opacity .35s cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  bottom: 0;
  left: -10px;
}

.multiselect--is-open:after {
  opacity: 1;
}

.multiselect .multiselect__input {
  justify-content: start;
  display: flex;
}

.multiselect .multiselect__input--has-selected-options {
  justify-content: space-between;
}

.multiselect .multiselect__input__placeholder {
  color: #fff;
  padding-top: 1px;
  padding-left: 10px;
}

.multiselect .multiselect__input__chip {
  background: var(--yellow-gradient);
}

.multiselect svg {
  color: #fff;
}

.multiselect__list {
  padding-right: 4px;
  width: 450px !important;
  height: 450px !important;
  background-color: #25274a !important;
  border-radius: 0 0 14px 14px !important;
  overflow: hidden !important;
  transform: translate(0) !important;
}

.multiselect__list ul {
  height: 380px;
  margin-top: 5px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
}

.multiselect__list ul::-webkit-scrollbar {
  width: 6px;
  padding-right: 4px;
}

.multiselect__list ul::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
}

.multiselect__list__item:after {
  content: "";
  width: 80px;
  height: 40px;
  opacity: .3;
  background-repeat: no-repeat;
  display: block;
}

.multiselect__list__item--selected {
  background-color: none !important;
}

.multiselect__list__item--selected:after {
  opacity: 1;
}

.multiselect__list__item__checkbox--checked > svg {
  color: #ffd308 !important;
}

.multiselect__list__item__checkbox > svg {
  color: #454765;
}

.multiselect__list__item__text {
  color: #fff;
}

.multiselect__list__extra-actions {
  width: 100%;
  background-color: #25274a;
  gap: 10px;
  padding: 5px 10px 10px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.multiselect__list__extra-actions button {
  width: 50%;
}

@media screen and (max-height: 900px) {
  .multiselect__list {
    height: 360px !important;
  }
}

.category {
  cursor: pointer;
  background-color: #ffffff0d;
  background-position: calc(100% - 20px);
  background-repeat: no-repeat;
  border-radius: 14px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  transition: background-color .5s;
  display: flex;
}

.category:hover, .category--selected {
  background-color: #ffffff26;
}

.category--xxs {
  border-radius: 8px;
  padding: 5px 20px;
}

.category--xs {
  padding: 10px 20px;
}

.category--m {
  width: 236px;
  height: 90px;
  padding: 20px;
}

@media screen and (max-width: 1540px) {
  .category--m {
    min-width: 160px;
    width: 160px;
    height: 70px;
    background-position-x: 90%;
    background-size: 25%;
    padding: 15px 20px;
  }
}

.category-filter-row {
  flex-flow: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  display: flex;
  font: Poppins !important;
}

.additional-filter-row {
  justify-content: center;
  column-gap: 24px;
  margin-top: 24px;
  display: flex;
}

.additional-filter-row > div {
  max-width: 430px;
}

@media screen and (max-width: 1199px) {
  .category-filter-row {
    flex-flow: row;
    justify-content: center;
    gap: 10px;
    padding: 0 16px;
    font-family: Poppins;
    overflow-x: auto;
  }

  .category-filter-row::-webkit-scrollbar {
    display: none;
  }

  .additional-filter-row {
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    padding: 0 20px;
  }

  .additional-filter-row .fullscreen-list__extra-actions {
    flex-flow: wrap;
    gap: 8px;
    margin-top: 20px;
    padding: 0 15px;
    display: flex;
  }

  .additional-filter-row .fullscreen-list__extra-actions-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
  }
}

@media screen and (max-width: 1058px) {
  .category-filter-row {
    justify-content: start;
  }
}

.fullscreen-list {
  height: 100%;
  z-index: 1100;
  background-color: #25274a;
  flex-direction: column;
  padding-bottom: 78px;
  font-family: Poppins;
  display: flex;
  position: fixed;
  inset: 0;
}

.fullscreen-list__row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.fullscreen-list__row--title {
  padding: 20px 15px;
}

.fullscreen-list__row--search {
  margin-top: 40px;
  padding: 0 15px;
}

.fullscreen-list__row--search label {
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 15px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

.fullscreen-list__title {
  font-size: 29px;
  font-weight: 700;
}

.fullscreen-list__custom-search > div fieldset {
  border: 1px solid #ffffff26;
  background-color: #0000 !important;
}

.fullscreen-list__items {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  flex-direction: column;
  margin-top: 12px;
  display: flex;
  overflow-y: auto;
}

.fullscreen-list__items .fullscreen-list__item {
  padding: 0 10px;
  min-height: 55px !important;
}

.fullscreen-list__items .fullscreen-list__item:after {
  content: "";
  width: 80px;
  height: 40px;
  opacity: .3;
  background-repeat: no-repeat;
  margin-right: 10px;
  display: block;
}

.fullscreen-list__items .fullscreen-list__item--selected {
  background-color: none !important;
}

.fullscreen-list__items .fullscreen-list__item--selected:after {
  opacity: 1;
}

.fullscreen-list__items .fullscreen-list__item__checkbox--checked > svg {
  color: #ffd308 !important;
}

.fullscreen-list__items .fullscreen-list__item__checkbox > svg {
  color: #454765;
}

.fullscreen-list__items .fullscreen-list__item__text {
  text-align: left;
  color: #fff;
  padding-left: 5px;
}

.fullscreen-list__actions {
  width: 100%;
  background-color: #25274a;
  gap: 10px;
  padding: 16px;
  display: flex;
  position: fixed;
  bottom: 0;
}

.promotions .promotion {
  width: 720px;
  height: 305px;
  background-color: #16183d;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 32px;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  display: flex;
}

.promotions .promotion--claimed {
  mix-blend-mode: luminosity;
}

.promotions .promotion__content {
  width: 67%;
  flex-direction: column;
  display: flex;
}

.promotions .promotion__content__category {
  width: max-content;
  color: #fff;
  background-color: #08287c;
  padding: 1px 5px;
  font-size: 14px;
}

.promotions .promotion__content__overline {
  color: #f7c903;
  text-align: start;
  font-size: 24px;
  font-weight: 700;
}

.promotions .promotion__content__title {
  text-align: start;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}

.promotions .promotion__actions {
  width: 67%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.promotions .promotion__actions__button {
  max-height: 42px;
  width: 150px;
  font-size: 16px;
  min-width: 210px !important;
}

@media screen and (max-width: 1680px) {
  .promotions .promotion {
    width: 610px;
    height: 305px;
  }

  .promotions .promotion__content__overline {
    font-size: 20px;
  }

  .promotions .promotion__content__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 1440px) {
  .promotions .promotion {
    width: 510px;
    height: 265px;
    padding: 30px;
  }

  .promotions .promotion__content {
    width: 50%;
  }

  .promotions .promotion__content__category {
    margin-bottom: 5px;
    font-size: 12px;
  }

  .promotions .promotion__content__overline {
    font-size: 16px;
  }

  .promotions .promotion__content__title {
    font-size: 20px;
    line-height: 27px;
  }

  .promotions .promotion__actions {
    width: 50%;
  }

  .promotions .promotion__actions__button {
    width: 140px;
    min-width: 140px !important;
  }
}

@media screen and (max-width: 1160px) {
  .promotions .promotion {
    width: 422px;
  }
}

@media screen and (max-width: 960px) {
  .promotions .promotion {
    width: 640px;
  }
}

@media screen and (max-width: 500px) {
  .promotions .promotion {
    background-position-x: calc(100% + 90px);
  }
}

@media screen and (max-width: 410px) {
  .promotions .promotion {
    width: 345px;
  }
}

.custom-select {
  height: 40px;
  background: var(--button-background-dark) !important;
  box-shadow: none !important;
  color: #fff !important;
  border-radius: 25px !important;
  padding: 15px !important;
}

.custom-select--open {
  height: 40px;
  border-radius: 14px 14px 0 0;
  padding-left: 15px;
  background: var(--button-background-dark) !important;
  color: #fff !important;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.custom-select .MuiSelect-icon, .custom-select--open .MuiSelect-icon {
  color: #fff;
}

.custom-menu-paper {
  background-color: var(--button-background-dark) !important;
  color: #fff !important;
  border-radius: 0 0 14px 14px !important;
}

.info-box {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #25274ae6 !important;
  border-radius: 30px !important;
}

@media (max-width: 767px) {
  .info-box {
    width: 345px;
  }
}

@media (min-width: 768px) {
  .info-box {
    width: 560px;
  }
}

.info-box--extended-height {
  height: 800px;
}

.info-box--extended-height .info-box__content {
  max-height: 800px;
}

.info-box--extended-height .info-box__content__children {
  max-height: 100%;
}

.info-box__content {
  max-height: 700px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px;
  display: flex;
}

.info-box__content__title {
  color: #f7c903;
  width: 97%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

@media (max-width: 767px) {
  .info-box__content__title {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .info-box__content__title {
    font-size: 31px;
  }
}

.info-box__content__subtitle {
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.info-box__content__children {
  width: 100%;
  max-height: 500px;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}

.info-box__content__button {
  width: 210px !important;
}

.info-box__content__button--claimed {
  color: var(--main-color) !important;
  mix-blend-mode: luminosity !important;
}

.info-box__content__button--locked {
  color: var(--main-color) !important;
  background: var(--yellow-gradient) !important;
}

.info-box__button-container {
  width: 100%;
  display: flex;
}

.info-box__button-container--centered {
  justify-content: center;
}

.info-box__close-button {
  z-index: 120;
  position: absolute;
  top: 25px;
  right: 30px;
}

@media (max-width: 767px) {
  .info-box--extended-height {
    height: 670px;
  }

  .info-box--extended-height .info-box__content {
    max-height: 670px;
  }

  .info-box--extended-height .info-box__content__children {
    max-height: 100%;
  }
}

.info-pairs-container {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.info-pairs-container__row {
  height: 40px;
  color: var(--white, #fff);
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-style: normal;
  line-height: 130%;
  display: flex;
}

.info-pairs-container__row:nth-child(2n+1) {
  background: #ffffff0d;
  border-radius: 8px;
}

.info-pairs-container__row__key {
  font-weight: 400;
}

.info-pairs-container__row__value {
  font-weight: 600;
}

.promotions {
  flex-direction: column;
  gap: 40px;
  display: flex;
}

.promotions__title {
  font-size: 64px;
  font-weight: 700;
}

.promotions__container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  display: flex;
}

.promotions__container:after {
  content: "";
  width: 720px;
}

@media screen and (max-width: 2560px) {
  .promotions {
    padding: 0 10%;
  }
}

@media screen and (max-width: 1920px) {
  .promotions {
    padding: 0;
  }
}

@media screen and (max-width: 1680px) {
  .promotions__container:after {
    width: 610px;
  }
}

@media screen and (max-width: 1440px) {
  .promotions__container:after {
    width: 510px;
  }
}

@media screen and (max-width: 1160px) {
  .promotions__container:after {
    width: 422px;
  }
}

@media screen and (max-width: 960px) {
  .promotions {
    padding: 0 5%;
  }

  .promotions__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 576px) {
  .promotions {
    gap: 32px;
    padding: 0 15px;
  }
}

.tournament-card__games {
  width: 100%;
  flex-flow: column;
  margin-top: 25px;
  display: flex;
}

.tournament-card__games-title {
  margin-bottom: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.tournament-card__games .test {
  position: relative;
}

.tournament-card__games-list {
  scroll-behavior: smooth;
  gap: 8px;
  display: flex;
  position: relative;
  overflow: auto;
}

.tournament-card__games-list::-webkit-scrollbar {
  display: none !important;
}

.tournament-card__games-list__controls {
  width: 100%;
  height: 100%;
  flex-grow: 0;
  justify-content: space-between;
  gap: 14px;
  display: flex;
  position: absolute;
}

.tournament-card__games-list__controls-left, .tournament-card__games-list__controls-right {
  z-index: 100;
  color: #fff !important;
  background-color: #0d0f36 !important;
  border: 2px solid #e3ab00 !important;
  position: absolute !important;
}

.tournament-card__games-list__controls-left {
  top: 45%;
  left: -20px;
  transform: translate(0, -50%);
}

.tournament-card__games-list__controls-right {
  top: 45%;
  right: -20px;
  transform: translate(0, -50%);
}

.tournament-card__extra-info {
  border: 2px solid #ffd000;
  border-radius: 14px;
  gap: 30px;
  margin-top: 25px;
  padding: 15px 20px;
  display: flex;
}

.tournament-card__extra-info__prize, .tournament-card__extra-info__clock {
  flex-flow: column wrap;
  gap: 5px;
  display: flex;
}

.tournament-card__extra-info__prize section, .tournament-card__extra-info__clock section {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.tournament-card__extra-info__prize-value, .tournament-card__extra-info__clock-value {
  width: 160px;
  height: 60px;
  background: #25274a;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 31px;
  display: flex;
}

.tournament-card__extra-info__prize-value__img, .tournament-card__extra-info__clock-value__img {
  width: 33px;
}

.tournament-card__extra-info__prize-title, .tournament-card__extra-info__prize-title--extra, .tournament-card__extra-info__clock-title--extra, .tournament-card__extra-info__clock-title {
  justify-content: center;
  font-size: 12px;
}

.tournament-card__extra-info__prize-title--extra, .tournament-card__extra-info__clock-title--extra {
  display: none !important;
}

.tournament-card__extra-info__clock section {
  gap: 16px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.tournament-card__details {
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.tournament-card__details-bet {
  position: relative;
}

.tournament-card__details-bet :after {
  content: "";
  height: 12px;
  border-right: 1px solid #fff;
  position: absolute;
  bottom: 6px;
  right: -6px;
}

@media screen and (max-width: 960px) {
  .tournament-card__extra-info {
    width: 100%;
    flex-direction: column;
  }

  .tournament-card__extra-info__prize section, .tournament-card__extra-info__clock section {
    width: 100%;
    justify-content: center;
  }

  .tournament-card__extra-info__prize-title, .tournament-card__extra-info__prize-title--extra, .tournament-card__extra-info__clock-title--extra, .tournament-card__extra-info__clock-title {
    display: none !important;
  }

  .tournament-card__extra-info__prize-title--extra, .tournament-card__extra-info__clock-title--extra {
    display: flex !important;
  }
}

.tournament {
  width: 100%;
  max-width: 1200px;
  background: #16183d;
  border-radius: 32px;
  gap: 24px;
  padding: 50px;
  font-family: Poppins;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tournament:before {
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url("tournamentBackground.32ba23ba.svg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
}

.tournament--passed:before {
  opacity: .5;
  mix-blend-mode: luminosity;
}

.tournament-info {
  width: 100%;
  z-index: 1;
  flex-flow: column;
  flex-grow: 1;
  align-items: start;
  display: flex;
}

.tournament-info__name {
  color: #fff;
  align-items: start;
  font-family: Poppins;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.tournament-info__details {
  min-width: 500px;
  flex-direction: row;
  gap: 30px;
  display: flex;
}

.tournament-info__details--highlighted {
  color: #f7c903;
}

.tournament-info__details section {
  gap: 5px;
  font-family: Poppins;
  font-size: 20px;
  display: flex;
}

.tournament-actions, .tournament-actions--extra-top-margin {
  align-items: center;
  gap: 20px;
  display: flex;
}

.tournament-actions--extra-top-margin {
  margin-top: 25px;
}

.tournament-actions__main-button {
  padding-left: 40px !important;
  padding-right: 40px !important;
  font-size: 19px !important;
}

.tournament-dialog__extra-info {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
}

.tournament-dialog__extra-info__date {
  gap: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

.tournament-dialog__extra-info__text {
  max-height: 36px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tournament-dialog__extra-info__text--expanded {
  max-height: 500px;
  height: auto;
  transition: max-height .25s ease-in;
  display: flex;
}

.tournament-dialog__extra-info__button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 5px 0;
  font-family: Poppins;
  font-weight: 300;
  text-decoration: underline;
}

.info-pairs-container__row__key:has(.prize-row, .prize-row--is-header) {
  width: 100%;
  display: flex;
}

.prize-row, .prize-row--is-header {
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.prize-row--is-header .prize-row-cell:first-of-type em, .prize-row--is-header .prize-row-cell--centered:first-of-type em, .prize-row--is-header .prize-row-cell--aligned-right:first-of-type em {
  width: 22px;
  height: 22px;
  padding-left: 6px;
  font-style: normal;
}

.prize-row-cell, .prize-row-cell--centered, .prize-row-cell--aligned-right {
  flex: 1;
  align-items: center;
  gap: 10px;
  display: flex;
}

.prize-row-cell--aligned-right {
  justify-content: end;
  font-weight: 600;
}

.prize-row-cell--centered {
  padding-left: 20%;
}

.prize-item {
  align-items: center;
  gap: 10px;
  display: flex;
}

.prize-item__place {
  height: 22px;
  width: 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 8px;
  display: flex;
}

.prize-item__place--winning {
  background: var(--yellow, linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%));
  color: #000;
  height: 22px;
  width: 22px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  display: flex;
}

@media screen and (max-width: 1199px) {
  .tournament:before {
    background-position: 130% 0;
  }

  .tournament-info__name {
    font-size: 31px;
  }
}

@media screen and (max-width: 960px) {
  .tournament {
    flex-direction: column;
    padding: 30px;
  }

  .tournament:before {
    background-position: 115% 0;
    background-size: 250px;
  }

  .tournament-actions, .tournament-actions--extra-top-margin {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .prize-row-cell:first-of-type, .prize-row-cell--aligned-right:first-of-type, .prize-row-cell--centered:first-of-type {
    min-width: 100px;
  }

  .prize-row-cell--centered {
    padding: 5%;
  }
}

@media screen and (max-width: 640px) {
  .tournament:before {
    background-position: 130% 0;
  }

  .tournament-info__name {
    font-size: 24px;
  }

  .tournament-info__details {
    flex-direction: column-reverse;
    gap: 0;
  }

  .tournament-info__details section {
    font-size: 16px;
  }

  .tournament-actions__main-button {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 470px) {
  .tournament-info > section {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    display: flex;
  }

  .tournament-info__name {
    width: 50%;
    text-align: left;
    font-size: 20px;
  }

  .tournament-info__details--highlighted {
    flex-direction: column;
    align-items: start;
    gap: 0 !important;
  }

  .tournament-info__details--highlighted .separator {
    display: none;
  }
}

.card {
  max-width: 233px;
  min-height: 352px;
  background-color: #16183d;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 2px #0d3d8e00;
}

.card:hover {
  transition: box-shadow 1.25s;
  box-shadow: inset 0 0 0 2px #0d3d8e;
}

.card:hover .card-image {
  opacity: 1;
  transition: opacity 1.25s;
}

.card--is-locked:hover {
  box-shadow: none;
}

.card--is-locked:hover .card-image {
  opacity: .5;
}

.card--is-locked .card-image {
  mix-blend-mode: luminosity;
}

.card--is-locked .card-title {
  opacity: .3;
}

.card--is-claimed:hover {
  box-shadow: none;
}

.card--is-claimed .card-image {
  opacity: 1 !important;
}

.card--is-claimed .card-description {
  opacity: .3;
}

.card--is-compact {
  max-width: 137px;
  max-height: 163px;
  min-height: auto;
}

.card--is-compact:hover {
  box-shadow: none;
}

.card--is-compact:hover .card-image {
  opacity: .5;
}

.card--is-compact .card__icon-container {
  top: 10%;
  right: 10%;
}

.card--is-compact .card-image {
  width: 60px;
  height: 60px;
  opacity: .5;
  margin-top: 0;
  margin-bottom: 16px;
}

.card--is-compact .card-title {
  margin-top: 0;
  font-size: 14px;
  font-weight: 600;
}

.card--is-compact .card-subtitle {
  font-size: 19px;
  font-weight: 600;
}

.card__icon-container {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.card__icon-container--adjusted {
  color: #16183d;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card__icon-container--adjusted svg {
  width: 16px;
  height: 17px;
}

.card-image {
  opacity: .5;
  margin-top: 16px;
}

.card-title {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
}

.card-subtitle {
  color: #f7c903;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.card-description {
  margin-top: 20px;
  padding: 0 24px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.card__popup {
  display: none;
}

.card--is-compact.card--is-claimed .card-image {
  opacity: 1 !important;
}

@media (max-width: 1199px) {
  .card {
    min-height: auto;
    padding-bottom: 32px;
  }

  .card-image {
    width: 110px;
  }

  .card-description {
    margin-top: 10px;
    padding: 0;
  }
}

@media (max-width: 980px) {
  .card-description {
    padding: 0;
  }
}

@media (max-width: 899px) {
  .card-image {
    width: 90px;
  }
}

@media (max-width: 767px) {
  .card {
    min-height: 200px;
    padding-bottom: 16px;
  }

  .card--is-compact {
    min-height: auto;
    width: 78px;
    max-height: 111px;
    padding: 11px 6px;
  }

  .card--is-compact .card-image {
    width: 34px;
    height: 34px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .card--is-compact .card-title {
    font-size: 9px;
  }

  .card--is-compact .card-subtitle {
    font-size: 12px;
  }

  .card--is-compact .card-subtitle svg {
    font-size: 16px;
  }

  .card__icon-container {
    width: 24px !important;
    height: 24px !important;
  }

  .card__icon-container--adjusted svg {
    width: 12px;
    height: 13px;
  }

  .card-title {
    font-size: 14px;
  }

  .card-subtitle {
    font-size: 21px;
  }

  .card-description {
    display: none;
  }

  .card__popup--is-visible {
    width: 100%;
    height: 100%;
    z-index: 1200;
    background-color: #00000080;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  .card__popup-content {
    max-width: 233px;
    padding-bottom: 32px;
    animation: .25s ease-out fadeInFromNone;
    position: relative;
    transform: translate(-20px);
    box-shadow: inset 0 0 0 2px #0d3d8e;
    min-height: 352px !important;
  }

  .card__popup-content__close {
    z-index: 1;
    position: relative;
    top: -200px;
    left: 210px;
  }

  .card__popup-content .card-image {
    width: unset;
  }

  .card__popup-content .card-title {
    font-size: 18px;
  }

  .card__popup-content .card-subtitle {
    font-size: 24px;
  }

  .card__popup-content .card-description {
    display: flex;
  }
}

@media (max-width: 576px) {
  .card {
    min-height: auto;
  }

  .card-image {
    width: 80px;
  }
}

@media (max-width: 375px) {
  .card-image {
    width: 60px;
  }

  .card-subtitle {
    font-size: 19px;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
    display: flex;
  }

  1% {
    opacity: 0;
    display: flex;
  }

  100% {
    opacity: 1;
    display: flex;
  }
}

.challenge-island-info, .challenge-island-info--adjusted-position {
  width: 100%;
  z-index: 10;
  background-color: #25274a;
  border-radius: 16px;
  flex-direction: column;
  gap: 8px;
  padding: 30px;
  display: none;
  position: absolute;
}

.challenge-island-info:after, .challenge-island-info--adjusted-position:after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #25274a;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)rotate(45deg);
}

.challenge-island-info--adjusted-position {
  top: -15%;
}

.challenge-island-info__row {
  align-items: center;
  gap: 5px;
  display: flex;
}

.challenge-island-info .challenge-island-info__icon, .challenge-island-info--adjusted-position .challenge-island-info__icon {
  width: 18px;
  height: 18px;
}

.challenge-island-info .challenge-island-info__icon--is-completed, .challenge-island-info--adjusted-position .challenge-island-info__icon--is-completed {
  width: 22px;
  height: 22px;
  color: #f7c903;
}

.challenge-island-info__name {
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 899px) {
  .challenge-island-info, .challenge-island-info--adjusted-position {
    width: 225px;
    padding: 21px;
  }

  .challenge-island-info__name {
    font-size: 17px;
  }

  .challenge-island-info .challenge-island-info__button, .challenge-island-info--adjusted-position .challenge-island-info__button {
    font-size: 11px;
    height: 30px !important;
  }
}

.indicator {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.indicator:before {
  content: "";
  width: 150%;
  height: 150%;
  box-sizing: border-box;
  background-color: #cd0a2d;
  border-radius: 45px;
  margin-top: -55%;
  margin-left: -53%;
  animation: 1.25s cubic-bezier(.215, .61, .355, 1) infinite pulse-ring;
  display: block;
  position: relative;
}

.indicator:after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #ff002e;
  border-radius: 15px;
  animation: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0000004d;
}

.indicator--bottom {
  top: 75%;
}

.indicator--right {
  left: 75%;
}

.indicator--top-right {
  top: 25%;
  left: 75%;
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }

  80%, 100% {
    opacity: 0;
  }
}

.challenges-info {
  color: #fff;
  text-align: left;
  font-family: Poppins;
}

.challenges-info h5 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
}

.challenges-info__heading {
  margin-top: 0 !important;
}

.challenges-info__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
}

.challenges-info__text__read-more-button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 5px 0;
  font-family: Poppins;
  font-weight: 300;
  text-decoration: underline;
  display: none;
}

.challenges-info__tasks {
  flex-direction: column;
  display: flex;
}

.challenges-info__row {
  justify-content: space-between;
  display: flex;
}

.challenges-info__row-box {
  max-width: 137px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.challenges-info__row-box__title {
  text-align: center;
  opacity: .5;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
}

.challenges-info__row-arrow svg {
  position: relative;
  top: 50%;
  transform: translate(0, -100%);
}

.challenges-info__badge {
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.challenges-info__badge-text {
  opacity: .5;
  margin-top: 10px;
  font-size: 12px;
}

@media (max-width: 767px) {
  .challenges-info h5 {
    margin-top: 10px;
  }

  .challenges-info__text {
    max-height: 100px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .challenges-info__text--is-expanded {
    height: auto;
    max-height: 300px;
    transition: max-height .25s ease-in;
    display: flex;
  }

  .challenges-info__text__read-more-button {
    display: block;
  }

  .challenges-info__row .challenges-info__badge-image {
    width: 40px;
  }

  .challenges-info__row .challenges-info__badge-text {
    font-size: 9px;
  }

  .challenges-info__row-box {
    align-items: center;
  }

  .challenges-info__row-box__title {
    font-size: 9px;
  }

  .challenges-info__row-arrow svg {
    font-size: 15px;
    top: 50%;
    transform: translate(0, -100%);
  }
}

@media (max-width: 403px) {
  .challenges-info__row-arrow svg {
    top: 50%;
    transform: translate(0, -175%);
  }
}

.challenges {
  font-family: Poppins;
}

.challenges__islands {
  width: 100%;
  height: 100%;
  background-image: url("mainBackground.e0959e66.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 112px;
}

.challenges__islands-title {
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.challenges__islands-title button {
  width: 135px;
  color: #fff;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.challenges__islands-container {
  padding: 0 5%;
}

.challenges__islands-container-row {
  justify-content: center;
  display: flex;
}

.challenges__cards {
  gap: 16px;
  display: grid !important;
}

.challenge-island {
  position: relative;
}

.challenge-island-info {
  transform: translate(0, -20%);
}

.challenge-island:hover .challenge-island-info {
  animation: .5s ease-out fadeInPosition;
  display: flex;
}

@media (max-width: 899px) {
  .challenge-island {
    align-self: flex-start;
  }

  .challenge-island:nth-of-type(2n) {
    align-self: flex-end;
  }
}

@media (max-width: 768px) {
  .challenges__title {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0 !important;
  }
}

@media (min-width: 0) {
  .challenges__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .challenges__cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1200px) {
  .challenges__cards {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1620px) {
  .challenges__cards {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 1920px) {
  .challenges__cards {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media (min-width: 2160px) {
  .challenges__cards {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@keyframes fadeInPosition {
  0% {
    visibility: hidden;
    opacity: 0;
    -webkit-user-select: none;
    user-select: none;
    transform: translate(0, 5%);
  }

  1% {
    visibility: visible;
    opacity: 0;
    -webkit-user-select: none;
    user-select: none;
    transform: translate(0, 5%);
  }

  100% {
    visibility: visible;
    opacity: 1;
    user-select: initial;
    display: flex;
    transform: translate(0, -20%);
  }
}

.exchange-slider {
  width: 100%;
  background: #16183d;
  border-radius: 32px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  display: flex;
}

.exchange-slider__slider {
  width: 100%;
  padding-left: 10px;
}

.exchange-slider__title {
  color: var(--Yellow-text, #f7c903);
  padding-bottom: 10px;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.exchange-slider__values {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.exchange-slider__values-current {
  color: #fff;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.exchange-slider__values-current-img {
  width: 40px;
  height: 40px;
}

.exchange-slider__values-max {
  color: #fff;
  cursor: pointer;
  background: #ffffff0d;
  border-radius: 14px;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
}

.exchange-slider__values-max:hover {
  background-color: #ffffff26;
}

.exchange-slider__legend {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  padding-top: 30px;
  display: flex;
}

.exchange-slider__legend-rate {
  align-items: center;
  gap: 5px;
  display: flex;
}

.exchange-slider__legend-rate-text {
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.exchange-slider__legend-rate-icon {
  color: #ffd000;
  width: 16px !important;
  height: 16px !important;
}

.exchange-slider__legend-text {
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.custom-slider .MuiSlider-thumb {
  height: 20px;
  width: 20px;
  background: #fff;
}

.custom-slider .MuiSlider-track {
  height: 15px;
  border: none;
}

.custom-slider .MuiSlider-rail {
  height: 12px;
  background-color: #ffffff1a;
  box-shadow: inset 0 0 4px -2px #000;
}

.custom-slider .MuiSlider-mark {
  width: 1px;
  height: 8px;
  opacity: .2;
  background: #fff;
}

@media (min-width: 768px) {
  .exchange-slider {
    padding: 30px;
  }

  .exchange-slider__title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .exchange-slider__title {
    font-size: 31px;
  }
}

.summary {
  width: 100%;
  background: #16183d;
  border-radius: 32px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  padding: 20px;
  display: flex;
}

.summary__title {
  color: #f7c903;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.summary__row {
  width: 100%;
  justify-content: space-between;
  line-height: 130%;
  display: flex;
}

.summary__type {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.summary__amount {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.summary__total {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.summary__price {
  color: #fff;
  align-items: center;
  gap: 5px;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.summary__price-icon {
  width: 20px;
  height: 20px;
  color: #ffd000;
}

.summary__action {
  width: 100%;
  margin-top: auto;
}

.summary__action-button:disabled {
  opacity: .3 !important;
  background: #fff !important;
}

@media (min-width: 768px) {
  .summary {
    padding: 30px;
  }

  .summary__title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .summary__title {
    font-size: 31px;
  }
}

.shop-banner {
  height: 330px;
  box-sizing: border-box;
  background: linear-gradient(90deg, #0b0957 0%, #4c1895 100%) no-repeat;
  border-radius: 32px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding: 30px 50px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.shop-banner:after {
  content: "";
  width: 600px;
  min-height: 400px;
  height: 100%;
  background: url("shopBackground.068bbe00.svg") 0 0 / cover no-repeat;
  border-radius: 32px;
  position: absolute;
  top: -35px;
  right: 0;
}

.shop-banner__content {
  z-index: 1;
  width: 1200px;
  height: 330px;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 30px;
  font-family: Poppins;
  display: flex;
}

.shop-banner__content-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 140%;
}

.shop-banner__content-message {
  max-width: 350px;
  font-size: 19px;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .shop-banner {
    width: auto;
    height: 272px;
    padding: 60px 30px 30px;
  }

  .shop-banner:after {
    width: 410px;
    min-height: 272px;
    top: 0;
    right: -90px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .shop-banner {
    width: auto;
    height: 238px;
    padding: 70px 20px 30px;
  }

  .shop-banner__content .group-container {
    display: none;
  }

  .shop-banner__content-message {
    margin-bottom: 0;
  }

  .shop-banner:after {
    width: 410px;
    min-height: 272px;
    top: 0;
    right: -10px;
  }

  .shop-banner__content {
    gap: 10px;
  }

  .shop-banner__content-title {
    font-size: 39px;
  }

  .shop-banner__content-message {
    width: 240px;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 575px) {
  .shop-banner {
    width: 100%;
    height: 238px;
    padding: 90px 20px 30px;
  }

  .shop-banner:after {
    width: 65%;
    height: 65%;
    background-position: 100% 0;
    background-size: contain;
    top: 0;
    right: 0;
  }

  .shop-banner__content-title {
    font-size: 39px;
  }

  .shop-banner__content-message {
    width: 240px;
    font-size: 14px;
    font-weight: 400;
  }

  .shop-banner__content .group-container {
    display: none;
  }

  .shop-banner__content-message {
    margin-bottom: 0;
  }
}

.free-spins-item {
  min-height: 80px;
  background-color: #25274a;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  display: flex;
}

.free-spins-item:hover {
  cursor: pointer;
}

.free-spins-item--is-selected {
  color: #031e44;
  background-color: #f7c903;
  transition: background-color .5s linear;
}

.free-spins__prizes {
  height: 100%;
  background-color: #16183d;
  border-radius: 32px;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  display: flex;
}

.free-spins__prizes__row {
  flex-direction: column;
  display: flex;
}

.free-spins__prizes__list {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  display: grid;
}

.free-spins__prizes__title {
  color: var(--Yellow-text, #f7c903);
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.free-spins__prizes__controls {
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

.free-spins__prizes__controls-title, .free-spins__prizes__controls-value {
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1199px) {
  .free-spins__prizes {
    padding: 20px;
  }

  .free-spins__prizes__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .free-spins__prizes {
    padding: 20px;
  }

  .free-spins__prizes__list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .free-spins__prizes__controls {
    margin-top: 56px;
  }
}

@media screen and (max-width: 576px) {
  .free-spins__prizes__list {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (max-width: 375px) {
  .free-spins__prizes__list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.free-spins {
  margin-bottom: 0;
}

.free-spins__games {
  background-color: #16183d;
  border-radius: 32px;
  flex-direction: column;
  padding: 30px;
  display: flex;
}

.free-spins__games__title {
  color: var(--Yellow-text, #f7c903);
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.free-spins__games-container {
  flex-wrap: wrap;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
  display: grid;
}

.free-spins__games-container__game {
  cursor: pointer;
  border: 1px solid #0d3d8e;
  border-radius: 14px;
  transition: border-color .5s;
  position: relative;
  overflow: hidden;
}

.free-spins__games-container__game:hover, .free-spins__games-container__game--is-selected {
  border-color: #f7c903;
}

.free-spins__games-container__game--is-selected .free-spins__games-container__game-image {
  filter: blur(5px);
  transition: filter .5s;
}

.free-spins__games-container__game--is-selected .free-spins__games-container__game-overlay {
  display: flex;
}

.free-spins__games-container__game-image {
  width: 100%;
  height: 100%;
}

.free-spins__games-container__game-overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.free-spins__games-container__game-overlay > button svg {
  color: #ffd000;
  font-size: 35px;
}

@media screen and (max-width: 1199px) {
  .free-spins__games {
    padding: 20px;
  }

  .free-spins__games__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .free-spins__games-container {
    gap: 13px;
  }
}

@media screen and (max-width: 767px) {
  .free-spins__games__title {
    font-size: 21px;
  }
}

@media screen and (max-width: 576px) {
  .free-spins__games-container {
    gap: 9px;
  }
}

@media screen and (max-width: 375px) {
  .free-spins__games-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 9px;
  }
}

.level-meter {
  display: flex;
}

.level-meter__level {
  opacity: .5;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  display: flex;
  position: relative;
}

.level-meter__level:hover {
  cursor: pointer;
}

.level-meter__level--is-selected {
  color: #f7c903;
  opacity: 1;
  transition: opacity .25s ease-in;
}

.level-meter__level--is-selected:after {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: #333657;
  position: absolute;
  bottom: -20px;
  transform: rotate(45deg);
}

.level-meter__level--is-selected .level-meter__level-text {
  font-weight: 700;
}

.level-meter__level-current {
  width: 26px;
  height: 26px;
  color: #f7c903;
  position: absolute;
  top: -7px;
  right: 5px;
}

.level-meter__level-current svg {
  font-size: 26px;
}

.level-meter__level-image {
  width: 60px;
  height: 60px;
}

.level-meter__level-text {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.level-meter__info {
  width: 100%;
  background: #333657;
  border-radius: 8px;
  flex-direction: column;
  gap: 20px;
  margin: 15px 0 30px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
}

.level-meter__info-row {
  align-items: center;
  gap: 10px;
  display: flex;
}

.level-meter__info-row span {
  align-items: center;
  display: flex;
}

.level-meter__info-row-icon {
  color: #f7c903;
  margin-left: 5px;
}

.level-meter__info-row-icon svg {
  font-size: 20px;
}

@media (max-width: 768px) {
  .level-meter__level--is-selected .level-meter__level-text {
    font-weight: 500;
  }

  .level-meter__level-current {
    width: 20px;
    height: 20px;
    top: -5px;
    right: -5px;
  }

  .level-meter__level-current svg {
    font-size: 20px;
  }

  .level-meter__level-image {
    width: 40px;
    height: 40px;
  }

  .level-meter__level-text {
    font-size: 9px;
  }
}

.shop-page__info-box {
  color: #fff;
  font-family: Poppins;
}

.shop-page__info-box__title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
}

.shop-page__info-box__text, .shop-page__info-box__text--clamped {
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
}

.shop-page__info-box__text--clamped {
  -webkit-line-clamp: 2;
  max-height: 36px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.shop-page__info-box__text--is-expanded {
  max-height: 500px;
  height: auto;
  transition: max-height .25s ease-in;
  display: flex;
}

.shop-page__info-box__read-more-button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin-bottom: 20px;
  padding: 5px 0;
  font-family: Poppins;
  font-weight: 300;
  text-decoration: underline;
}

.shop-page__info-box__slider {
  height: 100px;
  background-image: url("slider.696ffe7c.svg");
  background-position-y: 25px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 10px 0 20px;
}

.shop-page__info-box__slider-icon {
  animation: 4s ease-in-out .5s infinite backwards popup;
}

.shop-page__info-box__free-spins {
  justify-content: space-between;
  margin: 15px 0 30px;
  display: flex;
}

.shop-page__info-box__free-spins-box, .shop-page__info-box__free-spins-box--wide {
  width: 184px;
  height: 184px;
  background-image: url("selectQuantityBox.e5fc97e1.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  justify-content: center;
  align-items: end;
  font-size: 12px;
  font-weight: 500;
  display: flex;
}

.shop-page__info-box__free-spins-box--wide {
  width: 256px;
  background-image: url("selectGameBox.b0074735.svg");
}

.shop-page__info-box__free-spins-separator {
  align-items: center;
  display: flex;
  transform: translate(0, -20px);
}

@media (max-width: 768px) {
  .shop-page__info-box__slider {
    height: 65px;
  }

  .shop-page__info-box__free-spins {
    margin: 15px 0 0;
  }

  .shop-page__info-box__free-spins-box, .shop-page__info-box__free-spins-box--wide {
    height: 125px;
  }
}

@keyframes popup {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(40px);
  }

  35% {
    transform: translate(35px);
  }

  50% {
    transform: translate(35px);
  }

  75% {
    transform: translate(35px);
  }

  80% {
    transform: translate(35px);
  }

  85% {
    transform: translate(-6px);
  }

  90% {
    transform: translate(0);
  }

  100% {
    transform: translate(0);
  }
}

.inline-loader {
  justify-content: center;
  display: flex;
}

.empty-bonus-section {
  flex-flow: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

.empty-bonus-section__save {
  height: 52px;
  width: 52px;
  background: #0d1365;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0 5px #2575fc80;
}

.empty-bonus-section__text {
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.suggested-bonus {
  flex-flow: column;
  gap: 16px;
  display: flex;
}

.suggested-bonus__description {
  color: #fff;
  background: #ffffff1a;
  border: 1px solid #fff;
  border-radius: 8px;
  flex-flow: column;
  gap: 4px;
  display: flex;
}

.suggested-bonus__description__code {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.suggested-bonus__description__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.landing-page {
  min-height: 100vh;
  background-color: #0d0f36;
}

.landing-page-logo {
  width: 300px;
  cursor: pointer;
}

.landing-page-content {
  width: 100%;
  min-height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  gap: 30px;
  padding-top: 40px;
  display: flex;
  position: relative;
}

.landing-page-content:before {
  content: "";
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 92.08%, 2% 90.88%, 4% 89.68%, 6% 88.49%, 8% 87.33%, 10% 86.21%, 12% 85.17%, 14% 84.2%, 16% 83.34%, 18% 82.58%, 20% 81.95%, 22% 81.46%, 24% 81.1%, 26% 80.89%, 28% 80.83%, 30% 80.92%, 32% 81.17%, 34% 81.55%, 36% 82.08%, 38% 82.74%, 40% 83.52%, 42% 84.41%, 44% 85.39%, 46% 86.46%, 48% 87.58%, 50% 88.75%, 52% 89.95%, 54% 91.15%, 56% 92.34%, 58% 93.5%, 60% 94.62%, 62% 95.66%, 64% 96.63%, 66% 97.49%, 68% 98.25%, 70% 98.88%, 72% 99.38%, 74% 99.73%, 76% 99.94%, 78% 100%, 80% 99.91%, 82% 99.66%, 84% 99.28%, 86% 98.75%, 88% 98.09%, 90% 97.31%, 92% 96.42%, 94% 95.44%, 96% 94.37%, 98% 93.25%, 100% 92.08%);
  clip-path: polygon(100% 100%, 0% 100%, 0% 92.08%, 2% 90.88%, 4% 89.68%, 6% 88.49%, 8% 87.33%, 10% 86.21%, 12% 85.17%, 14% 84.2%, 16% 83.34%, 18% 82.58%, 20% 81.95%, 22% 81.46%, 24% 81.1%, 26% 80.89%, 28% 80.83%, 30% 80.92%, 32% 81.17%, 34% 81.55%, 36% 82.08%, 38% 82.74%, 40% 83.52%, 42% 84.41%, 44% 85.39%, 46% 86.46%, 48% 87.58%, 50% 88.75%, 52% 89.95%, 54% 91.15%, 56% 92.34%, 58% 93.5%, 60% 94.62%, 62% 95.66%, 64% 96.63%, 66% 97.49%, 68% 98.25%, 70% 98.88%, 72% 99.38%, 74% 99.73%, 76% 99.94%, 78% 100%, 80% 99.91%, 82% 99.66%, 84% 99.28%, 86% 98.75%, 88% 98.09%, 90% 97.31%, 92% 96.42%, 94% 95.44%, 96% 94.37%, 98% 93.25%, 100% 92.08%);
  background: #0d0f36;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page-content-section {
  justify-content: center;
  display: flex;
}

.landing-page-content-section__column {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.landing-page-content-section__column-text {
  flex-direction: column;
  margin-left: 15%;
  display: flex;
}

.landing-page-content-section__column-text--align-left {
  align-items: start;
}

.landing-page-content-section__column-text--align-center {
  align-items: center;
}

.landing-page-content-section__column-text__line {
  white-space: nowrap;
  z-index: 3;
  justify-content: flex-start;
  line-height: 100%;
  display: flex;
}

.landing-page-content-section__column-text__line--font-size-small {
  font-size: 2em;
}

.landing-page-content-section__column-text__line--font-size-medium {
  font-size: 4em;
  line-height: 115%;
}

.landing-page-content-section__column-text__line--font-size-large {
  font-size: 6em;
}

.landing-page-content-section__column-text__button-container {
  justify-content: flex-start;
  padding-top: 13px;
  display: flex;
}

.landing-page-content-section__column-text__button-container .landing-page-content-section__column-text__button {
  height: 54px;
  width: 184px;
  color: #031e44;
  background: var(--yellow-gradient);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.landing-page-content-section__column-text__button-container .landing-page-content-section__column-text__button:hover {
  background: #fff;
  border: none;
  box-shadow: 0 0 10px #ffd41b80;
}

.landing-page-steps {
  width: 100%;
  border-radius: 32px;
  margin-top: 20px;
  padding: 0 15%;
  font-family: Poppins;
  display: flex;
  overflow: hidden;
}

.landing-page-steps-step {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #16183d99;
  flex: 1;
  align-items: center;
  gap: 20px;
  padding: 11px 0;
  display: flex;
}

.landing-page-steps-step:before {
  content: "";
  width: 1px;
  height: 50px;
  opacity: .1;
  background-color: #fff;
  display: flex;
}

.landing-page-steps-step:first-of-type {
  border-radius: 32px 0 0 32px;
}

.landing-page-steps-step:first-of-type:before {
  content: none;
}

.landing-page-steps-step:last-of-type {
  border-right: none;
  border-radius: 0 32px 32px 0;
}

.landing-page-steps-step-order {
  color: #f7c903;
  align-items: center;
  padding-left: 60px;
  font-size: 72px;
  font-weight: 700;
  display: flex;
}

.landing-page-steps-step-text {
  align-items: center;
  font-weight: 500;
  display: flex;
}

.landing-page-terms-conditions {
  text-align: left;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
}

.landing-page-terms-conditions-button {
  color: #fff;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  gap: 5px;
  margin-top: 10px;
  padding: 0;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  text-decoration: underline;
  display: flex;
}

.landing-page-footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-top: 40px;
  padding-bottom: 40px;
  display: flex;
}

.landing-page-footer > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.landing-page-footer-legal-age {
  width: 48px;
  height: 48px;
  color: #fff;
  border: 2px solid #2575fc;
  border-radius: 100%;
  padding-left: 3px;
  font-family: Poppins;
  font-size: 18px;
}

@media (min-width: 0) {
  .landing-page-logo {
    width: 172px;
  }

  .landing-page-content {
    min-height: 670px;
  }

  .landing-page-content:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 76.81%, 6.67% 77.2%, 13.33% 78.05%, 20% 79.28%, 26.67% 80.82%, 33.33% 82.58%, 40% 84.45%, 46.67% 86.31%, 53.33% 88.04%, 60% 89.53%, 66.67% 90.69%, 73.33% 91.45%, 80% 91.75%, 86.67% 91.59%, 93.33% 90.97%, 100% 89.92%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 76.81%, 6.67% 77.2%, 13.33% 78.05%, 20% 79.28%, 26.67% 80.82%, 33.33% 82.58%, 40% 84.45%, 46.67% 86.31%, 53.33% 88.04%, 60% 89.53%, 66.67% 90.69%, 73.33% 91.45%, 80% 91.75%, 86.67% 91.59%, 93.33% 90.97%, 100% 89.92%);
  }

  .landing-page-content-section {
    flex-direction: column;
  }

  .landing-page-content-section__logo {
    align-items: center;
  }

  .landing-page-content-section__column {
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .landing-page-content-section__column-text__line {
    width: 100%;
    justify-content: center;
  }

  .landing-page-content-section__column-text__line--font-size-small {
    font-size: 1em;
  }

  .landing-page-content-section__column-text__line--font-size-medium {
    font-size: 1.5em;
    line-height: 115%;
  }

  .landing-page-content-section__column-text__line--font-size-large {
    font-size: 2em;
  }

  .landing-page-content-section__column-text__button-container {
    width: 100%;
    margin-top: 15px;
    padding: 0 15px;
    display: flex;
  }

  .landing-page-content-section__column-text__button {
    flex: 1;
    font-size: 1em;
  }

  .landing-page-steps {
    flex-direction: column;
    margin-top: 0;
    padding: 0 30px;
  }

  .landing-page-steps-step {
    flex-direction: column;
    gap: 0;
    position: relative;
  }

  .landing-page-steps-step:before {
    content: "";
    width: 50px;
    height: 1px;
    display: flex;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
  }

  .landing-page-steps-step:first-of-type {
    border-radius: 32px 32px 0 0;
  }

  .landing-page-steps-step:last-of-type {
    border-radius: 0 0 32px 32px;
  }

  .landing-page-steps-step-order {
    padding: 0;
    font-size: 58px;
  }

  .landing-page-steps-step-text {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .landing-page-terms-conditions-button {
    font-size: 16px;
  }

  .landing-page-footer {
    flex-wrap: wrap;
    gap: 50px;
  }
}

@media (min-width: 576px) {
  .landing-page-logo {
    width: 240px;
  }

  .landing-page-content-section__column-text__line--font-size-large {
    font-size: 4em;
  }

  .landing-page-content-section__column-text__button-container {
    justify-content: center;
  }

  .landing-page-content-section__column-text__button {
    max-width: 350px;
  }

  .landing-page-steps {
    margin-top: 40px;
  }

  .landing-page-steps-step-text {
    font-size: 24px;
  }
}

@media (min-width: 767px) {
  .landing-page-content {
    min-height: 540px;
  }

  .landing-page-content:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
  }

  .landing-page-content-section {
    flex-direction: row;
  }

  .landing-page-content-section__column-text--align-left {
    align-items: start;
  }

  .landing-page-content-section__column-text--align-center {
    align-items: center;
  }

  .landing-page-content-section__column-text__line {
    justify-content: start;
    padding-left: 40px;
  }

  .landing-page-content-section__column-text__line--font-size-large {
    font-size: 4em;
  }

  .landing-page-content-section__column-text__button-container {
    justify-content: start;
    padding: 0;
  }

  .landing-page-content-section__column-text__button {
    max-width: 200px;
  }

  .landing-page-steps {
    padding: 0 20%;
  }

  .landing-page-steps-step-text {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .landing-page-content {
    min-height: 540px;
  }

  .landing-page-content:before {
    -webkit-clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
    clip-path: polygon(100% 100%, 0% 100%, 0% 91.52%, 2% 90.73%, 4% 89.93%, 6% 89.13%, 8% 88.36%, 10% 87.61%, 12% 86.92%, 14% 86.27%, 16% 85.7%, 18% 85.19%, 20% 84.77%, 22% 84.44%, 24% 84.2%, 26% 84.07%, 28% 84.03%, 30% 84.09%, 32% 84.25%, 34% 84.51%, 36% 84.86%, 38% 85.3%, 40% 85.82%, 42% 86.41%, 44% 87.07%, 46% 87.78%, 48% 88.53%, 50% 89.31%, 52% 90.1%, 54% 90.91%, 56% 91.7%, 58% 92.47%, 60% 93.22%, 62% 93.91%, 64% 94.56%, 66% 95.13%, 68% 95.64%, 70% 96.06%, 72% 96.39%, 74% 96.63%, 76% 96.77%, 78% 96.8%, 80% 96.74%, 82% 96.58%, 84% 96.32%, 86% 95.97%, 88% 95.53%, 90% 95.01%, 92% 94.42%, 94% 93.76%, 96% 93.05%, 98% 92.3%, 100% 91.52%);
  }

  .landing-page-content-section {
    flex-direction: row;
  }

  .landing-page-content-section__column-text--align-left {
    align-items: start;
  }

  .landing-page-content-section__column-text--align-center {
    align-items: center;
  }

  .landing-page-content-section__column-text__line {
    justify-content: start;
    padding-left: 40px;
  }

  .landing-page-content-section__column-text__line--font-size-small {
    font-size: 1.5em;
  }

  .landing-page-content-section__column-text__line--font-size-medium {
    font-size: 2em;
  }

  .landing-page-content-section__column-text__line--font-size-large {
    font-size: 5em;
  }

  .landing-page-content-section__column-text__button-container {
    justify-content: start;
    padding: 0;
  }

  .landing-page-content-section__column-text__button {
    max-width: 272px;
  }

  .landing-page-steps {
    flex-direction: row;
    padding: 0 15px;
  }

  .landing-page-steps-step {
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    background-color: #16183d99;
    flex-direction: row;
    flex: 1;
    align-items: center;
    gap: 20px;
    padding: 11px 0;
    display: flex;
  }

  .landing-page-steps-step:before {
    content: "";
    position: unset;
    top: unset;
    right: unset;
    width: 1px;
    height: 50px;
    opacity: .1;
    background-color: #fff;
    display: flex;
    transform: translate(0);
  }

  .landing-page-steps-step:first-of-type {
    border-radius: 32px 0 0 32px;
  }

  .landing-page-steps-step:first-of-type:before {
    content: none;
  }

  .landing-page-steps-step:last-of-type {
    border-right: none;
    border-radius: 0 32px 32px 0;
  }

  .landing-page-steps-step-order {
    color: #f7c903;
    align-items: center;
    padding-left: 40px;
    font-size: 58px;
    font-weight: 700;
    display: flex;
  }

  .landing-page-steps-step-text {
    text-align: left;
    align-items: center;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: 500;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .landing-page-content {
    min-height: 560px;
  }

  .landing-page-content-section__column-text__line {
    padding-left: 115px;
  }

  .landing-page-steps {
    padding: 0 115px;
  }
}

@media (min-width: 1920px) {
  .landing-page-logo {
    width: 300px;
  }

  .landing-page-content {
    min-height: 830px;
  }

  .landing-page-content-section__column-text__line {
    padding-left: 215px;
  }

  .landing-page-content-section__column-text__line--font-size-small {
    font-size: 2em;
    line-height: 115%;
  }

  .landing-page-content-section__column-text__line--font-size-medium {
    font-size: 4em;
  }

  .landing-page-content-section__column-text__line--font-size-large {
    font-size: 6.5em;
  }

  .landing-page-steps {
    padding: 0 215px;
  }

  .landing-page-steps-step-order {
    padding-left: 50px;
    font-size: 72px;
  }

  .landing-page-steps-step-text {
    font-size: 24px;
  }

  .landing-page-terms-conditions-button {
    font-size: 19px;
  }
}

.landing-page-not-found {
  height: 100vh;
  text-align: center;
  color: #fff;
  background-color: #0d0f36;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-login {
  height: 100vh;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  display: grid;
}

.main-login__content-box {
  flex-flow: column;
  grid-column: span 12;
  display: flex;
}

@media (min-width: 960px) {
  .main-login__content-box {
    grid-column: span 8;
  }
}

.main-login__content-box__inner-content {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.main-login__content-box__inner-content__container {
  max-width: 616px;
  width: 100%;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container {
    min-width: 616px;
  }
}

.main-login__content-box__inner-content__container__info-text-container {
  color: var(--main-color);
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container__info-text-container {
    margin-left: 4rem;
    margin-right: 4rem;
    font-size: 12px;
  }
}

.main-login__content-box__inner-content__container__email-inputs-container {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container__email-inputs-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.main-login__content-box__inner-content__container__signin-button-container {
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container__signin-button-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.main-login__content-box__inner-content__container__signin-button-container__custom-signin-button {
  color: var(--main-color) !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

.main-login__content-box__inner-content__container__signin-button-container__custom-signin-button:hover {
  background: #fff !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

.main-login__content-box__inner-content__container__check-box-container {
  width: auto;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container__check-box-container {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

.main-login__content-box__inner-content__container__create-an-account {
  width: auto;
  color: #838c9a;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: grid;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container__create-an-account {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.main-login__content-box__inner-content__container__create-an-account__divider-span-5 {
  grid-column: span 5;
}

.main-login__content-box__inner-content__container__create-an-account__or {
  text-align: center;
  font-family: Poppins;
  position: relative;
  top: -9px;
}

.main-login__content-box__inner-content__container__create-an-account__divider-span-6 {
  grid-column: span 6;
}

.main-login__content-box__inner-content__container__dont-have-account-text {
  width: auto;
  color: #838c9a;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.main-login__content-box__inner-content__container__create-account-button-container {
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (min-width: 960px) {
  .main-login__content-box__inner-content__container__create-account-button-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.main-login__content-box__inner-content__container__create-account-button-container__custom-signin-button {
  color: #031e44 !important;
  border: 2px solid #2575fc !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  box-shadow: 0 0 5px #2575fc80 !important;
}

.main-login__content-box__inner-content__container__create-account-button-container__custom-signin-button:hover {
  background: #fff !important;
  border: none !important;
  box-shadow: 0 0 5px #2575fc80 !important;
}

.main-login__background-box {
  background-image: url("login.8e5384dc.svg");
  background-repeat: no-repeat;
  background-color: var(--main-color);
  background-position: center;
  background-size: cover;
  display: none;
}

@media (min-width: 960px) {
  .main-login__background-box {
    grid-column: span 4;
    display: block;
  }
}

.check-box-remember {
  color: #838c9a;
  font-weight: 400;
}

.check-box-remember .MuiFormControlLabel-label {
  font-size: 14px !important;
}

@media (min-width: 960px) {
  .check-box-remember .MuiFormControlLabel-label {
    font-size: 16px !important;
  }
}

.forgot-password-link-button {
  cursor: pointer !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}

@media (min-width: 960px) {
  .forgot-password-link-button {
    font-size: 16px !important;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating {
  -ms-animation: rotating 3s linear infinite;
  animation: 3s linear infinite rotating;
}

.fixed-button {
  height: 52px;
  width: 52px;
  z-index: 20;
  cursor: pointer;
  background: radial-gradient(circle at 100px 100px, #5cabff, #2d43a6);
  border: none;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  transition-duration: .3s;
  display: flex;
  position: fixed;
  bottom: 20px;
}

.fixed-button:hover {
  transform: scale(1.1);
}

.access-container {
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background: url("background.4a317e5a.png") center / cover no-repeat fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.content-container {
  width: 90%;
  max-width: 760px;
  height: 440px;
  background: #ffffff14;
  border: 2px solid #ffffff26;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 50px;
  display: flex;
}

.content-container__logo {
  height: 54px;
}

.content-container__code {
  height: 165px;
  align-items: center;
  gap: -10px;
  display: flex;
}

.content-container__code__bubble {
  z-index: 1;
  height: 100%;
  margin-right: -15px;
}

.content-container__code__num {
  color: #091344;
  text-shadow: -1px -1px 1px #011024;
  margin-right: -10px;
  font-size: 155px;
  font-weight: bolder;
}

.content-container__text {
  color: var(--white, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

@media screen and (max-width: 768px) {
  .content-container {
    padding: 50px 30px;
  }

  .content-container__logo {
    height: 36px;
  }

  .content-container__code {
    height: 104px;
  }

  .content-container__code__num {
    font-size: 94px;
  }

  .content-container__text {
    font-size: 14px;
  }
}

.confirmation-box {
  width: 355px;
  min-height: 240px;
  background: #fff;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 30px 20px;
  display: flex;
  border-radius: 30px !important;
}

.confirmation-box__close-button {
  z-index: 120;
  position: absolute;
  top: 5px;
  right: 10px;
}

.confirmation-box__content {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
}

.confirmation-box__content__icon {
  width: 36px;
  height: 36px;
}

.confirmation-box__content__title {
  color: #212121;
  text-align: center;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.confirmation-box__content__text {
  color: #212121;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.confirmation-box__button-container {
  width: 100%;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.campaign-popup {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #00000040;
  position: absolute;
  top: 0;
  left: 0;
}

.campaign-popup .iframe-container {
  max-width: 650px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-group {
  height: 40px;
  color: #fff !important;
  background: #2575fc !important;
  border-radius: 60px !important;
}

.button-group__option {
  font-weight: 600;
  color: #fff !important;
  height: 100% !important;
  min-width: 100px !important;
  border-radius: 100px !important;
  font-size: 14px !important;
}

.button-group__option--active {
  min-width: 100px !important;
  color: #fff !important;
  height: 100% !important;
  background: #25274ae6 !important;
  border: none !important;
  border-radius: 60px !important;
  font-size: 14px !important;
}

.button-group__option:hover {
  background-color: #25274ae6 !important;
}

@media (max-width: 576px) {
  .button-group__option {
    padding: 8px !important;
    font-size: 12px !important;
    line-height: 32px !important;
  }
}

.forgot-password-box {
  justify-content: flex-end;
  display: flex;
}

.signin-button-container {
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (min-width: 960px) {
  .signin-button-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.signin-button-container__custom-signin-button {
  color: var(--main-color) !important;
  background: linear-gradient(90deg, #e3ab00 0%, #ffd000 50%, #ffe539 100%) !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  box-shadow: 0 4px 4px #00000040 !important;
}

.signin-button-container__custom-signin-button:hover {
  background: #fff !important;
  box-shadow: 0 0 10px #ffd41b80 !important;
}

.create-account-button-container {
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
}

@media (min-width: 960px) {
  .create-account-button-container {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

.create-account-button-container__custom-signin-button {
  color: #031e44 !important;
  border: 2px solid #2575fc !important;
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  box-shadow: 0 0 5px #2575fc80 !important;
}

.create-account-button-container__custom-signin-button:hover {
  background: #fff !important;
  border: none !important;
  box-shadow: 0 0 5px #2575fc80 !important;
}

.mui-dialog-login {
  width: 450px !important;
  flex-flow: column !important;
  gap: 14px !important;
  margin: 0 !important;
  padding: 24px !important;
  display: flex !important;
}

.signin-title {
  color: var(--main-color);
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
  display: flex;
}

.close-icon-button {
  color: #9e9e9e !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.info-text {
  color: var(--main-color);
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
}

@media (min-width: 960px) {
  .info-text {
    font-size: 12px;
  }
}

/*# sourceMappingURL=index.1329d7d7.css.map */
