.banner {
  &__grid-container {
    display: flex;
  }

  &__grid {
    align-items: center;
    background-size: cover;
    background-position: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0d0f36;
      clip-path: polygon(
        100% 100%,
        0% 100%,
        0% 92.08%,
        2% 90.88%,
        4% 89.68%,
        6% 88.49%,
        8% 87.33%,
        10% 86.21%,
        12% 85.17%,
        14% 84.2%,
        16% 83.34%,
        18% 82.58%,
        20% 81.95%,
        22% 81.46%,
        24% 81.1%,
        26% 80.89%,
        28% 80.83%,
        30% 80.92%,
        32% 81.17%,
        34% 81.55%,
        36% 82.08%,
        38% 82.74%,
        40% 83.52%,
        42% 84.41%,
        44% 85.39%,
        46% 86.46%,
        48% 87.58%,
        50% 88.75%,
        52% 89.95%,
        54% 91.15%,
        56% 92.34%,
        58% 93.5%,
        60% 94.62%,
        62% 95.66%,
        64% 96.63%,
        66% 97.49%,
        68% 98.25%,
        70% 98.88%,
        72% 99.38%,
        74% 99.73%,
        76% 99.94%,
        78% 100%,
        80% 99.91%,
        82% 99.66%,
        84% 99.28%,
        86% 98.75%,
        88% 98.09%,
        90% 97.31%,
        92% 96.42%,
        94% 95.44%,
        96% 94.37%,
        98% 93.25%,
        100% 92.08%
      );
    }

    &-item {
      &--right {
        height: 100%;

        .spine-container {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-item-image {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      z-index: 1;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    padding-left: 15%;

    &--align-left {
      align-items: left;
    }

    &--align-center {
      align-items: center;
    }

    &__line {
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
      line-height: 125%;
      z-index: 3;

      &--font-size-small {
        font-size: 2em;
      }

      &--font-size-medium {
        font-size: 4em;
      }

      &--font-size-large {
        font-size: 6em;
      }
    }

    &__button-container {
      display: flex;
      justify-content: flex-start;
      padding-top: 13px;

      .banner-text__button {
        height: 54px;
        width: 184px;
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 22px;
        color: #031e44;
        background: var(--yellow-gradient);

        &:hover {
          background: #fff;
          box-shadow: 0px 0px 10px rgba(255, 212, 27, 0.5);
          border: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .banner {
    &__grid {
      &::before {
        clip-path: polygon(
          100% 100%,
          0% 100%,
          0% 91.52%,
          2% 90.73%,
          4% 89.93%,
          6% 89.13%,
          8% 88.36%,
          10% 87.61%,
          12% 86.92%,
          14% 86.27%,
          16% 85.7%,
          18% 85.19%,
          20% 84.77%,
          22% 84.44%,
          24% 84.2%,
          26% 84.07%,
          28% 84.03%,
          30% 84.09%,
          32% 84.25%,
          34% 84.51%,
          36% 84.86%,
          38% 85.3%,
          40% 85.82%,
          42% 86.41%,
          44% 87.07%,
          46% 87.78%,
          48% 88.53%,
          50% 89.31%,
          52% 90.1%,
          54% 90.91%,
          56% 91.7%,
          58% 92.47%,
          60% 93.22%,
          62% 93.91%,
          64% 94.56%,
          66% 95.13%,
          68% 95.64%,
          70% 96.06%,
          72% 96.39%,
          74% 96.63%,
          76% 96.77%,
          78% 96.8%,
          80% 96.74%,
          82% 96.58%,
          84% 96.32%,
          86% 95.97%,
          88% 95.53%,
          90% 95.01%,
          92% 94.42%,
          94% 93.76%,
          96% 93.05%,
          98% 92.3%,
          100% 91.52%
        );
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .banner-text {
    &__line {
      &--font-size-small {
        font-size: 1.75em;
      }

      &--font-size-medium {
        font-size: 2.5em;
      }

      &--font-size-large {
        font-size: 5em;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .banner {
    &__grid {
      &::before {
        clip-path: polygon(
          100% 100%,
          0% 100%,
          0% 91.52%,
          2% 90.73%,
          4% 89.93%,
          6% 89.13%,
          8% 88.36%,
          10% 87.61%,
          12% 86.92%,
          14% 86.27%,
          16% 85.7%,
          18% 85.19%,
          20% 84.77%,
          22% 84.44%,
          24% 84.2%,
          26% 84.07%,
          28% 84.03%,
          30% 84.09%,
          32% 84.25%,
          34% 84.51%,
          36% 84.86%,
          38% 85.3%,
          40% 85.82%,
          42% 86.41%,
          44% 87.07%,
          46% 87.78%,
          48% 88.53%,
          50% 89.31%,
          52% 90.1%,
          54% 90.91%,
          56% 91.7%,
          58% 92.47%,
          60% 93.22%,
          62% 93.91%,
          64% 94.56%,
          66% 95.13%,
          68% 95.64%,
          70% 96.06%,
          72% 96.39%,
          74% 96.63%,
          76% 96.77%,
          78% 96.8%,
          80% 96.74%,
          82% 96.58%,
          84% 96.32%,
          86% 95.97%,
          88% 95.53%,
          90% 95.01%,
          92% 94.42%,
          94% 93.76%,
          96% 93.05%,
          98% 92.3%,
          100% 91.52%
        );
      }
    }

    &__grid-item {
      justify-items: center;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }

    &__grid-item--flex {
      display: flex;
      justify-items: center;
      justify-content: center;
      height: 360px;

      img {
        max-width: none;
        max-height: none;
      }
    }

    &-text {
      padding-top: 80px;
      padding-left: 0;

      &__line,
      &__button-container {
        justify-content: center;
      }

      &__line {
        &--font-size-small {
          font-size: 1.5em;
        }

        &--font-size-medium {
          font-size: 2em;
        }

        &--font-size-large {
          font-size: 4em;
        }
      }

      &__button-container {
        .banner-text__button {
          width: 286px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .banner {
    &__grid {
      &::before {
        clip-path: polygon(
          100% 100%,
          0% 100%,
          0% 76.81%,
          6.67% 77.2%,
          13.33% 78.05%,
          20% 79.28%,
          26.67% 80.82%,
          33.33% 82.58%,
          40% 84.45%,
          46.67% 86.31%,
          53.33% 88.04%,
          60% 89.53%,
          66.67% 90.69%,
          73.33% 91.45%,
          80% 91.75%,
          86.67% 91.59%,
          93.33% 90.97%,
          100% 89.92%
        );
      }
    }
  }
}

@media screen and (max-width: 476px) {
  .banner {
    &-text {
      &__button-container {
        .banner-text__button {
          width: 90%;
        }
      }
    }
  }
}
