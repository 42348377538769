.suggested-bonus {
  display: flex;
  flex-flow: column;
  gap: 16px;

  &__description {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-flow: column;
    gap: 4px;

    &__code {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
