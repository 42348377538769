.challenge-island-info {
  position: absolute;
  display: none;
  gap: 8px;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  background-color: #25274a;
  border-radius: 16px;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #25274a;
  }

  &--adjusted-position {
    @extend .challenge-island-info;
    top: -15%;
  }

  &__row {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .challenge-island-info__icon {
    width: 18px;
    height: 18px;

    &--is-completed {
      width: 22px;
      height: 22px;
      color: #f7c903;
    }
  }

  &__name {
    font-size: 24px;
    font-weight: 700;
  }
}

@media (max-width: 899px) {
  .challenge-island-info {
    padding: 21px;
    width: 225px;

    &__name {
      font-size: 17px;
    }

    .challenge-island-info {
      &__button {
        height: 30px !important;
        font-size: 11px;
      }
    }
  }
}
