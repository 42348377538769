.receiving-details__warning {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-radius: 14px;
  border: 1px solid #f7c903;
  color: #fff;
  font-size: 14px;
}
