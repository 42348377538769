.free-spins {
  margin-bottom: 0px;

  &__games {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #16183d;
    border-radius: 32px;

    &__title {
      margin-bottom: 20px;
      font-family: Poppins;
      font-size: 31px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      color: var(--Yellow-text, #f7c903);
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 16px;
      flex-wrap: wrap;

      &__game {
        position: relative;
        cursor: pointer;
        border: 1px solid;
        border-color: #0d3d8e;
        border-radius: 14px;
        transition: border-color 0.5s;
        overflow: hidden;

        &:hover,
        &--is-selected {
          border-color: #f7c903;
        }

        &--is-selected {
          .free-spins__games-container__game-image {
            filter: blur(5px);
            transition: filter 0.5s ease;
          }

          .free-spins__games-container__game-overlay {
            display: flex;
          }
        }

        &-image {
          width: 100%;
          height: 100%;
        }

        &-overlay {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          z-index: 10;

          > button {
            svg {
              font-size: 35px;
              color: #ffd000;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .free-spins {
    &__games {
      padding: 20px;

      &__title {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .free-spins {
    &__games {
      &-container {
        gap: 13px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .free-spins {
    &__games {
      &__title {
        font-size: 21px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .free-spins {
    &__games {
      &-container {
        gap: 9px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .free-spins {
    &__games {
      &-container {
        gap: 9px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
}
