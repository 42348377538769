@keyframes blink {
    0% {
        border-color: #2CDB78;
    }

    50% {
        border-color: #FFF;
    }

    100% {
        border-color: #2CDB78;
    }
}

/* Apply the animation to the border */
.border-blink {
    border: 2px solid #2CDB78;
    animation: blink 2s linear infinite;
}

.new_label {
    position: absolute;
    left: 0;
    top: 8px;
    padding: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 0px 50px 50px 0px;
    z-index: 3;    
    color: var(--main-color);
    background: var(--yellow-gradient);
}