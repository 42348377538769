.confirmation-box {
  display: flex;
  width: 355px;
  min-height: 240px;
  padding: 30px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  border-radius: 30px !important;
  background: #fff;

  &__close-button {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 120;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    &__icon {
      width: 36px;
      height: 36px;
    }

    &__title {
      color: #212121;
      text-align: center;
      font-family: Poppins;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 26.6px */
    }

    &__text {
      color: #212121;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }

  &__button-container {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: center;
  }
}
