.shop-banner {
  display: flex;
  height: 330px;
  padding: 30px 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 32px;
  background: linear-gradient(90deg, #0B0957 0%, #4C1895 100%);
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  &::after {
    content: "";
     position: absolute;
     top: -35px;
     right: 0px;
     width: 600px;
     min-height: 400px;
     height: 100%;
     background: url('../../../assets/shop/shopBackground.svg');
     border-radius: 32px;
     background-repeat: no-repeat;
     background-size: cover;
  }

  &__content { 
    padding-bottom: 30px;
    z-index: 1;
    font-family: Poppins;
    display: flex;
    width: 1200px; 
    height: 330px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    z-index: 1;
    justify-content: center;

    &-title {
      font-size: 64px;
      font-weight: 700;
      line-height: 140%
    }

    &-message { 
      max-width: 350px;
      font-size: 19px;
      font-weight: 300;
    }
  }
} 

@media (max-width: 1024px) {
  .shop-banner {
    width: auto;
    height: 272px;
    padding: 60px 30px 30px 30px;
    &::after {
      top: 0px;
      right: -90px;
      width: 410px;
      min-height: 272px;
    }
  } 
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .shop-banner {
    width: auto;
    height: 238px;
    padding: 70px 20px 30px 20px;

    &__content {
      .group-container {
        display: none;
      }

      &-message {
        margin-bottom: 0;
      }
    }
     
    &::after {
      top: 0px;
      right: -10px;
      width: 410px;
      min-height: 272px;
    }

    &__content {
      gap: 10px;
      &-title {
        font-size: 39px;
      }
      
      &-message {
        font-size: 14px;
        font-weight: 400;
        width: 240px;
      }
    }
  }
}
 
@media (max-width: 575px) {
  .shop-banner {
    width: 100%;
    height: 238px;
    padding: 90px 20px 30px 20px;

    &::after {
      top: 0px;
      width: 65%; 
      height: 65%;
      right: 0px;
      background-size: contain;
      background-position: top right;
    } 

    &__content {
      &-title {
        font-size: 39px;
      }
      
      &-message {
        font-size: 14px;
        font-weight: 400;
        width: 240px;
      }

      .group-container {
        display: none;
      }

      &-message {
        margin-bottom: 0;
      }
    }
  }
}
