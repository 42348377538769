.custom-chip {
  display: flex;
  align-items: center;
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  background: #08287c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;

  &--success {
    background: #5bb524;
  }

  &--highlighted {
    color: #031e44;
    background: #f7c903;
  }

  &--condensed {
    font-size: 12px;
  }

  &--disabled {
    color: #fff;
    background: #686a8c;
  }
}

@media screen and (max-width: 960px) {
  .custom-chip {
    font-size: 12px;
  }
}
